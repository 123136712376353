import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[laAutofocus]',
})
export class AutofocusDirective implements AfterContentInit {
  private hasFocused = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    if (!this.hasFocused) {
      setTimeout(() => this.elementRef.nativeElement.focus(), 100);
      this.hasFocused = true;
    }
  }
}
