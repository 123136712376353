import { Pipe, PipeTransform } from '@angular/core';

/*
 * Return object keys as array of strings
 * Takes an object as argument
 * Usage:
 *   object | keys
 * Example:
 *   {{ { a: 4, b:6 } | keys }}
 *   formats to: ['a', 'b']
 */
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value: any): any {
    return Object.keys(value);
  }
}
