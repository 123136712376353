import { Inject, Injectable } from '@angular/core';
import { isString } from 'lodash';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { Socket, connect } from 'socket.io-client';

import { User } from '@core/api';
import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class SocketService {
  public connection$ = new BehaviorSubject(false);
  private socket: Socket | undefined;

  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig) {}

  connect(user: User) {
    if (user && this.config.socket.enabled && this.config.socket.url) {
      this.socket = connect(this.config.socket.url, {
        query: {
          userId: user.id,
        },
      });

      this.on('connect', () => this.connection$.next(true));
      this.on('reconnect', () => this.connection$.next(true));
      this.on('disconnect', () => this.connection$.next(false));
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  on(name: string, action: (data?: any) => void) {
    if (this.socket) {
      this.socket.off(name).on(name, action);
    }
  }

  emit(topic: string, message: any) {
    if (this.socket) {
      this.socket.emit(topic, message);
    }
  }

  fromEvent<T>(name: string) {
    return new Observable((subscriber: Subscriber<T>) => {
      this.connection$.subscribe((status) => {
        if (status) {
          this.socket?.on(name, (data) => subscriber.next(parse<T>(data)));
        } else {
          this.socket?.off(name);
        }
      });
    });
  }
}

function parse<T>(data: unknown) {
  try {
    return isString(data) ? (JSON.parse(data) as T) : (data as T);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
