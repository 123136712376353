import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { getAppStatus } from '../selectors/init.selectors';
import { APP_STATES } from '../enums/app.enum';
import { Location } from '@angular/common';

/**
 * Defined route can be accessed by anyone (but wait till user is defined).
 */
@Injectable({ providedIn: 'root' })
export class AuthAllGuard {
  constructor(private store: Store) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      map(() => true)
    );
  }
}

/**
 * Defined route can be accessed only by authorized users.
 */
@Injectable({ providedIn: 'root' })
export class AuthAuthorizedGuard {
  constructor(
    public router: Router,
    private store: Store,
    private location: Location
  ) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      tap((status) => {
        if (status === APP_STATES.CLIENT) {
          localStorage.setItem('last.location', this.location.path());
          this.router.navigate(['/login']);
        } else if (status === APP_STATES.ERROR) {
          this.router.navigate(['/error']);
        }
      }),
      map((status) => status === APP_STATES.USER)
    );
  }
}

/**
 * Defined route can be accessed only by unauthorized users.
 */
@Injectable({ providedIn: 'root' })
export class AuthUnauthorizedGuard {
  constructor(
    public router: Router,
    private store: Store
  ) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      tap((status) => {
        if (status === APP_STATES.USER) {
          this.router.navigate(['/maps']);
        } else if (status === APP_STATES.ERROR) {
          this.router.navigate(['/error']);
        }
      }),
      map((status) => status === APP_STATES.CLIENT || status === APP_STATES.ERROR)
    );
  }
}
