import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/config';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { BillingHistoryData, BillingHistoryResponse } from '../models/billings.model';
import { ActiveCollaboratorData } from '../models/collaborator.model';
import { Member } from '../models/member.model';
import { BillingCycle, Organisation } from '../models/organization.model';
import { SubscriptionDetails } from '../models/subscription.model';
import { Token } from '../models/token.model';
import {
  BillingCyclePrice,
  DowngradeCancelRequest,
  PasswordChange,
  SubscriptionChangeRequest,
  SubscriptionPrice,
  User,
  UserWithOrganisation,
  UserWithOrganisationDetails,
} from '../models/user.model';
import { deserialise } from '../operators/deserialise.operator';
import { applyParams } from '../utils/apply-params.utils';
import { CustomEncoder } from '../utils/params-encoder.service';

interface ListQueryParams {
  size?: number;
  page?: number;
  search?: string;
  team?: string[];
  sort?: string;
  order?: string;
}

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private http: HttpClient,
    private customEncoder: CustomEncoder
  ) {}

  fetch(): Observable<User> {
    return this.http.get<User>(`${this.config.api.mapUrl}/users/me`).pipe(deserialise());
  }

  fetchUsers(parameters: ListQueryParams) {
    const params = applyParams(parameters);
    return this.http.get<UserWithOrganisation[]>(`${this.config.api.mapUrl}/users`, { params }).pipe(deserialise());
  }

  fetchUserById(userId: string) {
    return this.http.get<UserWithOrganisationDetails>(`${this.config.api.mapUrl}/users/${userId}`).pipe(deserialise());
  }

  refresh(token: Token): Observable<Token> {
    const httpParams = new HttpParams().append('grant_type', 'refresh_token').append('refresh_token', token.refresh_token);

    return this.http.post<Token>(`${this.config.api.authUrl}/oauth/token`, httpParams);
  }

  login(username: string, password: string) {
    const httpParams = new HttpParams({ encoder: this.customEncoder })
      .append('grant_type', 'password')
      .append('username', username)
      .append('password', password);

    return this.http.post<Token>(`${this.config.api.authUrl}/oauth/token`, httpParams);
  }

  logout(refreshToken: string) {
    const httpParams = new HttpParams({ encoder: this.customEncoder }).append('grant_type', 'revoke').append('refreshToken', refreshToken);

    return this.http.post<void>(`${this.config.api.authUrl}/oauth/revoke`, httpParams);
  }

  register(form: any) {
    const httpParams = new HttpParams({ encoder: this.customEncoder, fromObject: form });

    return this.http.post<User>(`${this.config.api.mapUrl}/users`, httpParams).pipe(deserialise());
  }

  create(form: { token: string; password: string; sector: string; organisationType: string }) {
    const httpParams = new HttpParams({ encoder: this.customEncoder, fromObject: form });

    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/members`, httpParams);
  }

  activate(activationToken: string) {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/activate/${activationToken}`, null);
  }

  resendActivationEmail(email: string) {
    const httpParams = new HttpParams({ encoder: this.customEncoder, fromObject: { email } });

    return this.http.post<void>(`${this.config.api.mapUrl}/users/activate/resend`, httpParams);
  }

  initializePasswordChange(email: string) {
    const httpParams = new HttpParams({ encoder: this.customEncoder, fromObject: { email } });

    return this.http.post<void>(`${this.config.api.mapUrl}/users/password/change/initialize`, httpParams);
  }

  confirmPasswordChange(passwordChangeToken: string, form: any): Observable<void> {
    const httpParams = new HttpParams({ encoder: this.customEncoder, fromObject: form });

    return this.http.post<void>(`${this.config.api.mapUrl}/users/password/change/confirm/${passwordChangeToken}`, httpParams);
  }

  confirmEmailChange(token: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/email/change/confirm/${token}`, {});
  }

  getPaymentMethodValidity(): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/verify-payment-method`, {});
  }

  changePassword(data: PasswordChange): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/password/change`, data);
  }

  checkIfEmailExists(email: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/email/available`, { email });
  }

  initializeEmailChange(email: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/email/change`, { email });
  }

  updateLoggedUser(update: Partial<User>): Observable<void> {
    return this.http.patch<void>(`${this.config.api.mapUrl}/users/me`, update);
  }

  changeUserEmail(userId: string, newEmail: string) {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/${userId}/email/change-without-verification`, { newEmail });
  }

  changeUserAdminStatus(userId: string, isApplicationAdmin: boolean) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/users/${userId}`, { isApplicationAdmin });
  }

  changeUserProfessionalStatus(userId: string, isAccreditedProfessional: boolean) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/users/${userId}`, { isAccreditedProfessional });
  }

  changeUserBetaTesterStatus(userId: string, isBetaTester: boolean) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/users/${userId}`, { isBetaTester });
  }

  initializeEmailChangeByAdmin(email: string, memberId: string): Observable<void> {
    return this.http.patch<void>(`${this.config.api.mapUrl}/organisation/members/${memberId}/email`, { email });
  }

  fetchSubscription() {
    return this.http.get<SubscriptionDetails>(`${this.config.api.mapUrl}/users/subscriptions`);
  }

  fetchMember(memberId: string) {
    return this.http.get<Member>(`${this.config.api.mapUrl}/organisation/members/${memberId}`).pipe(deserialise());
  }

  subscriptionChange(data: SubscriptionChangeRequest) {
    return this.http.patch<Organisation>(`${this.config.api.mapUrl}/users/subscriptions`, data);
  }

  fetchSubscriptionUpgradePrice(body: SubscriptionChangeRequest) {
    return this.http.post<SubscriptionPrice>(`${this.config.api.mapUrl}/users/subscriptions/price/calculate`, body);
  }

  subscriptionDowngradeDelete(body: DowngradeCancelRequest) {
    return this.http.delete<void>(`${this.config.api.mapUrl}/users/subscriptions/downgrade`, { body });
  }

  fetchBillingHistory(parameters: ListQueryParams): Observable<BillingHistoryData> {
    const params = applyParams(parameters);
    return this.http
      .get<BillingHistoryResponse>(`${this.config.api.mapUrl}/users/billing/history`, { params })
      .pipe(map((response) => ({ ...response, data: response.data?.map(({ id, attributes }) => ({ ...attributes, id })) })));
  }

  downloadInvoice(id: string) {
    return this.http.get(`${this.config.api.mapUrl}/users/billing/history/invoices/${id}`, { responseType: 'blob' });
  }

  downloadReport(id: string) {
    return this.http.get(`${this.config.api.mapUrl}/users/billing/history/reports/${id}`, { responseType: 'blob' });
  }

  downloadZip(billingId: string, reportId: string) {
    return this.http.get(`${this.config.api.mapUrl}/users/billing/history/invoices/${billingId}/reports/${reportId}/zip`, {
      responseType: 'blob',
    });
  }

  fetchBillingCycleChangePrice(billingCycle: BillingCycle): Observable<BillingCyclePrice> {
    return this.http.post<BillingCyclePrice>(`${this.config.api.mapUrl}/users/subscriptions/cycle/price/calculate`, { billingCycle });
  }

  changeBillingCycle(billingCycle: BillingCycle): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/users/subscriptions/cycle/change`, { billingCycle });
  }

  cancelChangeBillingCycle(): Observable<void> {
    return this.http.delete<void>(`${this.config.api.mapUrl}/users/subscriptions/cycle/change`);
  }

  getCollaboratorData(mapId: string, userId: string) {
    return this.http.get<ActiveCollaboratorData>(`${this.config.api.mapUrl}/maps/${mapId}/collaborator/${userId}`);
  }
}
