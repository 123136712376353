import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ActionUIState, initActions, userActions, userApiActions } from '@core/store';

export const featureKey = 'login';

export type LoginUIState = ActionUIState;

export const initialState: LoginUIState = {
  status: 'INIT',
};

export const reducer = createReducer<LoginUIState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),

  on(userActions.login, (state) => ({ status: 'LOADING' })),
  on(userApiActions.loginSuccess, (state) => ({ status: 'READY' })),
  on(userApiActions.loginFail, (state, { error }) => ({ status: 'ERROR', error }))
);
