import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { setTextColor } from '../utils/color-checker';

@Directive({
  selector: '[laTeamChipColor]',
})
export class TeamChipColorDirective implements AfterViewInit {
  @Input('laTeamChipColor') backgroundColor?: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setColors();
  }

  setColors() {
    const fontColor = setTextColor(this.backgroundColor);
    const closeButton = this.el.nativeElement.querySelector('.mat-chip-remove');

    this.el.nativeElement.style.backgroundColor = this.backgroundColor;
    this.el.nativeElement.style.border =
      this.backgroundColor === '#FFFFFF' ? '1px solid rgba(0, 0, 0, 0.3)' : this.el.nativeElement.style.border;
    this.el.nativeElement.style.color = fontColor;

    if (closeButton) {
      closeButton.style.color = fontColor;
    }
  }
}
