import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { PlanDetails } from '@core/api';
import { initActions, ItemUIState } from '@core/store';
import * as plansApiActions from '../actions/api/plans-api.actions';
import * as dialogsActions from '../actions/dialogs.actions';
import * as mapPageActions from '../actions/map-page.actions';

export const featureKey = 'planDetails';

export interface PlanDetailsState extends ItemUIState<PlanDetails> {}

export const initialState: PlanDetailsState = {
  status: 'INIT',
};

export const reducer = createReducer<PlanDetailsState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, mapPageActions.leaveMap, () => cloneDeep(initialState)),

  on(dialogsActions.fetchPlanDetails, () => ({
    status: 'LOADING',
  })),

  on(plansApiActions.fetchPlanDetailsSuccess, (state, { plan }) => ({
    status: 'READY',
    data: plan,
  })),
  on(plansApiActions.fetchPlanDetailsFail, (state, { error }) => ({
    status: 'ERROR',
    error,
  }))
);
