import codes from './assets/phone-country-codes.json';

export const COUNTRY_CODES = codes;

export * from './common.module';

export * from './utils/date-formats';
export * from './utils/expire-date';

export * from './pipes/area.pipe';
export * from './pipes/file.pipe';
export * from './pipes/filter.pipe';
export * from './pipes/group.pipe';
export * from './pipes/is-expired.pipe';
export * from './pipes/keys.pipe';
export * from './pipes/limit-text-display.pipe';
export * from './pipes/non-breaking-hyphen.pipe';
export * from './pipes/orderBy.pipe';
export * from './pipes/paper-size.pipe';
export * from './pipes/perimeter.pipe';
export * from './pipes/pluralize.pipe';
export * from './pipes/price.pipe';
export * from './pipes/separator.pipe';
export * from './pipes/sort.pipe';
export * from './pipes/subscription-name.pipe';
export * from './pipes/timespan.pipe';

export * from './directives/autofocus.directive';
export * from './directives/drop-file.directive';
export * from './directives/scrollable.directive';
export * from './directives/team-chip-color.directive';

export * from './components/action/action.component';
export * from './components/avatar-icon/avatar-icon.component';
export * from './components/copy-to-clipboard/copy-to-clipboard.component';
export * from './components/from-now/from-now.component';
export * from './components/icon-host/icon-host.component';
export * from './components/image/image.component';
export * from './components/layer-source/layer-source.component';
export * from './components/line-bar/line-bar.component';
export * from './components/team-chips/team-chips.component';
export * from './components/delay-button/delay-button.component';
