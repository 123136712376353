import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ActionUIState } from '@core/store';
import { createUser, createUserFail, createUserSuccess } from '../actions/join-organisation.actions';

export const featureKey = 'joinOrganisation';

export type JoinOrganisationUIState = ActionUIState;

export const initialState: JoinOrganisationUIState = {
  status: 'INIT',
};

export const reducer = createReducer<JoinOrganisationUIState>(
  cloneDeep(initialState),

  on(createUser, (state) => ({ status: 'LOADING' })),
  on(createUserSuccess, (state) => ({ status: 'READY' })),
  on(createUserFail, (state, { error }) => ({ status: 'ERROR', error }))
);
