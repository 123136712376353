import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { Frame, NewFrame, PrintPrice } from '@core/api';
import { ActionUIState, BasicListQueryParams, initActions, ListUIState } from '@core/store';
import { fetchPrintPriceFail, fetchPrintPriceSuccess } from '../actions/api/prints-api.actions';
import { setPrintExtent } from '../actions/map-container.actions';
import { fetchPrintPrice, renameFrame } from '../actions/print-setup-effect.actions';
import {
  deselectFrame,
  fetchOrgFrames,
  fetchOrgFramesFail,
  fetchOrgFramesSuccess,
  fetchUserFrames,
  fetchUserFramesFail,
  fetchUserFramesSuccess,
  renameFrameFail,
  renameFrameSuccess,
  renderPaperOnMap,
  resetPrintSetup,
  searchOrgFrames,
  searchUserFrames,
  selectFrame,
} from '../actions/print-setup.actions';

export const featureKey = 'printSetup';

export interface FrameListQueryParams extends BasicListQueryParams {
  search?: string;
}

export interface PricingAction extends ActionUIState {
  price: PrintPrice;
}
export interface PrintSetupState {
  userFrames: ListUIState<Frame, FrameListQueryParams>;
  orgFrames: ListUIState<Frame, FrameListQueryParams>;

  pricingAction: PricingAction;
  renameAction: ActionUIState;

  newFrame?: NewFrame;
  existingFrame?: Frame;
}

export const initialState: PrintSetupState = {
  userFrames: {
    status: 'INIT',
    params: { page: 0, size: 100, search: '' },
  },

  orgFrames: {
    status: 'INIT',
    params: { page: 0, size: 100, search: '' },
  },

  pricingAction: {
    status: 'INIT',
    price: null,
  },

  renameAction: {
    status: 'INIT',
  },
};

export const reducer = createReducer<PrintSetupState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, resetPrintSetup, () => cloneDeep(initialState)),

  on(fetchUserFrames, (state) => ({
    ...state,
    userFrames: {
      ...state.userFrames,
      status:
        state.userFrames.params.page === 0 && !state.userFrames.data?.length
          ? 'LOADING'
          : state.userFrames.params.page === 0 && state.userFrames.data?.length > 0
            ? 'REFRESHING'
            : 'LOADING_MORE',
    },
  })),
  on(fetchUserFramesSuccess, (state, { frames }) => ({
    ...state,
    userFrames: {
      ...state.userFrames,
      status: 'READY',
      data: state.userFrames.params.page === 0 ? frames : [...state.userFrames.data, ...frames],
      params: {
        ...state.userFrames.params,
        page: frames.length === state.userFrames.params.size ? state.userFrames.params.page + 1 : state.userFrames.params.page,
      },
      more: frames.length === state.userFrames.params.size,
    },
  })),
  on(fetchUserFramesFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  })),

  on(fetchOrgFrames, (state) => ({
    ...state,
    orgFrames: {
      ...state.orgFrames,
      status:
        state.orgFrames.params.page === 0 && !state.orgFrames.data?.length
          ? 'LOADING'
          : state.orgFrames.params.page === 0 && state.orgFrames.data?.length > 0
            ? 'REFRESHING'
            : 'LOADING_MORE',
    },
  })),
  on(fetchOrgFramesSuccess, (state, { frames }) => ({
    ...state,
    orgFrames: {
      ...state.orgFrames,
      status: 'READY',
      data: state.orgFrames.params.page === 0 ? frames : [...state.orgFrames.data, ...frames],
      params: {
        ...state.orgFrames.params,
        page: frames.length === state.orgFrames.params.size ? state.orgFrames.params.page + 1 : state.orgFrames.params.page,
      },
      more: frames.length === state.orgFrames.params.size,
    },
  })),
  on(fetchOrgFramesFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  })),

  on(searchUserFrames, (state, { payload }) => ({
    ...state,
    userFrames: {
      ...state.userFrames,
      params: {
        ...state.userFrames.params,
        page: 0,
        search: payload,
      },
    },
  })),
  on(searchOrgFrames, (state, { payload }) => ({
    ...state,
    orgFrames: {
      ...state.orgFrames,
      params: {
        ...state.orgFrames.params,
        page: 0,
        search: payload,
      },
    },
  })),

  on(renderPaperOnMap, (state, { paperSize, border, basemap, scale }) => ({
    ...state,
    newFrame: { ...state.newFrame, scale: scale ? scale : state.newFrame?.scale, paperSize, bboxProjection: 'EPSG:27700', basemap, border },
  })),
  on(setPrintExtent, (state, { extent, width, height, scale, area }) => ({
    ...state,
    pricingAction: state.existingFrame ? state.pricingAction : { ...state.pricingAction, status: 'LOADING' },
    newFrame: { ...state.newFrame, bbox: extent, width, height, scale, area },
  })),

  on(selectFrame, (state, { frame }) => ({
    ...state,
    existingFrame: frame,
    pricingAction:
      new Date(frame.activeTo) < new Date()
        ? { ...state.pricingAction }
        : {
            price: {
              price: 0,
              priceWithVat: 0,
              priceWithoutVat: 0,
              vatAmount: 0,
              vatPercentage: 0,
              type: 'none',
            },
            status: 'READY',
          },
  })),

  on(deselectFrame, (state) => ({
    ...state,
    existingFrame: undefined,
    pricingAction: {
      price: {
        price: 0,
        priceWithVat: 0,
        priceWithoutVat: 0,
        vatAmount: 0,
        vatPercentage: 0,
        type: 'none',
      },
      status: 'READY',
    },
  })),

  on(fetchPrintPrice, (state) => ({
    ...state,
    pricingAction: {
      ...state.pricingAction,
      status: 'LOADING',
    },
  })),
  on(fetchPrintPriceSuccess, (state, { printPrice }) => ({
    ...state,
    pricingAction: {
      ...state.pricingAction,
      status: 'READY',
      price: printPrice,
    },
  })),
  on(fetchPrintPriceFail, (state, { error }) => ({
    ...state,
    pricingAction: {
      ...state.pricingAction,
      status: 'ERROR',
      error,
    },
  })),

  on(renameFrame, (state) => ({
    ...state,
    renameAction: {
      status: 'LOADING',
    },
  })),
  on(renameFrameSuccess, (state, { frame }) => ({
    ...state,
    renameAction: {
      status: 'READY',
    },
    userFrames: {
      ...state.userFrames,
      data: state.userFrames.data?.map((userFrame) => (userFrame.id !== frame.id ? userFrame : { ...userFrame, title: frame.title })),
    },
    orgFrames: {
      ...state.orgFrames,
      data: state.orgFrames.data?.map((orgFrame) => (orgFrame.id !== frame.id ? orgFrame : { ...orgFrame, title: frame.title })),
    },
  })),
  on(renameFrameFail, (state, { error }) => ({
    ...state,
    renameAction: {
      status: 'ERROR',
      error,
    },
  }))
);
