import { createSelector } from '@ngrx/store';
import { some } from 'lodash';

import { DataLayer, PhotoLayer, Plan } from '@core/api';
import { configSelectors, userSelectors } from '@core/store';
import { flattenPlans } from '../utils/plans-tree.utils';
import { getEstateState, getPlanDetailsState, getPlansState } from './main.selectors';
import { getIsUserNotifiedAboutPaymentRequired } from './settings.selectors';

const PREMIUM_DATA_LAYERS = ['osmm_ta_colour_27700', 'osmm_ta_black_white_27700'];

/**
 * Page
 * ----------------------------------------------
 */

export const isLoading = createSelector(getEstateState, (state) => state.status === 'LOADING');

export const isPageError = createSelector(getEstateState, (state) => (state.status === 'ERROR' ? state.error : undefined));

export const isReady = createSelector(getEstateState, (state) => state.status === 'READY');

export const isReadonly = createSelector(getEstateState, (state) => !!state.readonly);

export const isLocked = createSelector(getEstateState, (state) => !!state.locked);

export const isFirstPlanCreated = createSelector(getEstateState, (state) => !!state.isFirstPlan);

export const isActionReady = createSelector(getEstateState, (state) => state.action.status === 'READY');

/**
 * Estate
 * ----------------------------------------------
 */

export const getEstate = createSelector(getEstateState, (state) => state.data);

export const getMapId = createSelector(getEstate, (state) => state?.id);

export const getMapVersion = createSelector(getEstate, (state) => state?.version);

export const getPlanNodes = createSelector(getEstate, (state) => state?.plans);

export const getFlattenedPlans = createSelector(getEstate, (data) => flattenPlans(data?.plans));

export const getVisibleDataLayersIds = createSelector(getEstate, (state) => state.dataLayers);

export const getVisibleDataLayers = createSelector(getVisibleDataLayersIds, configSelectors.getDataLayers, (ids, dataLayers) =>
  dataLayers?.filter((layer) => layer && ids.includes(layer.id))
);

export const getPhotosEnabled = createSelector(getEstate, (state) => !!state.photosEnabled);

export const getSelectedBasemap = createSelector(getEstate, (state) => state?.selectedBasemap);

export const getSelectedBaseLayer = createSelector(getSelectedBasemap, configSelectors.getBaseLayers, (selectedBasemap, baseLayers) => {
  const layer = baseLayers.find((l) => l.id === selectedBasemap);
  return layer ? layer : baseLayers[0];
});

export const getRegion = createSelector(getEstate, (estate) => estate?.region);

export const getProjection = createSelector(getRegion, (region) => (region ? region.projection : 'EPSG:27700'));

export const getMapCreatorId = createSelector(getEstate, (state) => state?.createdBy.id);

export const isMapCollaborator = createSelector(getEstate, userSelectors.getUserId, (estate, userId) => estate?.createdBy.id !== userId);

/**
 * Interactions
 * ----------------------------------------------
 */

export const getInteraction = createSelector(getEstateState, (state) => state.interaction);

export const getMapMode = createSelector(getInteraction, (state) => state?.mapMode);

export const getBufferPreview = createSelector(getInteraction, (state) => state.bufferPreview);

export const isInteractiveMapOn = createSelector(getInteraction, (state) => state.interactiveMap);

/**
 * Selection
 * ----------------------------------------------
 */

export const getSelection = createSelector(getEstateState, (state) => state.selection);

export const getSelectedLayerId = createSelector(getSelection, (state) => state.layerId);

export const getSelectedFeatureIds = createSelector(getSelection, (state) => state.features?.map((feature) => feature.id) || []);

/**
 * Plans
 * ----------------------------------------------
 */

export const getPlan = (id: string) => createSelector(getPlansState, (plans) => plans[id]?.data);

export const getPlanDetailsWithTemplate = createSelector(getPlanDetailsState, configSelectors.getProjectTemplates, (state, templates) => {
  const template = templates.find((t) => t.id === state.data?.subtype);
  return { ...state.data, template };
});

export const getPlans = createSelector(getPlansState, (state) => state);

export const getPlansArray = createSelector(getPlansState, (state) => Object.keys(state).map((id) => state[id]?.data));

export const getPlansIds = createSelector(getPlansState, (state) => Object.keys(state));

export const getSortedPlans = createSelector(getFlattenedPlans, getPlansState, (structure, state) =>
  structure.map(({ id }) => state[id]?.data)
);

export const getSortedPlansForCopyFeature = createSelector(
  getFlattenedPlans,
  configSelectors.getProjectTemplates,
  getPlansState,
  (structure, templates, state) =>
    structure
      .map(({ id }) => state[id]?.data)
      .filter((plan) => !!templates?.find((template) => plan?.subtype === template.id && template.type === 'plan'))
);

export const getSelectedPlanEntity = createSelector(getPlans, getSelectedLayerId, (state, layerId) => (state ? state[layerId] : undefined));

export const getSelectedPlan = createSelector(getSelectedPlanEntity, (state) => (state ? state?.data : undefined));

export const getSelectedSubtype = createSelector(getSelectedPlan, (data) => (data ? data.subtype : undefined));

export const getSelectedPlanFeatures = createSelector(getSelectedPlan, (state) => state?.geojson?.features || []);

export const isPlanActionLoading = createSelector(getPlansState, (plans) => some(plans, (plan) => plan.action.status === 'LOADING'));

export const hasLinkedPhotos = (ids: string[]) =>
  createSelector(getPlans, (state) =>
    ids.some((id) => state[id]?.data.geojson.features.some((feature) => feature.properties?.laFeatureType === 'photo'))
  );

export const getFeatures = (planId: string, featureIds: (string | number)[]) =>
  createSelector(getPlansState, (state) => {
    const plan = state[planId]?.data;
    if (!plan) {
      return [];
    }

    return featureIds.map((id) => plan.geojson.features.find((feature) => feature.id === id)).filter((feature) => !!feature);
  });

/**
 * History
 * ----------------------------------------------
 */

export const getHistory = createSelector(getSelectedPlanEntity, (state) => state?.history);

export const getUndo = createSelector(getHistory, (state) => state?.undo);

export const getRedo = createSelector(getHistory, (state) => state?.redo);

/**
 * Combined
 * ----------------------------------------------
 */

export const getSelectedTemplate = createSelector(getSelectedPlan, configSelectors.getProjectTemplates, (plan, templates) =>
  plan ? templates.find((t) => t.id === plan.subtype) : undefined
);

export const getSelectedLayer = createSelector(
  getSelection,
  getSelectedPlan,
  getProjection,
  configSelectors.getDataLayers,
  (selection, plan, projection, dataLayers): Plan | DataLayer | PhotoLayer => {
    if (!selection) {
      return undefined;
    }

    if (plan) {
      return plan;
    }

    if (selection.layerId === 'photos') {
      return {
        id: 'photos',
        type: 'GEOJSON',
        name: 'Photos',
        projection,
        section: 'photo-layer',
        readonly: false,
        enabled: true,
      } as PhotoLayer;
    }

    return dataLayers.find((layer) => layer.id === selection.layerId);
  }
);

export const getSelectedFeaturesFromPlan = createSelector(getSelection, getSelectedPlan, (selection, plan): GeoJSON.Feature[] => {
  if (!selection || selection.layerId === 'photos' || !plan || !selection.features?.length) {
    return [];
  }

  return selection.features.map((selected) => plan.geojson?.features?.find((f) => f.id === selected.id)).filter((f) => !!f);
});

export const getSelectedFeatures = createSelector(
  getSelection,
  getSelectedPlan,
  configSelectors.getDataLayers,
  (selection, plan, dataLayers) => {
    if (!selection || !selection.features?.length) {
      return [];
    }

    if (!plan && (selection.layerId === 'photos' || dataLayers.some((dl) => dl.id === selection.layerId))) {
      return selection.features;
    }

    if (plan) {
      return selection.features.map((selected) => plan.geojson?.features?.find((f) => f.id === selected.id)).filter((f) => !!f);
    }

    return [];
  }
);

export const getMeasureTool = createSelector(getEstateState, (state) => (state.measure ? state.measure : null));

export const getPaywallVisibility = createSelector(
  getEstate,
  getIsUserNotifiedAboutPaymentRequired,
  (state, isUserNotifiedAboutPaymentRequired) => {
    if (state.selectedBasemap?.startsWith('os_') || state.dataLayers.some((d) => PREMIUM_DATA_LAYERS.includes(d))) {
      return isUserNotifiedAboutPaymentRequired;
    }

    return false;
  }
);

export const getUndoStackForSelectedPlan = createSelector(getUndo, getSelectedLayerId, (stack, layerId) => stack[layerId] || []);

export const getRedoStackForSelectedPlan = createSelector(getRedo, getSelectedLayerId, (stack, layerId) => stack[layerId] || []);

export const getUserSector = createSelector(userSelectors.getUserSector, configSelectors.getSectors, (userSector, sectors) => {
  return sectors.find((sector) => sector.id === userSector);
});

const getPlanTemplates = createSelector(configSelectors.getProjectTemplates, getRegion, (templates, region) => {
  if (!region) {
    return [];
  }

  return templates.filter((template) => !template.regions || template.regions.includes(region.id));
});

export const getPlanTemplatesForNewPlansStepper = createSelector(getPlanTemplates, getUserSector, (templates, sector) => {
  const unorderedTemplates = sector?.templates
    .map(({ key, suggested }) => ({
      ...templates.find((template) => template.id === key),
      suggested,
    }))
    .filter((template) => !!template.id);
  return [unorderedTemplates[0], ...unorderedTemplates.slice(1).sort((a, b) => (a.suggested && !b.suggested ? -1 : 1))];
});

export const getPlanTemplatesForDuplication = createSelector(
  getPlanTemplates,
  getPlanTemplatesForNewPlansStepper,
  (allTemplates, sortedTemplates) => {
    return [
      ...sortedTemplates,
      ...allTemplates.filter(
        (template) => template.type === 'survey' && !sortedTemplates.some((sortedTemplate) => template.id === sortedTemplate.id)
      ),
    ];
  }
);

export const isPublishedToOrganisation = (planId: string) => createSelector(getPlan(planId), (plan) => plan?.published);

const getDeletePlanState = createSelector(getEstateState, ({ deleteAction }) => deleteAction);
export const getDeleteLoading = createSelector(getDeletePlanState, (state) => state?.status === 'LOADING');
export const getDeleteReady = createSelector(getDeletePlanState, (state) => state?.status === 'READY');
export const getDeleteError = createSelector(getDeletePlanState, (state) => (state?.status === 'ERROR' ? state?.error : undefined));
const getDeletePrerequisitesState = createSelector(getDeletePlanState, ({ prerequisite }) => prerequisite);
export const getDeletePrerequisites = createSelector(getDeletePrerequisitesState, ({ data }) => data);
export const getDeletePrerequisitesLoading = createSelector(getDeletePrerequisitesState, ({ status }) => status === 'LOADING');
export const getDeletePrerequisitesReady = createSelector(getDeletePrerequisitesState, ({ status }) => status === 'READY');
export const getDeletePrerequisitesError = createSelector(getDeletePrerequisitesState, ({ status }) => status === 'ERROR');

const getArchivePlanState = createSelector(getEstateState, ({ archiveAction }) => archiveAction);
export const getArchiveLoading = createSelector(getArchivePlanState, (state) => state?.status === 'LOADING');
export const getArchiveReady = createSelector(getArchivePlanState, (state) => state?.status === 'READY');
export const getArchiveError = createSelector(getArchivePlanState, (state) => (state?.status === 'ERROR' ? state?.error : undefined));
const getArchivePrerequisitesState = createSelector(getArchivePlanState, ({ prerequisite }) => prerequisite);
export const getArchivePrerequisites = createSelector(getArchivePrerequisitesState, ({ data }) => data);
export const getArchivePrerequisitesLoading = createSelector(getArchivePrerequisitesState, ({ status }) => status === 'LOADING');
export const getArchivePrerequisitesReady = createSelector(getArchivePrerequisitesState, ({ status }) => status === 'READY');
export const getArchivePrerequisitesError = createSelector(getArchivePrerequisitesState, ({ status }) => status === 'ERROR');
