import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { HydrationEffects } from './effects/hydration.effects';
import { InitEffects } from './effects/init.effects';
import { UserEffects } from './effects/user.effects';
import { ApiInterceptor } from './interceptors/api.interceptor';
import * as fromConfig from './reducers/config.reducer';
import { metaReducer } from './reducers/hydration.reducer';
import * as fromInit from './reducers/init.reducer';
import * as fromOrganisation from './reducers/organisation.reducer';
import * as fromPayment from './reducers/payment.reducer';
import * as fromSubscription from './reducers/subscription.reducer';
import * as fromTeams from './reducers/teams.reducer';
import * as fromUser from './reducers/user.reducer';

export interface AppState {
  [fromInit.featureKey]: fromInit.InitState;
  [fromUser.featureKey]: fromUser.UserState;
  [fromOrganisation.featureKey]: fromOrganisation.OrganisationState;
  [fromSubscription.featureKey]: fromSubscription.SubscriptionState;
  [fromTeams.featureKey]: fromTeams.TeamsState;
  [fromPayment.featureKey]: fromPayment.PaymentState;
  [fromConfig.featureKey]: fromConfig.ConfigState;
}

const reducers = {
  [fromInit.featureKey]: fromInit.reducer,
  [fromUser.featureKey]: fromUser.reducer,
  [fromOrganisation.featureKey]: fromOrganisation.reducer,
  [fromSubscription.featureKey]: fromSubscription.reducer,
  [fromTeams.featureKey]: fromTeams.reducer,
  [fromPayment.featureKey]: fromPayment.reducer,
  [fromConfig.featureKey]: fromConfig.reducer,
};

@NgModule({
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
  imports: [
    StoreModule.forRoot(reducers, { metaReducers: [metaReducer] }),
    EffectsModule.forRoot([HydrationEffects, UserEffects, InitEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), autoPause: true, trace: false, connectInZone: true }),
  ],
})
export class CoreStoreModule {}
