import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { configSelectors } from '@core/store';
import { GtagService, LinkedInService } from '@core/providers';
import { registerSuccess } from '../actions/register.actions';

@Injectable()
export class AnalyticsEventsEffects {
  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerSuccess),
        concatLatestFrom(({ sector, organisationType }) => [
          this.store.select(configSelectors.getSector(sector)),
          this.store.select(configSelectors.getOrganisationType(organisationType)),
        ]),
        tap(([{ subscription, id, referralCode }, sector, orgType]) => {
          this.gtag.setUser({
            user_id: id,
            org_type: orgType?.name,
            subscription_tier: subscription.toLowerCase(),
            sector: sector?.name,
          });
          this.gtag.event('register', {
            is_referral: referralCode ? true : false,
          });
          this.linkedIn.trackEvent();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private gtag: GtagService,
    private linkedIn: LinkedInService
  ) {}
}
