<apx-chart
  class="la-donut-chart"
  [series]="series"
  [chart]="options.chart"
  [labels]="labels"
  [colors]="colors"
  [dataLabels]="options.dataLabels"
  [legend]="options.legend"
  [plotOptions]="plotOptions"
  [tooltip]="options.tooltip"
  [stroke]="options.stroke"
></apx-chart>
