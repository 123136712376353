import { Pipe, PipeTransform } from '@angular/core';
import { expirationDate } from '../utils/expire-date';

@Pipe({
  name: 'laIsExpired',
})
export class IsExpiredPipe implements PipeTransform {
  transform(value: string): boolean {
    return expirationDate(new Date(value), new Date()) < 0;
  }
}
