import { Component, Input } from '@angular/core';

@Component({
  selector: 'la-reset-password-required',
  styleUrls: ['./reset-password-required.component.scss'],
  templateUrl: './reset-password-required.component.html',
})
export class ResetPasswordRequiredComponent {
  @Input() email: string;
}
