import { BillingCycle } from './organization.model';
import { Price } from './price.model';
import { Subscription } from './user.model';

export const SubscriptionTierName = {
  [Subscription.FREE]: 'Free',
  [Subscription.STANDARD]: 'Standard',
  [Subscription.PROFESSIONAL]: 'Professional',
  [Subscription.PREMIUM]: 'Premium',
};

export interface PendingDowngradeStatus {
  from: Subscription;
  to: Subscription;
  executeAt: string;
}

export interface PendingBillingCycleChangeStatus {
  from: BillingCycle;
  to: BillingCycle;
  executeAt: string;
}

export interface SubscriptionDetails {
  subscription: Subscription;
  billingCycle?: BillingCycle;
  listingPrice: Price;
  subscriptionCost: Price;
  referralCredits: Price;
  pendingUserDowngrade?: PendingDowngradeStatus;
  pendingOrganisationDowngrade?: PendingDowngradeStatus;
  pendingBillingCycleChange?: PendingBillingCycleChangeStatus;
}
