import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppErrorCodes } from '@core/api';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '@core/common';
import { configSelectors } from '@core/store';
import { COUNTRY_CODES } from '@shared/common';
import { registerActions, registerSelectors } from '../../store';

type IForm = {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  phoneNumber: FormControl<string>;
  countryCode: FormControl<string>;
  sector: FormControl<string>;
  organisationType: FormControl<string>;
  policiesCheckbox: FormControl<boolean>;
  referralCode: FormControl<string>;
};

@Component({
  selector: 'la-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  public isActivationRequired = false;
  public isSubmitPending = false;
  public form: FormGroup<IForm>;

  public readonly COUNTRY_CODES = COUNTRY_CODES;
  public readonly licenceUrl = 'https://assets.thelandapp.com/landapp_user_terms_of_service_01.02.2022.pdf';
  public readonly privacyPolicyUrl = 'https://assets.thelandapp.com/landapp_privacy_policy_01.02.2022.pdf';
  public readonly termsOfUseUrl = 'https://assets.thelandapp.com/landapp_website_terms_of_use_01.02.2022.pdf';

  organisationTypes$ = this.store.select(configSelectors.getOrganisationTypes);
  sectors$ = this.store.select(configSelectors.getSectors);
  loading$ = this.store.select(registerSelectors.getRegisterLoading);

  hide = true;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.dispatch(registerActions.registrationPageOpened());

    const initialReferralCode = this.route.snapshot.queryParams['referralCode'] || '';

    this.buildForm(initialReferralCode);

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((change) => {
      this.form.get('phoneNumber').setValue(change.phoneNumber.replace(/[^0-9 ]+/g, ''), { emitEvent: false });
    });

    this.store
      .select(registerSelectors.getRegisterReady)
      .pipe(
        takeUntil(this.destroy$),
        filter((error) => !!error)
      )
      .subscribe(() => {
        this.isActivationRequired = true;
        this.cd.markForCheck();
      });

    this.store
      .select(registerSelectors.getRegisterError)
      .pipe(
        takeUntil(this.destroy$),
        filter((error) => !!error)
      )
      .subscribe((error) => {
        switch (error.code) {
          case AppErrorCodes.ALREADY_EXISTS:
            this.form.get('email').setErrors({ exists: true });
            break;
          case AppErrorCodes.INVALID_REFERRAL_CODE:
            this.form.get('referralCode').setErrors({ message: error.message });
            break;
          case AppErrorCodes.PASSWORD_TOO_WEAK:
            this.form.get('password').setErrors({ message: error.message });
            break;
          default:
            this.form.setErrors({ invalid: error.message });
        }

        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleVisibility() {
    this.hide = !this.hide;
  }

  public userAlreadyExists() {
    const errors = this.form.get('email').errors;
    return !!errors?.exists;
  }

  public register() {
    this.store.dispatch(registerActions.register(this.form.getRawValue()));
  }

  public valueAscOrder(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
    return a.value.localeCompare(b.value);
  }

  private buildForm(referralCode: string = '') {
    this.form = new FormGroup({
      firstName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(64), Validators.minLength(1)],
      }),
      lastName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(64), Validators.minLength(1)],
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(128), Validators.pattern(EMAIL_PATTERN)],
      }),
      password: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(64), Validators.pattern(PASSWORD_PATTERN)],
      }),
      phoneNumber: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(20)],
      }),
      countryCode: new FormControl('44', { validators: [Validators.required] }),
      sector: new FormControl(null, Validators.required),
      organisationType: new FormControl(null, Validators.required),
      policiesCheckbox: new FormControl(false, Validators.required),
      referralCode: new FormControl(referralCode),
    });
  }
}
