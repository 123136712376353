import { createAction, props } from '@ngrx/store';

import { MapChanges } from '@core/api';
import { AdvancedFilter } from '@shared/open-layers';
import { ActiveCollaborator } from '../models/collaborator.model';

type UploadPlanData = { id: string; body: { name: string; subtype: string; files: File[]; projection?: string } };

export const refershEstate = createAction('[Map/Effect] Refresh Estate');

export const fetchPlansOrder = createAction('[Map/Effect] Fetch Plan Order');

export const fetchPlanData = createAction('[Map/Effect] Fetch Plan Data', props<{ planId: string }>());

export const deletePlan = createAction('[Map/Effect] Delete Plan', props<{ planId: string }>());

export const duplicatePlan = createAction(
  '[Map/Effect] Duplicate Plan',
  props<{ geojson: GeoJSON.FeatureCollection; name: string; fromSubtype: string; toSubtype: string }>()
);

export const createFolder = createAction('[Map/Effect] Create Folder', props<{ name: string }>());

export const renameFolder = createAction('[Map/Effect] Rename Folder', props<{ id: string; name: string }>());

export const cancelEditFolder = createAction('[Map/Effect] Cancel Edit Folder');

export const cancelDeleteFolder = createAction('[Map/Effect] Cancel Delete Folder');

export const cancelPlanCreation = createAction('[Map/Effect] Cancel Plan Creation');

export const selectFeatures = createAction(
  '[Map/Effect] Select Features',
  props<{ layerId: string; filter: (string | number)[] | AdvancedFilter[]; delay?: number }>()
);

export const clearDrawings = createAction('[Map/Effect] Clear Drawings');

export const deselectAll = createAction('[Map/Effect] Deselect All');

export const deselectFeatures = createAction('[Map/Effect] Deselect Features');

export const exportPlans = createAction(
  '[Map/Effect] Export Plans',
  props<{ planIds: string[]; format: string; includePhotos: boolean }>()
);

export const uploadPlan = createAction('[Map/Effect] Upload Plan', props<{ queue: UploadPlanData[]; changes?: MapChanges }>());

export const uploadPlanProgress = createAction('[Map/Effect] Upload Plan Progress', props<{ id: string; progress: number }>());

export const uploadPlansComplete = createAction('[Map/Effect] Upload Plans Complete');

export const completePlanCreation = createAction('[Map/Effect] Complete Plan Creation', props<{ plans: string[] }>());

export const refreshPlan = createAction('[Map/Effect] Refresh Plan', props<{ planId: string }>());

export const clearLinkedPhoto = createAction('[Map/Effect] Clear Linked Photo', props<{ planId: string; mediaId: string }>());

/**
 * Socket Actions
 * ------------------------
 */
export const socketAddPlan = createAction('[Map/Page/Socket] Add Plan', props<{ planId: string; userId: string }>());

export const socketRefreshPlan = createAction('[Map/Page/Socket] Refresh Plan', props<{ planId: string; userId: string }>());

export const socketRemovePlan = createAction('[Map/Page/Socket] Remove Plan', props<{ planId: string; userId: string }>());

export const socketRefreshOrder = createAction('[Map/Page/Socket] Refresh Order', props<{ userId: string }>());

export const socketLockMap = createAction('[Map/Page/Socket] Lock Map');

export const socketUnlockMap = createAction('[Map/Page/Socket] Unlock Map');

export const socketCollaboratorInfo = createAction('[Map/Page/Socket] Collaborator Info', props<{ collaborators: ActiveCollaborator[] }>());
