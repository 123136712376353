import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({ selector: '[laDropFile]' })
export class DropFileDirective implements OnInit {
  @Input() isDragInactive?: boolean;
  @Output() public dropped: EventEmitter<FileList> = new EventEmitter();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const element = this.el.nativeElement;

    element.ondragover = (event: DragEvent) => this.handleDragOver(event);
    element.ondragleave = (event: DragEvent) => this.handleDragLeave(event);
    element.ondrop = (event: DragEvent) => this.handleDrop(event);
  }

  private handleDragOver(event: DragEvent) {
    event.preventDefault();
    this.el.nativeElement.classList.add('dragging');
  }

  private handleDragLeave(event: DragEvent) {
    event.preventDefault();
    this.el.nativeElement.classList.remove('dragging');
  }

  private handleDrop(event: DragEvent) {
    if (!this.isDragInactive) {
      event.preventDefault();
      this.el.nativeElement.classList.remove('dragging');

      const { files } = event.dataTransfer!;

      if (files && files.length > 0) {
        this.dropped.emit(files);
      }
    }
  }
}
