import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionTierName } from '@core/api';

type SubscriptionTierNameKey = keyof typeof SubscriptionTierName;

@Pipe({ name: 'laSubscriptionName' })
export class SubscriptionNamePipe implements PipeTransform {
  transform(value: SubscriptionTierNameKey): string {
    return SubscriptionTierName[value];
  }
}
