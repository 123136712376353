import { createReducer, on } from '@ngrx/store';
import { cloneDeep, uniq } from 'lodash';
import { initActions } from '@core/store';
import { leaveMap } from '../actions/map-page.actions';
import { ActiveCollaborator } from '../models/collaborator.model';
import {
  socketAddPlan,
  socketCollaboratorInfo,
  socketRefreshOrder,
  socketRefreshPlan,
  socketRemovePlan,
  socketUnlockMap,
} from '../actions/estate-effect.actions';

export const featureKey = 'activeCollaborators';

export type ActiveCollaboratorsState = ActiveCollaborator[];

export const initialState: ActiveCollaboratorsState = [];

export const reducer = createReducer<ActiveCollaboratorsState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, () => cloneDeep(initialState)),

  on(socketCollaboratorInfo, (_, { collaborators }) => collaborators),

  on(socketAddPlan, socketRemovePlan, socketRefreshPlan, (state, { planId, userId }) =>
    state.map((ac) =>
      ac.id === userId
        ? {
            ...ac,
            lockedLayers: ac.lockedLayers?.length ? uniq([...ac.lockedLayers, planId]) : [planId],
            lastEditDate: new Date().toISOString(),
          }
        : ac
    )
  ),

  on(socketRefreshOrder, (state, { userId }) =>
    state.map((ac) =>
      ac.id === userId
        ? {
            ...ac,
            layersOrderLock: true,
            lastEditDate: new Date().toISOString(),
          }
        : ac
    )
  ),

  on(socketUnlockMap, (state) => state.map((ac) => ({ ...ac, layersOrderLock: false, lockedLayers: [] })))
);
