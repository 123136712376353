import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ToolboxWorkflow } from '@core/api';
import { ActionUIState, initActions, StaticListUIState } from '@core/store';
import {
  closeRunToolboxDialog,
  fetchToolboxWorkflows,
  fetchToolboxWorkflowsFail,
  fetchToolboxWorkflowsSuccess,
  runToolboxWorkflow,
  runToolboxWorkflowFail,
  runToolboxWorkflowSuccess,
} from '../actions/toolbox.actions';

export const featureKey = 'toolbox';

export interface ToolboxState {
  workflows: StaticListUIState<ToolboxWorkflow>;
  runAction: ActionUIState;
}

export const initialState: ToolboxState = {
  workflows: { status: 'INIT' },
  runAction: { status: 'INIT' },
};

export const reducer = createReducer<ToolboxState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),

  on(fetchToolboxWorkflows, (state) => ({
    ...state,
    workflows: { ...state.workflows, status: state.workflows.data ? 'REFRESHING' : 'LOADING' },
  })),
  on(fetchToolboxWorkflowsSuccess, (state, { workflows }) => ({ ...state, workflows: { status: 'READY', data: workflows } })),
  on(fetchToolboxWorkflowsFail, (state, { error }) => ({ ...state, workflows: { ...state.workflows, status: 'ERROR', error } })),

  on(runToolboxWorkflow, (state) => ({ ...state, runAction: { status: 'LOADING' } })),
  on(runToolboxWorkflowSuccess, (state) => ({ ...state, runAction: { status: 'READY' } })),
  on(runToolboxWorkflowFail, (state, { error }) => ({ ...state, runAction: { status: 'ERROR', error } })),
  on(closeRunToolboxDialog, (state) => ({ ...state, runAction: { status: 'INIT' } }))
);
