<div class="custom-legend">
  @if (description) {
    <p>{{ description }}</p>
  }
  @for (label of labels; track $index) {
    <div class="custom-legend-label">
      <span class="marker" [ngStyle]="{ 'background-color': colors[$index] }"></span>
      <div class="custom-legend-text">
        <span>{{ label }}</span>
        <span>{{ getValue(label) }}</span>
      </div>
    </div>
  }
</div>
