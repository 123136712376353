import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'la-content-with-label',
  templateUrl: './content-with-label.component.html',
  styleUrls: ['./content-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWithLabelComponent {
  @Input() label?: string;
  @Input() info: string = '';
  @Input() icon?: string;
  @Input() iconColor?: string;
  @Output() clicked = new EventEmitter<void>();
}
