<la-header></la-header>

<section>
  <div class="content">
    <h1 class="title">Thank you for using Land App</h1>
    <p>You have signed out</p>
    <button type="submit" mat-raised-button color="primary" [routerLink]="['/login']">Log in</button>
  </div>
</section>

<la-footer></la-footer>
