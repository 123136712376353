import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-content-with-image',
  templateUrl: './content-with-image.component.html',
  styleUrls: ['./content-with-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWithImageComponent {
  @Input() title?: string;
  @Input() header?: string;
  @Input() url?: string;
  @Input() width?: string;
}
