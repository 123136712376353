<mat-card>
  <mat-card-content>
    <ng-container [ngSwitch]="error?.code">
      <h1 *ngSwitchCase="CODES.NO_INTERNET">No internet</h1>
      <h1 *ngSwitchCase="CODES.DEPRECATED">New version available</h1>
      <h1 *ngSwitchDefault>Error</h1>
    </ng-container>

    <ng-container [ngSwitch]="error?.code">
      <p *ngSwitchCase="CODES.NO_INTERNET">
        You're not connected to the internet at the moment, so any changes you make won't be saved. Please check your connection and try
        again.
      </p>
      <p *ngSwitchCase="CODES.DEPRECATED">
        Please refresh your browser or clear your cache to ensure you are using the latest version of the Land App.
      </p>
      <p *ngSwitchDefault>{{ error?.message }}</p>
    </ng-container>
    <button mat-raised-button color="primary" [routerLink]="['/']">Home</button>
  </mat-card-content>
</mat-card>
