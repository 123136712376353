import {
  BaseLayer,
  DataLayer,
  InteractiveLayer,
  OrganisationType,
  PrintBorder,
  ProjectTemplate,
  Region,
  Sector,
  SubscriptionsPrices,
} from '@core/api';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { initActions } from '../actions/init.actions';
import { userApiActions } from '../actions/user.actions';

export const featureKey = 'config';

export interface ConfigState {
  baseLayers: BaseLayer[];
  dataLayers: DataLayer[];
  printLayers: DataLayer[];
  interactiveLayers: InteractiveLayer[];
  regions: Region[] | null;
  projectTemplates?: ProjectTemplate[];
  sectors?: Sector[];
  organisationTypes?: OrganisationType[];
  subscriptionsPrices?: SubscriptionsPrices;
  printBorders?: PrintBorder[];
}

export const initialState: ConfigState = {
  baseLayers: [],
  dataLayers: [],
  printLayers: [],
  interactiveLayers: [],
  projectTemplates: [],
  sectors: [],
  organisationTypes: [],
  printBorders: [],
  regions: [],
};

export const reducer = createReducer(
  cloneDeep(initialState),
  on(initActions.appCleanup, (state) => ({
    ...cloneDeep(initialState),
    organisationTypes: state.organisationTypes,
    sectors: state.sectors,
  })),

  on(initActions.appComplete, userApiActions.loginSuccess, (state, { config }) => ({
    ...state,
    baseLayers: config?.baseLayers?.map((layer) => ({ ...layer, section: 'base-layer', readonly: true }) as DataLayer) || [],
    dataLayers:
      (config?.dataLayers?.filter((layer) => layer.section === 'data-layer') as DataLayer[])?.map((layer) => ({
        ...layer,
        readonly: true,
      })) || [],
    interactiveLayers:
      (config?.dataLayers?.filter((layer) => layer.section === 'interactive-map') as InteractiveLayer[])?.map((layer) => ({
        ...layer,
        readonly: true,
      })) || [],
    printLayers:
      (config?.dataLayers?.filter((layer) => layer.section === 'print-layer') as InteractiveLayer[])?.map((layer) => ({
        ...layer,
        readonly: true,
      })) || [],
    projectTemplates: config?.templates,
    sectors: config?.sectors,
    organisationTypes: config?.organisationTypes,
    subscriptionsPrices: config?.subscriptionsPrices,
    printBorders: config?.printBorders,
    regions: config?.regions || null,
  }))
);
