import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngleComponent } from './components/angle/angle.component';

import { CheckboxMultiselectComponent } from './components/checkbox-multiselect/checkbox-multiselect.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FilterAutocompleteComponent } from './components/filter-autocomplete/filter-autocomplete.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FiltersComponent } from './components/filters/filters.component';
import { LineTypeDropdownComponent } from './components/line-type-dropdown/line-type-dropdown.component';
import { NewTagFilterComponent } from './components/new-tag-filter/new-tag-filter.component';
import { SliderComponent } from './components/slider/slider.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatMenuModule,
    MatDividerModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
  ],
  declarations: [
    AngleComponent,
    CheckboxMultiselectComponent,
    LineTypeDropdownComponent,
    SliderComponent,
    FilterAutocompleteComponent,
    FilterBarComponent,
    FiltersComponent,
    NewTagFilterComponent,
    UploadImageComponent,
    ColorPickerComponent,
  ],
  exports: [
    AngleComponent,
    CheckboxMultiselectComponent,
    LineTypeDropdownComponent,
    SliderComponent,
    FilterAutocompleteComponent,
    FilterBarComponent,
    FiltersComponent,
    NewTagFilterComponent,
    UploadImageComponent,
    ColorPickerComponent,

    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
})
export class FormFieldsModule {}
