<ng-container *ngIf="actionButton">
  <div *ngIf="tooltip; else noTooltip" [matTooltip]="tooltip" [matTooltipDisabled]="!actionButtonDisabled">
    <button mat-raised-button color="primary" class="action" (click)="actionButtonClicked.emit()" [disabled]="actionButtonDisabled">
      {{ actionButton }}
    </button>
  </div>

  <ng-template #noTooltip>
    <button mat-raised-button color="primary" class="action" (click)="actionButtonClicked.emit()" [disabled]="actionButtonDisabled">
      {{ actionButton }}
    </button>
  </ng-template>
</ng-container>

<ng-content></ng-content>

<section *ngIf="showClear">
  <button color="primary" [class.secondary]="buttonSecondaryText" mat-button (click)="clear.emit()" type="button">
    {{ clearButtonText ? clearButtonText : 'Clear' }}
  </button>
</section>

<section class="right-actions" *ngIf="showRightActionButton">
  <button mat-raised-button color="primary" mat-button (click)="rightActionButtonClicked.emit()">
    <mat-icon class="action-icon">download</mat-icon>
    {{ rightActionButtonText }}
  </button>
</section>
