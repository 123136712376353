import { createAction, props } from '@ngrx/store';

import { AppError, FeatureChanges, Photo } from '@core/api';
import { apiActionFactory } from '@core/store';

export const [hidePhotosSuccess, hidePhotosFail] = apiActionFactory<{ id: string; updatedAt: string }>('[Photos/API] Hide Photos');

export const [showPhotosSuccess, showPhotosFail] = apiActionFactory<{ id: string; updatedAt: string }>('[Photos/API] Show Photos');

export const [fetchPhotoSuccess, fetchPhotoFail] = apiActionFactory<{ photo: Photo }>('[Photos/API] Fetch Photo');

export const [updatePhotoDataSuccess, updatePhotoDataFail] = apiActionFactory<{ photo: Photo }>('[Photos/API] Update Photo');

export const deletePhoto = createAction('[Photos/API] Delete Photo', props<{ photoId: string }>());
export const [deletePhotoSuccess, deletePhotoFail] = apiActionFactory<{ changes: any }>('[Photos/API] Delete Photo');

export const [unlinkPhotosSuccess, unlinkPhotosFail] = apiActionFactory<
  { planId: string; photoIds: string[]; changes: FeatureChanges },
  { error: AppError; planId: string }
>('[Photos/API] Unlink Photos');

export const [linkPhotosSuccess, linkPhotosFail] = apiActionFactory<
  { planId: string; photoIds: string[]; changes: FeatureChanges },
  { error: AppError; planId: string }
>('[Photos/API] Link Photos');
