import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Team } from '@core/api';
import { TagFilterValue } from '../../models/tag-filter.model';

type IForm = {
  search: FormControl<string>;
  team: FormControl<string[]>;
  sort: FormControl<'date' | 'name'>;
};

@Component({
  selector: 'la-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() teams: Team[] = [];
  @Input() search = '';
  @Input() team: string[] = [];
  @Input() sort: 'date' | 'name' = 'date';
  @Input() isInOrganization?: boolean;
  @Input() showTeamFilter: boolean = true;

  @Output() filtered = new EventEmitter<{ search: string; sort: 'name' | 'date'; team: string[] }>();
  @Output() clickOnFilter = new EventEmitter();
  @Output() selectedFilter = new EventEmitter<{ element: 'team' | 'sort'; value: string | string[] }>();
  @Output() searchPrints = new EventEmitter();
  @Output() clearFilters = new EventEmitter();

  form?: FormGroup<IForm>;
  autocompleteElements: TagFilterValue[] = [];

  private readonly destroy$ = new Subject<void>();

  ngOnInit() {
    this.buildForm();
    this.prepareAutocompleteElements();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['teams']) {
      this.prepareAutocompleteElements();
    }

    if (this.form && (changes['search'] || changes['team'] || changes['sort'])) {
      this.form.setValue({ search: this.search, team: this.team, sort: this.sort }, { emitEvent: false });
    }
  }

  reset() {
    this.form?.setValue({ search: '', team: [], sort: 'date' });
    this.clearFilters.emit();
  }

  private buildForm() {
    this.form = new FormGroup({
      search: new FormControl(this.search) as FormControl<string>,
      team: new FormControl(this.team) as FormControl<string[]>,
      sort: new FormControl(this.sort) as FormControl<'date' | 'name'>,
    });

    this.form?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe((value) => {
      this.filtered.emit(this.form?.getRawValue());
    });

    const options: ('team' | 'sort')[] = ['team', 'sort'];

    options.forEach((element) => {
      this.form
        ?.get(element)
        ?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
        .subscribe((value) => {
          this.selectedFilter.emit({ element, value });
        });
    });
  }

  private prepareAutocompleteElements() {
    this.autocompleteElements = this.teams
      ?.map((team) => ({ id: team.id, name: team.name, options: { color: team.color } }))
      .filter((team) => team.name !== 'Administrators');
  }
}
