import { createSelector } from '@ngrx/store';
import { isEmpty, isNil } from 'lodash';
import { getOrganisationState } from './main.selectors';

export const getOrganisation = createSelector(getOrganisationState, (state) => state);

export const isInOrganisation = createSelector(getOrganisationState, (state) => !isNil(state) && !isEmpty(state));

export const getOSApiKey = createSelector(getOrganisationState, (state) => state?.osApiKey);

export const getOrganisationType = createSelector(getOrganisationState, (state) => state?.organisationType);

export const getBillingCycle = createSelector(getOrganisationState, (state) => state?.billingCycle);

export const getBillingData = createSelector(getOrganisationState, (state) => ({
  billingCycle: state?.billingCycle,
  billingStartDate: state?.billingStartDate,
}));

export const getBillingRecipients = createSelector(getOrganisationState, (state) =>
  state ? (!state.billingRecipients?.length ? [state.owner.email] : state.billingRecipients) : []
);

export const getBillingDetails = createSelector(getOrganisationState, (state) => state?.billingDetails);

export const getUserHasNatureReportingAccess = createSelector(getOrganisation, (organisation) => {
  if (organisation?.natureReporting) {
    return organisation?.teams.find((team) => team.type === 'ADMINISTRATION') ? organisation?.natureReporting?.enabled : false;
  }
  return undefined;
});

export const getHasPricePerHa = createSelector(getOrganisationState, (state) => !!state?.natureReporting?.pricePerHa);
