import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { SearchResult } from '@core/api';
import { ItemUIState, initActions } from '@core/store';
import { leaveMap } from '../actions/map-page.actions';
import * as searchActions from '../actions/search.actions';
import { parseOSGridRef } from '../utils/search.utils';

export const featureKey = 'search';

export interface SearchState {
  phrase: string;
  googlePlacesResults: ItemUIState<{ [key: string]: SearchResult[] }>;
  landRegistryTitles: ItemUIState<{ [key: string]: SearchResult[] }>;
  what3wordsResults: ItemUIState<{ [key: string]: SearchResult[] }>;
  gridRefResult?: SearchResult;
}

export const initialState: SearchState = {
  phrase: '',
  googlePlacesResults: { status: 'INIT' },
  landRegistryTitles: { status: 'INIT' },
  what3wordsResults: { status: 'INIT' },
};

export const reducer = createReducer<SearchState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, () => cloneDeep(initialState)),

  on(searchActions.search, (state, { phrase }) => ({
    ...state,
    phrase,
  })),
  on(searchActions.clear, (state) => ({
    ...state,
    phrase: initialState.phrase,
    what3wordsResults: initialState.what3wordsResults,
  })),

  on(searchActions.searchGooglePlaces, (state, { phrase }) => ({
    ...state,
    googlePlacesResults: {
      ...state.googlePlacesResults,
      status: phrase && !Object.keys(state?.googlePlacesResults).includes(phrase) ? 'LOADING' : 'INIT',
    },
  })),
  on(searchActions.searchGooglePlacesSuccess, (state, { phrase, response }) => ({
    ...state,
    googlePlacesResults: {
      ...state.googlePlacesResults,
      status: 'READY',
      [phrase]: response,
    },
  })),
  on(searchActions.searchGooglePlacesFail, (state) => ({
    ...state,
    googlePlacesResults: {
      ...state.googlePlacesResults,
      status: 'ERROR',
    },
  })),

  on(searchActions.searchLandRegistry, (state, { phrase }) => ({
    ...state,
    landRegistryTitles: {
      ...state.landRegistryTitles,
      status: phrase && !Object.keys(state?.landRegistryTitles).includes(phrase) ? 'LOADING' : 'INIT',
    },
  })),
  on(searchActions.searchLandRegistrySuccess, (state, { phrase, response }) => ({
    ...state,
    landRegistryTitles: {
      ...state.landRegistryTitles,
      status: 'READY',
      [phrase]: response,
    },
  })),
  on(searchActions.searchLandRegistryFail, (state) => ({
    ...state,
    landRegistryTitles: {
      ...state.landRegistryTitles,
      status: 'ERROR',
    },
  })),

  on(searchActions.searchWhat3Words, (state, { phrase }) => ({
    ...state,
    what3wordsResults: {
      ...state.what3wordsResults,
      status: phrase && !Object.keys(state?.what3wordsResults).includes(phrase) ? 'LOADING' : 'INIT',
    },
  })),
  on(searchActions.searchWhat3WordsSuccess, (state, { phrase, response }) => ({
    ...state,
    what3wordsResults: {
      ...state.what3wordsResults,
      status: 'READY',
      [phrase]: response,
    },
  })),
  on(searchActions.searchWhat3WordsFail, (state) => ({
    ...state,
    what3wordsResults: {
      ...state.what3wordsResults,
      status: 'ERROR',
    },
  })),

  on(searchActions.searchGridRef, (state, { phrase }) => ({
    ...state,
    gridRefResult: parseOSGridRef(phrase),
  }))
);
