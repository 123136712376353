import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get, pick } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, switchMap, takeWhile, tap } from 'rxjs/operators';
import { hydrationActions } from '../actions/hydration.actions';
import { getUser } from '../selectors/user.selectors';

@Injectable()
export class HydrationEffects {
  hydrate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hydrationActions.hydrate),
      concatLatestFrom(() => this.store.select(getUser)),
      switchMap(([{ persistedKeys }, user]) => {
        return persistedKeys.map((element) => {
          const userId = user?.id;
          const storageKey = `${element.key.join('.')}.${userId}`;
          const storageValue = localStorage.getItem(storageKey);
          if (storageValue) {
            try {
              const newState = JSON.parse(storageValue);
              return hydrationActions.hydrateSuccess({ element, newState, userId });
            } catch {
              localStorage.removeItem(storageKey);
            }
          }
          return hydrationActions.hydrateFail({ element, userId });
        });
      })
    )
  );

  persistInLocalstorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hydrationActions.hydrateSuccess, hydrationActions.hydrateFail),
        mergeMap((action) => {
          return combineLatest([of(action), this.store.select((state) => get(state, action.element.key)), this.store.select(getUser)]).pipe(
            takeWhile(([, , user]) => !!user),
            filter(([{ userId }, , user]) => !!user && userId === user?.id),
            distinctUntilChanged(),
            tap(([{ element, userId }, state]) =>
              localStorage.setItem(`${element.key.join('.')}.${userId}`, JSON.stringify(pick(state, element.sections)))
            )
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store
  ) {}
}
