import { hasEntity, updateEntities, upsertEntity } from '@core/store';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { Photo } from '@core/api';
import { ActionUIState, initActions, ItemUIState, StaticListUIState } from '@core/store';
import * as photosApiActions from '../actions/api/photos-api.actions';
import * as dialogsActions from '../actions/dialogs.actions';
import * as estateEffectActions from '../actions/estate-effect.actions';
import * as mapPageActions from '../actions/map-page.actions';
import * as photosUploadActions from '../actions/photos-upload.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';

export const featureKey = 'photos';

export interface PhotoUpload extends ActionUIState {
  id: string;
  name: string;
  progress: number;
}

export interface PhotosState extends StaticListUIState<GeoJSON.Feature> {
  uploads?: PhotoUpload[];
  details?: { [key: string]: ItemUIState<Photo> };
  action: PhotoAction;
}

export interface PhotoAction extends ActionUIState {
  planId?: string;
}

export const initialState: PhotosState = {
  status: 'INIT',
  uploads: [],
  action: {
    status: 'INIT',
  },
};

export const reducer = createReducer<PhotosState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, mapPageActions.leaveMap, () => cloneDeep(initialState)),

  on(photosUploadActions.resetPhotoUploads, (state) => ({ ...state, uploads: [] })),

  on(mapPageActions.fetchPhotos, (state) => ({
    ...state,
    status: 'LOADING',
  })),

  on(photosUploadActions.refreshPhotos, (state) => ({
    ...state,
    status: 'REFRESHING',
  })),

  on(photosUploadActions.refreshPhotosSuccess, (state, { features }) => ({
    ...state,
    data: features,
    status: 'READY',
  })),

  on(photosUploadActions.refreshPhotosFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  })),

  on(photosUploadActions.uploadPhoto, (state, { id, photo }) => ({
    ...state,
    uploads: [
      ...state.uploads,
      {
        id,
        status: 'LOADING',
        name: photo.name,
        progress: 0,
      },
    ],
  })),

  on(photosUploadActions.cancelPhotoUpload, (state, { id }) => ({
    ...state,
    uploads: state.uploads?.filter((upload) => upload.id !== id),
  })),

  on(photosUploadActions.uploadPhotoSuccess, (state, { id }) => ({
    ...state,
    uploads: state.uploads?.map((upload) =>
      upload.id !== id
        ? upload
        : {
            ...upload,
            status: 'READY',
            progress: 100,
          }
    ),
  })),
  on(photosUploadActions.uploadPhotoFail, (state, { id, error }) => ({
    ...state,
    uploads: state.uploads?.map((upload) =>
      upload.id !== id
        ? upload
        : {
            ...upload,
            status: 'ERROR',
            error,
          }
    ),
  })),
  on(photosUploadActions.setUploadPhotoProgress, (state, { id, progress }) => ({
    ...state,
    uploads: state.uploads?.map((upload) =>
      upload.id !== id
        ? upload
        : {
            ...upload,
            progress,
          }
    ),
  })),

  on(photosApiActions.fetchPhotoSuccess, (state, { photo }) => ({
    ...state,
    details: upsertEntity(state.details, photo.id, (entity) => ({
      ...entity,
      status: 'READY',
      data: photo,
    })),
  })),

  on(rightHandPanelActions.editPhoto, (state) => ({
    ...state,
    action: {
      status: 'LOADING',
    },
  })),

  on(photosApiActions.updatePhotoDataSuccess, (state, { photo }) => ({
    ...state,
    details: upsertEntity(state.details, photo.id, (entity) => ({
      ...entity,
      status: 'READY',
      data: { ...photo, owner: entity.data.owner, plans: entity.data.plans },
    })),
    action: {
      status: 'READY',
    },
  })),

  on(photosApiActions.updatePhotoDataFail, (state, { error }) => ({
    ...state,
    action: {
      status: 'ERROR',
      error,
    },
  })),

  on(rightHandPanelActions.linkPhotos, rightHandPanelActions.unlinkPhotos, (state, { planId }) => ({
    ...state,
    action: { status: 'LOADING', planId },
  })),

  on(photosApiActions.linkPhotosSuccess, (state, { planId, photoIds, changes }) => ({
    ...state,
    action: {
      status: 'READY',
      planId: planId,
    },
    details: updateEntities(state.details, (entity) =>
      photoIds.includes(entity.data.id)
        ? {
            ...entity,
            status: 'READY',
            data: { ...entity.data, plans: [...entity.data.plans, changes.plan] },
          }
        : entity
    ),
  })),

  on(photosApiActions.unlinkPhotosSuccess, (state, { planId, photoIds }) => ({
    ...state,
    action: {
      status: 'READY',
      planId: planId,
    },
    details: updateEntities(state.details, (entity) =>
      photoIds.includes(entity.data.id)
        ? {
            ...entity,
            status: 'READY',
            data: { ...entity.data, plans: entity.data.plans.filter((link) => link.id !== planId) },
          }
        : entity
    ),
  })),

  on(photosApiActions.linkPhotosFail, photosApiActions.unlinkPhotosFail, (state, { error, planId }) => ({
    ...state,
    action: {
      planId,
      status: 'ERROR',
      error,
    },
  })),

  on(dialogsActions.closeLinkPhotoDialog, (state) => ({ ...state, action: { status: 'INIT' } })),

  on(estateEffectActions.clearLinkedPhoto, (state, { planId, mediaId }) => ({
    ...state,
    details: hasEntity(state.details, mediaId)
      ? upsertEntity(state.details, mediaId, (entity) => ({
          ...entity,
          data: { ...entity.data, plans: entity.data.plans.filter((plan) => plan.id !== planId) },
        }))
      : state.details,
  }))
);
