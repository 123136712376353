import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLogin from '../reducers/login.reducer';
import * as fromMain from '../reducers/main.reducer';
import * as fromRegister from '../reducers/register.reducer';
import * as fromUserSetup from '../reducers/user-setup.reducer';
import * as fromJoinOrganisation from '../reducers/join-organisation.reducer';

export const getUIAuthState = createFeatureSelector<fromMain.MainState>(fromMain.featureKey);

export const getLoginState = createSelector(getUIAuthState, (state) => state[fromLogin.featureKey]);

export const getRegisterState = createSelector(getUIAuthState, (state) => state[fromRegister.featureKey]);

export const getUserSetupState = createSelector(getUIAuthState, (state) => state[fromUserSetup.featureKey]);

export const getJoinOrganisationState = createSelector(getUIAuthState, (state) => state[fromJoinOrganisation.featureKey]);
