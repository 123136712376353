import { createAction, props } from '@ngrx/store';

import { AppError, Plan } from '@core/api';
import { Operation } from '../../models/operations.model';

export const fetchPlan = createAction('[Plans/Effect] Fetch Plan', props<{ planId: string }>());

export const fetchFeature = createAction('[Plans/Effect] Fetch Feature', props<{ planId: string; featureId: string }>());

export const fetchFeatures = createAction('[Plans/Effect] Fetch Features', props<{ planId: string; featureIds: string[] }>());

export const addOperations = createAction('[Plans/Effect] Add Operations', props<{ planId: string; operations: Operation[] }>());

export const addUndoOperations = createAction('[Plans/Effect] Add Undo Operations', props<{ planId: string; operations: Operation[] }>());

export const addRedoOperations = createAction('[Plans/Effect] Add Redo Operations', props<{ planId: string; operations: Operation[] }>());

export const saveOperations = createAction('[Plans/Effect] Save Operations');

export const updatePlansInsideFolder = createAction(
  '[Plans/Effect] Update Plans Inside Folder',
  props<{ updates: { planId: string; data: Partial<Plan> }[] }>()
);

export const showFeatures = createAction('[Plans/Effect] Show Features', props<{ planId: string; featuresIds: (string | number)[] }>());

export const hideFeatures = createAction('[Plans/Effect] Hide Features', props<{ planId: string; featuresIds: (string | number)[] }>());

export const deleteFeatures = createAction('[Plans/Effect] Delete Features', props<{ planId: string; featuresIds: (string | number)[] }>());

export const deleteSelectedPhotos = createAction('[Plans/Effect] Delete Selected Photos');

export const openDeleteLinkedPhotoWarningDialog = createAction('[Plans/Effect] Open Delete Linked Photos Warning Dialog');

export const deletePhotos = createAction('[Plans/Effect] Delete Photos');

export const updateFeaturesProperties = createAction(
  '[Plans/Effect] Update Features Properties',
  props<{ planId: string; changes: { id: string | number; properties: GeoJSON.GeoJsonProperties }[] }>()
);

export const removeFeatureProperties = createAction(
  '[Plans/Effect] Remove Features Properties',
  props<{ planId: string; changes: { id: string | number; keys: string[] }[] }>()
);

export const splitFeaturesFail = createAction('[Plans/Effect] Split Features Fail', props<{ error: AppError }>());

export const mergeFeaturesFail = createAction('[Plans/Effect] Merge Features Fail', props<{ error: AppError }>());

export const clearCreatedPlanId = createAction('[Plans/Effect] Clear Created Plan Id');
