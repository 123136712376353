import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class GtagService {
  gtag: any;

  userData:
    | {
        user_id: string;
        org_type: string;
        subscription_tier: string;
        sector: string;
        organisation_name?: string;
      }
    | undefined;

  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig) {
    this.install();
  }

  install() {
    if (this.config.gtag) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.config.gtag.key}`;

      const other = document.getElementsByTagName('script')[0];
      other.parentNode?.insertBefore(script, other);

      (window as any).dataLayer = (window as any).dataLayer || [];
      function gtag(..._args: (string | Date | unknown)[]) {
        (window as any).dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', this.config.gtag.key, { send_page_view: false });

      this.gtag = gtag;
      (window as any).gtag = gtag;
    }
  }

  event(action: string, params?: any) {
    if (this.gtag) {
      this.gtag('event', action, { event_category: this.config.gtag.category, ...params, ...this.userData });
      if (this.config.gtag.debug) {
        console.log('[gtag]', action, { ...params, ...this.userData });
      }
    }
  }

  setUser(params?: { user_id: string; org_type: string; subscription_tier: string; sector: string; organisation_name?: string }) {
    if (params) {
      return (this.userData = { ...params });
    }
    return (this.userData = undefined);
  }
}
