import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { limitsActions } from '../actions/limits.actions';

export const featureKey = 'limits';

export type LimitsState = {
  maps?: {
    balance: number;
    limit: number | 'unlimited';
  };
  archivedMaps?: {
    balance: number;
    limit: number | 'unlimited';
  };
};

export const initialState: LimitsState = {};

export const reducer = createReducer<LimitsState>(
  initialState,
  on(initActions.appCleanup, () => initialState),

  on(limitsActions.fetchMapsBalanceSuccess, (state, { data }) => ({
    ...state,
    ...data,
  }))
);
