import { Pipe, PipeTransform } from '@angular/core';
import { PAPER_SIZES_LOOKUP } from '@core/api';

type PaperSizeKey = keyof typeof PAPER_SIZES_LOOKUP;

/*
 * Return the friendly name of a PAPER_SIZE string
 * Usage:
 *   'A4_LANDSCAPE' | laPaperSize
 *
 */
@Pipe({ name: 'laPaperSize' })
export class PaperSizePipe implements PipeTransform {
  transform(value: PaperSizeKey): string | string[] {
    return PAPER_SIZES_LOOKUP[value] || value;
  }
}
