import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppError, User } from '@core/api';

import { AppComplete } from '../models/app.models';

export const userActions = createActionGroup({
  source: 'User',
  events: {
    Login: props<{ email: string; password: string }>(),
    Logout: emptyProps(),
  },
});

export const userApiActions = createActionGroup({
  source: 'User/API',
  events: {
    'Login Success': props<AppComplete>(),
    'Login Fail': props<{ error: AppError }>(),
    'Logout Success': emptyProps(),
    'Logout Fail': props<{ error: AppError }>(),
    'Token Expired': emptyProps(),
    'Fetch User Success': props<{ user: User }>(),
    'Fetch User Fail': props<{ error: AppError }>(),
    'Update User Success': props<{ user: Partial<User> }>(),
    'Update User Fail': props<{ error: AppError }>(),
  },
});
