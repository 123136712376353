import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'laSort' })
export class SortPipe implements PipeTransform {
  transform(value: string[], asc: 'asc' | 'desc' = 'asc'): any[] {
    return [...value].sort((a, b) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      return asc === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    });
  }
}
