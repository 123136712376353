import { createSelector } from '@ngrx/store';
import { getLoginState } from './main.selectors';
import { AppErrorCodes } from '@core/api';

export const getLoginLoading = createSelector(getLoginState, ({ status }) => status === 'LOADING');
export const getLoginError = createSelector(getLoginState, ({ status, error }) => (status === 'ERROR' ? error : undefined));

export const getLoginErrorMessage = createSelector(getLoginState, ({ status, error }) => {
  if (status === 'ERROR') {
    switch (error.code) {
      case AppErrorCodes.TEMPORARILY_BLOCKED:
        return error.message;

      case AppErrorCodes.USER_SUSPENDED:
        return error.message;

      default:
        return 'Wrong email or password. Please try again.';
    }
  }

  return undefined;
});

export const getLoginStatus = createSelector(getLoginState, ({ status, error }) => {
  if (status === 'ERROR') {
    switch (error.code) {
      case AppErrorCodes.NOT_ACTIVE:
        return error.code;

      case AppErrorCodes.PASSWORD_RESET_REQUIRED:
        return error.code;
    }
  }

  return undefined;
});
