import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'laTimespan',
})
export class TimespanPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(value: Date | string | number, format: string = ''): string {
    return this.lastEditedIntervalInMinutes(value) < 720 ? this.lastEditedAt(value) : formatDate(value, format, this.locale);
  }

  private lastEditedIntervalInMinutes(date: Date | string | number) {
    const now = new Date().getTime();
    const lastUpdated = new Date(date).getTime();
    const diffTime = Math.abs(now - lastUpdated);
    return Math.floor(diffTime / (1000 * 60));
  }

  private lastEditedAt(date: Date | string | number) {
    const minutes = this.lastEditedIntervalInMinutes(date);
    if (minutes < 60) {
      return minutes + ` minute${minutes === 1 ? '' : 's'} ago`;
    }
    const hours = Math.floor(minutes / 60);
    return hours + ` hour${hours === 1 ? '' : 's'} ago`;
  }
}
