<div class="label">{{ label }}</div>

<button mat-flat-button [matMenuTriggerFor]="menu" class="menu-trigger">
  <img *ngIf="selectedType" [src]="typePreview(selectedType.id)" />
  <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
</button>

<mat-menu #menu="matMenu" class="la-menu">
  <ng-container *ngFor="let lineType of lineTypes">
    <button mat-menu-item (click)="onSelect(lineType)">
      <img [src]="typePreview(lineType.id)" class="line-type" />
    </button>
    <mat-divider></mat-divider>
  </ng-container>
</mat-menu>
