import { HttpErrorResponse } from '@angular/common/http';
import { AppErrorCodes } from '../enums/error.enum';
import { ApiError, AppError } from '../models/error.model';

export interface ErrorSchema {
  condition: (res: ApiError, http: HttpErrorResponse) => boolean;
  output: (res: ApiError, http: HttpErrorResponse) => Partial<AppError>;
}

export function errorWrapper(schemas: ErrorSchema[] = []) {
  return (error: Error | HttpErrorResponse): AppError => {
    if (error instanceof HttpErrorResponse) {
      const response = error.error.error as ApiError;
      let result: AppError | null = null;

      schemas.forEach((schema) => {
        if (schema.condition(response, error)) {
          result = {
            type: 'REQUEST',
            url: error.url || undefined,
            status: error.status,
            message: response.title,
            code: response.status === 0 ? AppErrorCodes.NO_INTERNET : response.status?.toString() || AppErrorCodes.SERVER_ERROR,
            meta: response.meta,
            ...schema.output(response, error),
          };
        }
      });

      if (!result) {
        return {
          type: 'REQUEST',
          url: error.url || undefined,
          status: error.status,
          message: error.status === 0 ? 'No internet' : response?.title,
          code: error.status === 0 ? AppErrorCodes.NO_INTERNET : response?.status?.toString() || AppErrorCodes.SERVER_ERROR,
          meta: response?.meta,
        };
      }

      return result;
    } else {
      return {
        type: 'CODE',
        code: AppErrorCodes.CODE_ERROR,
        message: error.message,
        details: error.stack,
      };
    }
  };
}

export function handleError(error: Error | HttpErrorResponse) {
  return errorWrapper()(error);
}

export function handleCustomError(message: string, details?: string): AppError {
  return {
    type: 'CUSTOM',
    code: AppErrorCodes.CUSTOM_ERROR,
    message,
    ...(details ? { details } : undefined),
  };
}
