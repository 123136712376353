import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppError } from '@core/api';

import { AppComplete } from '../models/app.models';

export const initActions = createActionGroup({
  source: 'Init',
  events: {
    'App Init': emptyProps(),
    'App Complete': props<AppComplete>(),
    'App Error': props<{ error: AppError }>(),
    'App Cleanup': emptyProps(),
  },
});
