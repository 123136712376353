import { Action, combineReducers } from '@ngrx/store';

import { AppState } from '@core/store';
import * as fromJoinOrganisation from './join-organisation.reducer';
import * as fromLogin from './login.reducer';
import * as fromRegister from './register.reducer';
import * as fromUserSetup from './user-setup.reducer';

export const featureKey = 'uiAuth';

export interface MainState {
  [fromLogin.featureKey]: fromLogin.LoginUIState;
  [fromRegister.featureKey]: fromRegister.RegisterUIState;
  [fromUserSetup.featureKey]: fromUserSetup.UserSetupUIState;
  [fromJoinOrganisation.featureKey]: fromJoinOrganisation.JoinOrganisationUIState;
}

export interface State extends AppState {
  [featureKey]: MainState;
}

export function reducers(state: MainState | undefined, action: Action) {
  return combineReducers({
    [fromLogin.featureKey]: fromLogin.reducer,
    [fromRegister.featureKey]: fromRegister.reducer,
    [fromUserSetup.featureKey]: fromUserSetup.reducer,
    [fromJoinOrganisation.featureKey]: fromJoinOrganisation.reducer,
  })(state, action);
}
