import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { auditTime, filter, tap } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class UploadImageService {
  file: File | null = null;
  error: string | null = null;
  progress$: Observable<number>;
  upload$: ReplaySubject<string> = new ReplaySubject(1);
  fail$: ReplaySubject<any> = new ReplaySubject(1);

  private uploading$?: Subscription;
  private uploadStatus$: BehaviorSubject<number> = new BehaviorSubject(0);

  private config = inject<AppConfig>(APP_CONFIG);

  constructor(private http: HttpClient) {
    this.progress$ = this.uploadStatus$.pipe(auditTime(250));
  }

  upload(file: FileList | null, endpoint: string = '/prints/logo') {
    this.reset();

    if ((this.uploading$ && !this.uploading$.closed) || !file) {
      return;
    }

    this.file = file[0];

    const formData = new FormData();
    formData.append('file', file[0]);
    this.uploading$ = this.http
      .post(`${this.config.api.mediaUrl}${endpoint}`, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        tap((event: HttpEvent<any>) => {
          switch (event.type) {
            case 0:
              this.uploadStatus$.next(0);
              break;
            case 1:
              const { loaded, total } = event as any;
              const progress = Math.round((loaded / total) * 100);
              this.uploadStatus$.next(progress);
              break;
            case 3:
              this.uploadStatus$.next(100);
              break;
          }
        }),
        filter((event) => event instanceof HttpResponse)
      )
      .subscribe(
        (response: any) => {
          if (response.body.locationStorage) {
            this.upload$.next(response.body.locationStorage);
          } else {
            this.upload$.next(response.body);
          }
        },
        (error: HttpErrorResponse) => {
          this.fail$.next(error.error);
        }
      );
  }

  cancel() {
    this.reset();
    if (this.uploading$ && !this.uploading$.closed) {
      this.uploading$.unsubscribe();
    }
  }

  reset() {
    this.file = null;
    this.uploadStatus$.next(0);
  }
}
