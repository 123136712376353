import { omit } from 'lodash';
import { ItemUIState } from '../models/states.models';

interface WithID {
  id: string | number;
}

export function arrayToEntity<T extends WithID>(
  arr: T[],
  setStatus?: (data: T) => ItemUIState<T>['status']
): { [key: string]: ItemUIState<T> } {
  return arr.reduce((acc: { [key: string]: ItemUIState<T> }, current) => {
    acc[current.id] = {
      data: current,
      status: setStatus ? setStatus(current) : 'INIT',
    };
    return acc;
  }, {});
}

export function createEntities<T, D extends WithID>(arr: D[], transform: (el: D) => T): { [key: string]: T } {
  return arr.reduce((acc: { [key: string]: T }, current) => {
    acc[current.id] = transform(current);
    return acc;
  }, {});
}

export function upsertEntity<T>(collection: { [key: string]: T }, key: string | undefined, cb: (value: T | null) => T) {
  if (key) {
    if (!collection) {
      return { [key]: cb(null) };
    }
    return {
      ...collection,
      [key]: cb(collection[key]),
    };
  }

  return collection;
}

export function deleteEntity<T>(collection: { [key: string]: T }, key: string) {
  return omit(collection, key);
}

export function deleteEntities<T>(collection: { [key: string]: T }, keys: string[]) {
  return omit(collection, keys);
}

export function upsertArray<T extends Record<string, any>>(collection: T[], data: T, by = 'id'): T[] {
  const index = collection.findIndex((el) => el[by] === data[by]);
  return index > -1 ? collection.map((el) => (el[by] === data[by] ? data : el)) : [...collection, data];
}

export function updateEntities<T>(collection: { [key: string]: T }, cb: (value: T) => T) {
  return Object.keys(collection).reduce<{ [key: string]: T }>((acc, key) => {
    return {
      ...acc,
      [key]: cb(collection[key]),
    };
  }, {});
}

export function someEntities<T>(collection: { [key: string]: T }, condition: (value: T) => boolean) {
  const arr = Object.keys(collection).map((id) => collection[id]);

  return arr.some(condition);
}

export function findEntities<T>(collection: { [key: string]: T }, condition: (value: T) => boolean) {
  const arr = Object.keys(collection).map((id) => collection[id]);

  return arr.filter(condition);
}

export function hasEntity<T>(collection: { [key: string]: T }, key: string): boolean {
  return !!collection && !!collection[key];
}
