<la-header></la-header>

<section>
  @if (!formSent){
  <div class="content">
    <h1 class="title">Forgot password</h1>
    <p>Please enter the email address for your account and we will send you instructions to reset your password</p>
    @if (isGenericError) {
    <mat-error>Wrong email address. Please try again</mat-error>
    }
    <form [formGroup]="form">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Email address</mat-label>
        <input type="email" placeholder="Email address" matInput formControlName="email" maxLength="128" required />
        @if (form.controls['email'].invalid) {
        <mat-error>Please enter a valid email address</mat-error>
        }
      </mat-form-field>
    </form>

    <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || disabled" (click)="send()">
      @if(form.invalid){ <span>Reset password</span> } @else if(form.valid){ <span>Email me instructions</span> }
    </button>
  </div>

  } @if(formSent){ <la-reset-password-required [email]="this.form.value.email"></la-reset-password-required> }
</section>

<la-footer></la-footer>
