import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Invitation, InvitationRequest } from '../models/invitation.model';
import { Member, UpdateMember } from '../models/member.model';
import { ApiKey, Discount, Organisation, OrganisationRequest } from '../models/organization.model';
import { Team, TeamCategory, TeamRole } from '../models/team.model';
import { SubscriptionPrice } from '../models/user.model';
import { applyParams } from '../utils/apply-params.utils';
import { deserialise } from '../operators/deserialise.operator';
import { NatureReporting } from '../models/nature-reporting.model';

interface ListQueryParams {
  size?: number;
  page?: number;
  search?: string;
  team?: string[];
  sort?: string;
  order?: string;
}

@Injectable({ providedIn: 'root' })
export class OrganizationApiService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private http: HttpClient
  ) {}

  fetchOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(`${this.config.api.mapUrl}/organisation`).pipe(deserialise());
  }

  fetchOrganisations(parameters: ListQueryParams): Observable<Organisation[]> {
    const params = applyParams(parameters);
    return this.http.get<Organisation[]>(`${this.config.api.mapUrl}/organisations`, { params }).pipe(deserialise());
  }

  createOrganisation(data: OrganisationRequest): Observable<Organisation> {
    return this.http.post<Organisation>(`${this.config.api.mapUrl}/organisation`, { ...data }).pipe(deserialise());
  }

  updateOrganisation(data: OrganisationRequest): Observable<Organisation> {
    return this.http.patch<Organisation>(`${this.config.api.mapUrl}/organisation`, { ...data }).pipe(deserialise());
  }

  validateOrganisation(name: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/validate`, { name });
  }

  deleteOsApiKey() {
    return this.http.delete<Organisation>(`${this.config.api.mapUrl}/organisation/osapikey`).pipe(deserialise());
  }

  fetchInvitations(parameters: ListQueryParams): Observable<Invitation[]> {
    const params = applyParams(parameters);

    return this.http.get<Invitation[]>(`${this.config.api.mapUrl}/organisation/invitations`, { params }).pipe(deserialise());
  }

  createInvitation(body: InvitationRequest) {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/invitations`, body);
  }

  acceptInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/invitations/${id}/accept`, {});
  }

  rejectInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/invitations/${id}/reject`, {});
  }

  deleteInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/invitations/${id}/delete`, {});
  }

  resendInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/invitations/${id}/resend`, {});
  }

  fetchMembers(parameters: ListQueryParams): Observable<Member[]> {
    const params = applyParams(parameters);
    return this.http.get<Member[]>(`${this.config.api.mapUrl}/organisation/members`, { params }).pipe(deserialise());
  }

  editMember(id: string, update: UpdateMember) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/organisation/members/${id}`, update);
  }

  removeMember(id: string) {
    return this.http.delete<void>(`${this.config.api.mapUrl}/organisation/members/${id}`, {});
  }

  fetchMemberReactivationPrice(id: string) {
    return this.http.post<SubscriptionPrice>(`${this.config.api.mapUrl}/organisation/members/${id}/reactivate/price/calculate`, {});
  }

  reactivateMember(id: string) {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/members/${id}/reactivate`, {});
  }

  fetchTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.config.api.mapUrl}/organisation/teams`).pipe(deserialise());
  }

  addMemberToTeam(userId: string, teamId: string) {
    return this.http.post<Member>(`${this.config.api.mapUrl}/organisation/teams/${teamId}/members`, { user: userId }).pipe(
      deserialise(),
      map((member) => {
        const source = member.teams ? member.teams : member.organisation?.teams || [];
        return source.find((team) => team.id === teamId);
      })
    );
  }

  removeMemberFromTeam(userId: string, teamId: string) {
    return this.http.request('delete', `${this.config.api.mapUrl}/organisation/teams/${teamId}/members`, {
      body: { user: userId },
    });
  }

  changeRoleInTeam(teamId: string, memberId: string, role: TeamRole) {
    return this.http.patch(`${this.config.api.mapUrl}/organisation/teams/${teamId}/members/${memberId}`, {
      role,
    });
  }

  memberEmailChange(id: string, email: string) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/organisation/members/${id}/email`, { email });
  }

  exportMembers(parameters?: ListQueryParams) {
    const params = parameters ? applyParams(parameters) : undefined;
    return this.http.get(`${this.config.api.mapUrl}/organisation/members/export`, { params, responseType: 'blob' });
  }

  createTeam(name: string, category: string, color: string) {
    return this.http.post<Team>(`${this.config.api.mapUrl}/organisation/teams`, { name, category, color }).pipe(deserialise());
  }

  editTeam(id: string, data: { name?: string; category?: string; color?: string }) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/organisation/teams/${id}`, { ...data });
  }

  removeTeam(id: string) {
    return this.http.delete<void>(`${this.config.api.mapUrl}/organisation/teams/${id}`);
  }

  fetchCategories() {
    return this.http.get<TeamCategory[]>(`${this.config.api.mapUrl}/organisation/categories`).pipe(deserialise());
  }

  createCategory(name: string) {
    return this.http.post<TeamCategory>(`${this.config.api.mapUrl}/organisation/categories`, { name }).pipe(deserialise());
  }

  updateCategory(id: string, name: string) {
    return this.http.patch<void>(`${this.config.api.mapUrl}/organisation/categories/${id}`, { name });
  }

  removeCategory(id: string) {
    return this.http.delete<void>(`${this.config.api.mapUrl}/organisation/categories/${id}`);
  }

  validateCategory(name: string, id?: string) {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/categories/validate`, { name, id });
  }

  createIntegrationApiKey() {
    return this.http.post<ApiKey>(`${this.config.api.mapUrl}/organisation/integration-api`, {}).pipe(deserialise());
  }

  showIntegrationApiKey() {
    return this.http.get<ApiKey>(`${this.config.api.mapUrl}/organisation/integration-api`).pipe(deserialise());
  }

  rotateIntegrationApiKey() {
    return this.http.post<ApiKey>(`${this.config.api.mapUrl}/organisation/integration-api/rotate`, {}).pipe(deserialise());
  }

  deleteIntegrationApiKey() {
    return this.http.delete<ApiKey>(`${this.config.api.mapUrl}/organisation/integration-api`).pipe(deserialise());
  }

  exportOrganisations() {
    return this.http.get(`${this.config.api.mapUrl}/organisations/export`, { responseType: 'blob' });
  }

  fetchOrganisationById(organisationId: string) {
    return this.http.get<Organisation>(`${this.config.api.mapUrl}/organisations/${organisationId}`).pipe(deserialise());
  }

  fetchDiscountById(organisationId: string) {
    return this.http.get<Discount>(`${this.config.api.mapUrl}/organisations/${organisationId}/discounts`).pipe(deserialise());
  }

  updateBillingExceptions(organisationId: string, addons: string[]) {
    return this.http.patch(`${this.config.api.mapUrl}/organisations/${organisationId}/billing-exclusions`, { offlineBilling: { addons } });
  }

  updateOrganisationDiscount(id: string, discount: number) {
    return this.http
      .patch<Discount>(`${this.config.api.mapUrl}/organisations/${id}/discounts`, {
        discount,
      })
      .pipe(deserialise());
  }

  fetchOrganisationMembers(organisationId: string, parameters: ListQueryParams): Observable<Member[]> {
    const params = applyParams(parameters);
    return this.http.get<Member[]>(`${this.config.api.mapUrl}/organisations/${organisationId}/members`, { params }).pipe(deserialise());
  }

  updateOrganisationOwner(organisationId: string, newOwner: string) {
    return this.http
      .patch<Organisation>(`${this.config.api.mapUrl}/organisations/${organisationId}/owner`, { newOwner })
      .pipe(deserialise());
  }

  updateOrganisationNatureReporting(organisationId: string, natureReporting: NatureReporting) {
    return this.http.patch<{ natureReporting: NatureReporting }>(
      `${this.config.api.mapUrl}/organisations/${organisationId}/nature-reporting`,
      natureReporting
    );
  }

  changePsgaLicence(organisationId: string, licence: string) {
    return this.http
      .patch<Organisation>(`${this.config.api.mapUrl}/organisations/${organisationId}`, { psgaLicenceNumber: licence })
      .pipe(deserialise());
  }
}
