<div class="content">
  <h2 class="title">Please check your email to activate your account</h2>
  <p>Thank you for signing up to Land App!</p>
  <p>For security, we have sent you an email with instructions to activate your account.</p>
  <p>Once your account is active, we will send you tutorial guides and videos to help get you started.</p>

  <button type="submit" mat-raised-button color="primary" (click)="resend()" [disabled]="emailSent">
    {{ emailSent ? 'Activation email has been resent' : 'Re-send account activation email' }}
  </button>
</div>
