import { ApexNonAxisChartSeries } from 'ng-apexcharts';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-donut-chart-legend',
  templateUrl: './donut-chart-legend.component.html',
  styleUrl: './donut-chart-legend.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartLegendComponent {
  @Input() series: ApexNonAxisChartSeries = [];
  @Input() labels: string[] = [];
  @Input() colors: string[] = [];
  @Input() description?: string;

  getValue(text: string) {
    const index = this.labels?.indexOf(text);
    return this.getPercentage(this.series[index]);
  }

  private getPercentage(value: number) {
    const sum = this.series?.reduce((sum, current) => sum + current, 0);
    const result = (sum > 0 ? value / sum : 0) * 100;
    return (Number.isInteger(result) ? result : result.toFixed(2)) + '%';
  }
}
