import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { initActions } from '@core/store';
import * as landPickerActions from '../actions/land-picker.actions';
import * as leftHandPanelActions from '../actions/left-hand-panel.actions';
import { leaveMap } from '../actions/map-page.actions';

export const featureKey = 'landPicker';

export interface LandPickerState {
  enabled: boolean;
  multi: boolean;
  from?: string;
  features?: GeoJSON.Feature[];
}

export const initialState: LandPickerState = {
  enabled: false,
  multi: true,
};

export const reducer = createReducer<LandPickerState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, () => cloneDeep(initialState)),

  on(landPickerActions.startLandPicker, (state, { from }) => ({
    ...state,
    enabled: true,
    from,
  })),
  on(landPickerActions.stopLandPicker, (state) => ({
    ...state,
    enabled: false,
  })),

  on(landPickerActions.toggleMultiPick, (state, { multi }) => ({
    ...state,
    multi,
  })),

  on(landPickerActions.addFeatures, (state, { features }) => ({
    ...state,
    features: state.features ? [...state.features, ...features] : [...features],
  })),
  on(landPickerActions.removeFeatures, (state, { filterBy }) => ({
    ...state,
    features: state.features?.filter(({ id, properties }) => {
      if (Array.isArray(filterBy)) {
        return !filterBy.includes(id);
      }

      const key = Object.keys(filterBy)[0];
      return !properties || properties[key] !== filterBy[key];
    }),
  })),

  on(leftHandPanelActions.openCreatePlanStepperDialog, leftHandPanelActions.cancelPlanCreation, () => ({ ...initialState }))
);
