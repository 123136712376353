import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard, AuthAuthorizedGuard, ForceUserToSetOrganisationTypeGuard, NatureReportingGuard } from '@core/store';
import { ExpiredInvitationPageErrorComponent, PageNotFoundComponent } from '@shared/errors';
import { FarmerNatureReportingGuard } from './modules/map/guards/farmer-nature-reporting.guard';

const routes: Routes = [
  { path: '', redirectTo: '/maps', pathMatch: 'full' },
  {
    path: 'data-purchases',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/data-purchases/data-purchases.module').then((m) => m.DataPurchasesModule),
  },
  {
    path: 'account',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/account/account.module').then((m) => m.OrganizationModule),
  },
  {
    path: 'maps',
    canActivate: [AuthAuthorizedGuard, ForceUserToSetOrganisationTypeGuard],
    loadChildren: () => import('./modules/maps/maps.module').then((m) => m.MapsModule),
  },
  {
    path: 'map',
    canActivate: [AuthAuthorizedGuard, ForceUserToSetOrganisationTypeGuard],
    loadChildren: () => import('./modules/map/estate-map.module').then((m) => m.EstateMapModule),
  },
  {
    path: 'map/:mapId/nature-reporting',
    canActivate: [AuthAuthorizedGuard, FarmerNatureReportingGuard],
    loadChildren: () =>
      import('./modules/farmer-nature-reporting/farmer-nature-reporting.module').then((m) => m.FarmerNatureReportingModule),
  },
  {
    path: 'prints',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/prints/prints.module').then((m) => m.PrintsModule),
  },
  {
    path: 'print-customization',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/print/print.module').then((m) => m.PrintModule),
  },
  {
    path: 'referrals',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/referrals/referrals.module').then((m) => m.ReferralsModule),
  },
  {
    path: 'reports',
    canActivate: [AuthAuthorizedGuard],
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'admin',
    canActivate: [AuthAuthorizedGuard, AdminGuard],
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'nature-reporting',
    canActivate: [AuthAuthorizedGuard, NatureReportingGuard],
    loadChildren: () => import('./modules/nature-reporting/nature-reporting.module').then((m) => m.NatureReportingModule),
  },
  {
    path: 'organisation/invitations',
    component: ExpiredInvitationPageErrorComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
