import { createAction, props } from '@ngrx/store';

export const renamePlan = createAction('[Map/Dialog] Rename Plan', props<{ planId: string; name: string }>());
export const cancelRenamePlan = createAction('[Map/Dialog] Cancel Rename Plan', props<{ planId: string }>());

export const renameParcel = createAction(
  '[Map/Dialog] Rename Parcel',
  props<{ planId: string; featuresIds: (string | number)[]; properties: GeoJSON.GeoJsonProperties }>()
);

export const copyFeaturesToPlan = createAction(
  '[Map/Copy/Dialog] Copy Features To Plan',
  props<{ planId: string; additionalData: string }>()
);

export const createPlanWithFeatures = createAction(
  '[Map/Copy/Dialog] Create Plan With Features',
  props<{ name: string; subtype: string }>()
);

export const cancelRenameParcel = createAction('[Map/Dialog] Cancel Rename Parcel');

export const cancelExportPlan = createAction('[Map/Dialog] Cancel Export Plan', props<{ planId: string }>());

export const cancelExportPlans = createAction('[Map/Dialog] Cancel Export Plans');

export const cancelCopyFeaturesToPlan = createAction('[Map/Dialog] Cancel Copy Features To Plan');

export const cleanCreatePlanSummary = createAction('[Map/Dialog] Clean Create Plan Summary');

export const closeLinkPhotoDialog = createAction('[Map/Dialog] Close Link Photo Dialog');

export const fetchPlanDetails = createAction('[Map/Dialog] Fetch Plan Details', props<{ planId: string }>());

export const updatePlanStatus = createAction('[Map/Dialog] Update Plan Status', props<{ planId: string; planStatus: string }>());
export const updateVerificationStatus = createAction(
  '[Map/Dialog] Update Verification Status',
  props<{ planId: string; verificationStatus: string }>()
);

export const openedDeletePlanDialog = createAction('[Map/Dialog] Open Delete Plan Dialog');
export const getDeletePrerequisites = createAction(
  '[Map/Dialog] Fetch Delete Prerequisites',
  props<{ planId: string; isDeletedFromArchived: boolean }>()
);
export const archivePlan = createAction('[Map/Dialog] Archive Plan', props<{ id: string }>());
export const closedDeletePlanDialog = createAction('[Map/Dialog] Close Delete Plan Dialog');

export const openedArchivePlanDialog = createAction('[Map/Dialog] Open Archive Plan Dialog', props<{ planId: string }>());
export const getArchivePrerequisites = createAction('[Map/Dialog] Fetch Archive Prerequisites', props<{ planId: string }>());
export const deletePlan = createAction('[Map/Dialog] Delete Plan', props<{ planId: string }>());
export const closedArchivePlanDialog = createAction('[Map/Dialog] Close Archive Plan Dialog');
