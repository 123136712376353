import { createAction } from '@ngrx/store';

import { Map } from '@core/api';
import { apiActionFactory } from '@core/store';

export const [initMapSuccess, initMapFail] = apiActionFactory<{ estate: Map; readonly: boolean }>('[Map/API] Init Map');

export const initMapComplete = createAction('[Map/API] Init Map Complete');

export const [switchBaseLayerSuccess, switchBaseLayerFail] = apiActionFactory<{ selectedBasemap: string }>('[Map/API] Switch Base Layer');
