import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'laNonBreakingHyphen',
})
export class NonBreakingHyphenPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/(?<=\S)-(?=\S)/, '\u2011');
  }
}
