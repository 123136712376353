import OsGridRef from 'geodesy/osgridref';

import { SearchResult } from '@core/api';

export function parseOSGridRef(phrase: string): SearchResult {
  try {
    const { easting, northing }: { easting: number; northing: number } = OsGridRef.parse(phrase);
    const extent: [number, number, number, number] = [easting - 10, northing - 10, easting + 10, northing + 10];

    return {
      title: phrase,
      extent,
      projection: 'EPSG:27700',
    };
  } catch (error) {}

  return undefined;
}
