import { createAction, props } from '@ngrx/store';

export const startLandPicker = createAction('[Map/Picker] Start Land Picker', props<{ from: string }>());

export const stopLandPicker = createAction('[Map/Picker] Stop Land Picker');

export const toggleMultiPick = createAction('[Map/Picker] Toggle Multi Pick', props<{ multi: boolean }>());

export const addFeatures = createAction('[Map/Picker] Add Picked Features', props<{ features: GeoJSON.Feature[] }>());

export const removeFeatures = createAction('[Map/Picker] Remove Picked Features', props<{ filterBy: { [key: string]: any } }>());
