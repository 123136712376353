import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() url?: string;
  @Input() alt: string = '';
  @Input() height?: string;
  @Input() width?: string;
}
