import { Action, combineReducers } from '@ngrx/store';

import { AppState } from '@core/store';
import * as fromActiveCollaborators from './active-collaborators.reducer';
import * as fromArchivedPlans from './archived-plans.reducer';
import * as fromAttributesPicker from './attributes-picker.reducer';
import * as fromCreatePlan from './create-plan.reducer';
import * as fromEstate from './estate.reducer';
import * as fromLandPicker from './land-picker.reducer';
import * as fromLandRegistryOwnership from './land-registry-ownership.reducer';
import * as fromLimits from './limits.reducer';
import * as fromPhotos from './photos.reducer';
import * as fromPlanDetails from './plan-details.reducer';
import * as fromPlans from './plans.reducer';
import * as fromPrintSetup from './print-setup.reducer';
import * as fromSearch from './search.reducer';
import * as fromSettings from './settings.reducer';
import * as fromToolbox from './toolbox.reducer';
import * as fromWorkflow from './workflow.reducer';

export const featureKey = 'uiMap';

export interface MainState {
  [fromEstate.featureKey]: fromEstate.EstateState;
  [fromPlans.featureKey]: fromPlans.PlansState;
  [fromPlanDetails.featureKey]: fromPlanDetails.PlanDetailsState;
  [fromPhotos.featureKey]: fromPhotos.PhotosState;
  [fromArchivedPlans.featureKey]: fromArchivedPlans.ArchivedPlansState;
  [fromSettings.featureKey]: fromSettings.SettingsState;
  [fromLandPicker.featureKey]: fromLandPicker.LandPickerState;
  [fromLandRegistryOwnership.featureKey]: fromLandRegistryOwnership.LandRegistryOwnershipState;
  [fromAttributesPicker.featureKey]: fromAttributesPicker.AttributesPickerState;
  [fromCreatePlan.featureKey]: fromCreatePlan.CreatePlanState;
  [fromSearch.featureKey]: fromSearch.SearchState;
  [fromPrintSetup.featureKey]: fromPrintSetup.PrintSetupState;
  [fromWorkflow.featureKey]: fromWorkflow.BuyDataState;
  [fromToolbox.featureKey]: fromToolbox.ToolboxState;
  [fromActiveCollaborators.featureKey]: fromActiveCollaborators.ActiveCollaboratorsState;
  [fromLimits.featureKey]: fromLimits.LimitsState;
}

export interface State extends AppState {
  [featureKey]: MainState;
}

export function reducers(state: MainState | undefined, action: Action) {
  return combineReducers({
    [fromEstate.featureKey]: fromEstate.reducer,
    [fromPlans.featureKey]: fromPlans.reducer,
    [fromPlanDetails.featureKey]: fromPlanDetails.reducer,
    [fromPhotos.featureKey]: fromPhotos.reducer,
    [fromArchivedPlans.featureKey]: fromArchivedPlans.reducer,
    [fromSettings.featureKey]: fromSettings.reducer,
    [fromLandPicker.featureKey]: fromLandPicker.reducer,
    [fromLandRegistryOwnership.featureKey]: fromLandRegistryOwnership.reducer,
    [fromAttributesPicker.featureKey]: fromAttributesPicker.reducer,
    [fromCreatePlan.featureKey]: fromCreatePlan.reducer,
    [fromSearch.featureKey]: fromSearch.reducer,
    [fromPrintSetup.featureKey]: fromPrintSetup.reducer,
    [fromWorkflow.featureKey]: fromWorkflow.reducer,
    [fromToolbox.featureKey]: fromToolbox.reducer,
    [fromActiveCollaborators.featureKey]: fromActiveCollaborators.reducer,
    [fromLimits.featureKey]: fromLimits.reducer,
  })(state, action);
}
