import { createAction, props } from '@ngrx/store';

import { PrintExtent } from '@shared/open-layers';
import { Pick, Selection } from '../../models/interactions.model';

export const renderComplete = createAction('[Map/Container] Render Complete');

export const droppedPlanFiles = createAction(
  '[Map/Container] Dropped Plan Files',
  props<{ mode: 'CHOOSE_TEMPLATE' | 'IMPORT_DATA'; files?: FileList }>()
);

export const selectOnMap = createAction('[Map/Container] Select On Map', props<Selection>());

export const drawOnMap = createAction('[Map/Container] Draw On Map', props<{ layerId: string; feature: GeoJSON.Feature }>());

export const modifyOnMap = createAction('[Map/Container] Modify On Map', props<{ layerId: string; features: GeoJSON.Feature[] }>());

export const moveOnMap = createAction('[Map/Container] Move On Map', props<{ layerId: string; features: GeoJSON.Feature[] }>());

export const rotateOnMap = createAction('[Map/Container] Rotate On Map', props<{ layerId: string; features: GeoJSON.Feature[] }>());

export const updateInteractiveMapVisibility = createAction(
  '[Map/Container] Update Interactive Map Visibility',
  props<{ isVisible: boolean; zoom?: number }>()
);

export const updatePaymentRequired = createAction('[Map/Container] Update Payment Required', props<{ paymentRequired: boolean }>());

/**
 * Picker Actions
 * ----------------------------------------------
 */

export const pickFeatureOnMap = createAction('[Map/Container] Pick Feature On Map', props<Pick>());

export const pickAttributesOnMap = createAction('[Map/Container] Pick Attribute On Map', props<Pick>());

/**
 * Geometry Actions
 * ----------------------------------------------
 */

export const mergeOnMap = createAction('[Map/Container] Merge On Map');

export const cancelMerge = createAction('[Map/Container] Cancel Merge');

export const splitOnMap = createAction('[Map/Container] Split On Map', props<{ line: GeoJSON.Feature }>());

/**
 * Contextual Menu Actions
 * ----------------------------------------------
 */
export const menuStartSplit = createAction('[Map/Container/Menu] Start Split');

export const menuStartMerge = createAction('[Map/Container/Menu] Start Merge');

export const menuMergeImmediately = createAction('[Map/Container/Menu] Merge Immediately');

export const menuSelectType = createAction('[Map/Container/Menu] Select Type');

export const menuCopyFeatures = createAction('[Map/Container/Menu] Copy Features');

export const menuDeleteFeatures = createAction('[Map/Container/Menu] Delete Features');

/**
 * Measure Actions
 * ----------------------------------------------
 */

export const updateMeasureUnits = createAction('[Map/Container] Update Measure Units', props<{ area: number; perimeter: number }>());

export const startDrawingMeasure = createAction('[Map/Container] Start Drawing Measure');

export const stopDrawingMeasure = createAction('[Map/Container] Stop Drawing Measure');

export const stopMeasure = createAction('[Map/Container] Stop Measure');

export const resetMeasure = createAction('[Map/Container] Reset Measure');

/**
 * Red Ractangle BBOX Actions
 * ----------------------------------------------
 */
export const setWorkflowExtent = createAction('[Map/Container] Set Workflow Extent', props<{ bbox: [number, number, number, number] }>());

export const setPrintExtent = createAction('[Map/Container] Set Print Extent', props<PrintExtent>());

/**
 * Zoom Indicator Actions
 * ----------------------------------------------
 */

export const openTopUpDialogFromFooter = createAction('[Map/Container/Footer] Open Top Up Dialog');

export const openTopUpDialogFromSnackbar = createAction('[Map/Container/Snackbar] Open Top Up Dialog');
