import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';

import { UserApiService, catchAppError } from '@core/api';
import { userApiActions, userSelectors } from '@core/store';
import { updateUser } from '../actions/user-setup.actions';

@Injectable()
export class UserSetupEffects {
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      concatLatestFrom(() => this.store.select(userSelectors.getUser)),
      switchMap(([{ sector, organisationType }, user]) =>
        this.userApi.updateLoggedUser({ sector, organisationType }).pipe(
          map(() => userApiActions.updateUserSuccess({ user: { ...user, sector, organisationType } })),
          tap(() => this.router.navigate(['/maps'])),
          catchAppError((error) => userApiActions.updateUserFail({ error }))
        )
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private userApi: UserApiService
  ) {}
}
