import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { pick } from 'lodash';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

import { getPatternImage, PATTERN_NAMES, StyleConfig } from '@shared/open-layers';

@Component({
  selector: 'la-feature-styles',
  templateUrl: './feature-styles.component.html',
  styleUrls: ['./feature-styles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureStylesComponent implements OnInit {
  @Input() styles: StyleConfig;
  @Input() styleType: 'polygon' | 'line' | 'point' | 'text' | 'arrow';
  @Input() isFromLegend: boolean = false;
  @Output() featureStyleUpdatedEvent = new EventEmitter<StyleConfig>();
  @Output() styleDiff = new EventEmitter<Partial<StyleConfig>>();
  featureStylesForm: UntypedFormGroup;

  public PATTERNS: string[][] = PATTERN_NAMES;

  public patternConfig = {
    fillColor: 'white',
    fillOpacity: 100,
    patternColor: 'black',
    patternOpacity: 100,
  };

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.featureStylesForm = new UntypedFormGroup({
      fillColour: new UntypedFormControl(this.styles.fillColour),
      fillOpacity: new UntypedFormControl(this.styles.fillOpacity ?? 100),
      fillPattern: new UntypedFormControl(this.styles.fillPattern),
      fillPatternOpacity: new UntypedFormControl(this.styles.fillPatternOpacity ?? 100),
      fillPatternColour: new UntypedFormControl(this.styles.fillPatternColour),
      strokeColour: new UntypedFormControl(this.styles.strokeColour),
      strokeOpacity: new UntypedFormControl(this.styles.strokeOpacity ?? 100),
      strokeWidth: new UntypedFormControl(this.styles.strokeWidth),
      strokeDash: new UntypedFormControl(this.styles.strokeDash ?? []),
      imageRadius: new UntypedFormControl(this.styles.imageRadius ?? 5),
      imageFillOpacity: new UntypedFormControl(this.styles.imageFillOpacity ?? 100),
      imageFillColour: new UntypedFormControl(this.styles.imageFillColour),
      imageStrokeOpacity: new UntypedFormControl(this.styles.imageStrokeOpacity ?? 100),
      imageStrokeColour: new UntypedFormControl(this.styles.imageStrokeColour),
      imageStrokeWidth: new UntypedFormControl(this.styles.imageStrokeWidth ?? 2),
      textRotation: new UntypedFormControl(this.styles.textRotation ?? 0),
      textShadowOpacity: new UntypedFormControl(this.styles.textShadowOpacity),
      textMaxResolution: new UntypedFormControl(this.styles.textMaxResolution),
      textSize: new UntypedFormControl(this.styles.textSize),
      textFillColour: new UntypedFormControl(this.styles.textFillColour),
      textFillOpacity: new UntypedFormControl(this.styles.textFillOpacity),
      textShadowColour: new UntypedFormControl(this.styles.textShadowColour),
      textShadowWidth: new UntypedFormControl(this.styles.textShadowWidth),
      textMinResolution: new UntypedFormControl(this.styles.textMinResolution),
    });

    this.featureStylesForm.valueChanges.subscribe(() => {
      this.featureStyleUpdatedEvent.emit(this.cleanup(this.featureStylesForm.value));
    });

    this.featureStylesForm.valueChanges
      .pipe(
        startWith(this.featureStylesForm.value),
        pairwise(),
        map(([oldState, newState]) => {
          let changes = {};
          for (const key in newState) {
            if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
              changes[key] = newState[key];
            }
          }
          return changes;
        }),
        filter((changes) => Object.keys(changes).length !== 0 && !this.featureStylesForm.invalid)
      )
      .subscribe((params) => {
        this.styleDiff.emit(params);
      });
  }

  public onFillPatternChanged(pattern: string) {
    this.featureStylesForm.controls.fillPattern.setValue(pattern);
  }

  public onNoPatternClicked() {
    this.featureStylesForm.controls.fillPattern.setValue(null);
  }

  public getPattern(patternName: string) {
    const backgroundImageUrl = `url(${getPatternImage(patternName, this.patternConfig)})`;
    return this.sanitizer.bypassSecurityTrustStyle(backgroundImageUrl);
  }

  private cleanup(styles: StyleConfig) {
    switch (this.styleType) {
      case 'polygon':
        return pick(styles, [
          'fillColour',
          'fillOpacity',
          'fillPattern',
          'fillPatternOpacity',
          'fillPatternColour',
          'strokeColour',
          'strokeOpacity',
          'strokeWidth',
          'strokeDash',
        ]);
      case 'line':
      case 'arrow':
        return pick(styles, ['strokeColour', 'strokeOpacity', 'strokeWidth', 'strokeDash']);
      case 'point':
        return pick(styles, [
          'imageRadius',
          'imageFillOpacity',
          'imageFillColour',
          'imageStrokeOpacity',
          'imageStrokeColour',
          'imageStrokeWidth',
        ]);
      case 'text':
        return pick(styles, [
          'textRotation',
          'textShadowOpacity',
          'textMaxResolution',
          'textSize',
          'textFillColour',
          'textFillOpacity',
          'textShadowColour',
          'textShadowWidth',
          'textMinResolution',
        ]);
    }
  }
}
