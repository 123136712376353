import { AnalyticsEventsEffects } from './effects/analytics-events.effects';
import { JoinOrganisationEffects } from './effects/join-organisation.effects';
import { RegisterEffects } from './effects/register.effects';
import { UserSetupEffects } from './effects/user-setup.effects';

export * as joinOrganisationActions from './actions/join-organisation.actions';
export * as registerActions from './actions/register.actions';
export * as userSetupActions from './actions/user-setup.actions';
export { featureKey, reducers } from './reducers/main.reducer';
export * as joinOrganisationSelectors from './selectors/join-organisation.selectors';
export * as loginSelectors from './selectors/login.selectors';
export * as registerSelectors from './selectors/register.selectors';
export * as userSetupSelectors from './selectors/user-setup.selectors';

export const effects = [AnalyticsEventsEffects, RegisterEffects, UserSetupEffects, JoinOrganisationEffects];
