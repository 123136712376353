import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreCommonModule } from '@core/common';
import { APP_CONFIG } from '@core/config';
import { CoreStoreModule } from '@core/store';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, AuthModule, AppRoutingModule, CoreStoreModule, CoreCommonModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: APP_CONFIG, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
