import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromActiveCollaborators from '../reducers/active-collaborators.reducer';
import * as fromArchivedPlans from '../reducers/archived-plans.reducer';
import * as fromAttributesPicker from '../reducers/attributes-picker.reducer';
import * as fromCreatePlan from '../reducers/create-plan.reducer';
import * as fromEstate from '../reducers/estate.reducer';
import * as fromLandPicker from '../reducers/land-picker.reducer';
import * as fromLandRegistryOwnership from '../reducers/land-registry-ownership.reducer';
import * as fromLimits from '../reducers/limits.reducer';
import * as fromMain from '../reducers/main.reducer';
import * as fromPhotos from '../reducers/photos.reducer';
import * as fromPlanDetails from '../reducers/plan-details.reducer';
import * as fromPlans from '../reducers/plans.reducer';
import * as fromPrintSetup from '../reducers/print-setup.reducer';
import * as fromSearch from '../reducers/search.reducer';
import * as fromSettings from '../reducers/settings.reducer';
import * as fromToolbox from '../reducers/toolbox.reducer';
import * as fromBuyData from '../reducers/workflow.reducer';

export const getUIMapState = createFeatureSelector<fromMain.MainState>(fromMain.featureKey);

export const getBuyDataState = createSelector(getUIMapState, (state) => state[fromBuyData.featureKey]);

export const getArchivedPlansState = createSelector(getUIMapState, (state) => state[fromArchivedPlans.featureKey]);

export const getEstateState = createSelector(getUIMapState, (state) => state[fromEstate.featureKey]);

export const getLandPickerState = createSelector(getUIMapState, (state) => state[fromLandPicker.featureKey]);

export const getLandRegistryOwnershipState = createSelector(getUIMapState, (state) => state[fromLandRegistryOwnership.featureKey]);

export const getPhotosState = createSelector(getUIMapState, (state) => state[fromPhotos.featureKey]);

export const getPlansState = createSelector(getUIMapState, (state) => state[fromPlans.featureKey]);

export const getPlanDetailsState = createSelector(getUIMapState, (state) => state[fromPlanDetails.featureKey]);

export const getSettingsState = createSelector(getUIMapState, (state) => state[fromSettings.featureKey]);

export const getAttributesPickerState = createSelector(getUIMapState, (state) => state[fromAttributesPicker.featureKey]);

export const getCreatePlanState = createSelector(getUIMapState, (state) => state[fromCreatePlan.featureKey]);

export const getSearchState = createSelector(getUIMapState, (state) => state[fromSearch.featureKey]);

export const getPrintSetupState = createSelector(getUIMapState, (state) => state[fromPrintSetup.featureKey]);

export const getToolboxState = createSelector(getUIMapState, (state) => state[fromToolbox.featureKey]);

export const getActiveCollaboratorsState = createSelector(getUIMapState, (state) => state[fromActiveCollaborators.featureKey]);

export const getLimitsState = createSelector(getUIMapState, (state) => state[fromLimits.featureKey]);
