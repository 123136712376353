import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ActionUIState, initActions, userApiActions } from '@core/store';
import { updateUser } from '../actions/user-setup.actions';

export const featureKey = 'userSetup';

export type UserSetupUIState = ActionUIState;

export const initialState: UserSetupUIState = {
  status: 'INIT',
};

export const reducer = createReducer<UserSetupUIState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),

  on(updateUser, (state) => ({ status: 'LOADING' })),
  on(userApiActions.updateUserSuccess, (state) => ({ status: 'READY' })),
  on(userApiActions.updateUserFail, (state, { error }) => ({ status: 'ERROR', error }))
);
