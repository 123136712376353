import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ActionUIState, initActions } from '@core/store';
import { register, registerFail, registerSuccess } from '../actions/register.actions';

export const featureKey = 'register';

export type RegisterUIState = ActionUIState;

export const initialState: RegisterUIState = {
  status: 'INIT',
};

export const reducer = createReducer<RegisterUIState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),

  on(register, (state) => ({ status: 'LOADING' })),
  on(registerSuccess, (state) => ({ status: 'READY' })),
  on(registerFail, (state, { error }) => ({ status: 'ERROR', error }))
);
