import { InjectionToken } from '@angular/core';

export interface AppConfig {
  version: string;
  api: {
    clientToken: string;
    authUrl: string;
    mapUrl: string;
    mediaUrl: string;
    geometryUrl: string;
    layerUrl: string;
    debounce: number;
  };
  socket: {
    enabled: boolean;
    url?: string;
  };
  intercom: {
    id: string;
    delay: number;
  };
  chargebee: {
    site: string;
    delay: number;
  };
  gtag: {
    enabled: boolean;
    key: string;
    category: string;
    debug: boolean;
  };
  natureReporting: {
    landManager: boolean;
  };
  amplitude: {
    apiKey: string;
  };
  print: {
    dpi: number;
  };
  layers: {
    bingKey: string;
  };
  limits: {
    maps: number;
    teamTags: number;
  };
  linkedIn?: {
    partnerId?: string;
    conversionId?: string;
  };
}

export const APP_CONFIG = new InjectionToken('Application Config');
