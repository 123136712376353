import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Organisation, Sector, User } from '@core/api';
import { APP_CONFIG, AppConfig } from '@core/config';

interface IntercomParams {
  name: string;
  data: any[];
}

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private booted = false;

  private intercom$: ReplaySubject<void> = new ReplaySubject(1);
  private request$: Subject<IntercomParams> = new Subject();

  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig) {
    const { id, delay = 200 } = this.config.intercom;

    if (id) {
      this.install(id, delay).subscribe(() => this.intercom$.next());
    }

    this.request$.pipe(switchMap((params) => this.intercom$.pipe(map(() => params)))).subscribe(({ name, data }) => {
      (window as any).Intercom(name, ...data);
    });
  }

  public boot(user: User, organization: Organisation | undefined, sectors: Sector[]) {
    if (!this.booted) {
      this.booted = true;
      this.request$.next({ name: 'boot', data: [this.getIntercomUser(user, organization, sectors)] });
    }
  }

  public trackEvent(eventName: string, metadata?: any) {
    this.request$.next({ name: 'trackEvent', data: [eventName, metadata] });
  }

  public shutdown() {
    if (this.booted) {
      this.booted = false;
      this.request$.next({ name: 'shutdown', data: [] });
    }
  }

  public show() {
    if (this.booted) {
      this.request$.next({ name: 'show', data: [] });
    }
  }

  public showNewMessage(message?: string) {
    if (this.booted) {
      this.request$.next({ name: 'showNewMessage', data: [message] });
    }
  }

  private getIntercomUser(user: User, organization: Organisation | undefined, sectors: Sector[]) {
    const company = organization
      ? { company: { name: organization.name, id: organization.id, remote_created_at: organization.createdAt } }
      : undefined;
    const sector = sectors.find((s) => s.id === user.sector);
    if (user) {
      return {
        user_id: user.id.replace('auth0|', ''),
        user_hash: user.intercomHash,
        app_id: this.config.intercom.id,
        email: user.email,
        sector: sector ? sector.name : 'Other',
        first_name: user.firstName,
        last_name: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phoneNumber,
        subscription: user.subscription,
        ...company,
      };
    } else {
      return null;
    }
  }

  private install(id: string, delay: number) {
    const installer$: Subject<void> = new Subject();

    /* tslint:disable */

    (function () {
      let w = window as any;
      w.intercomSettings = {
        horizontal_padding: 20,
        vertical_padding: 20,
      };
      let ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        let d = document;
        let i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        let l = function () {
          let s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + id;
          s.onload = () => {
            setTimeout(() => {
              installer$.next();
              installer$.complete();
            }, delay);
          };
          let x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();

    return installer$;
  }
}
