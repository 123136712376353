import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_STATES, initSelectors } from '@core/store';
import { Store } from '@ngrx/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { isReadonly } from '../store/selectors/estate.selectors';

@Injectable({ providedIn: 'root' })
export class FarmerNatureReportingGuard {
  constructor(
    public router: Router,
    private store: Store
  ) {}

  canActivate() {
    return this.store.select(initSelectors.getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      switchMap(() => this.store.select(isReadonly)),
      map((readonly) => {
        if (readonly) {
          this.router.navigate(['/']);
        }
        return true;
      })
    );
  }
}
