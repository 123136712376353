<button *ngIf="back" mat-button [routerLink]="link" (click)="closing.emit()">
  <mat-icon mat-list-icon>chevron_left</mat-icon>
  {{ backButton ? 'Back' : 'Close' }}
</button>

<button *ngIf="!back" mat-button (click)="closing.emit()">
  <mat-icon mat-list-icon>chevron_left</mat-icon>
  {{ backButton ? 'Back' : 'Close' }}
</button>

<h2>{{ title }}<ng-content></ng-content></h2>
