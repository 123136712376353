import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../models/error.model';
import { handleError } from '../utils/error.utils';

export function catchAppError(cb: (error: AppError) => any) {
  return catchError((error) => {
    const appError: AppError = error?.type === 'REQUEST' || error?.type === 'CODE' ? error : handleError(error);

    if (appError.type === 'CODE') {
      console.error(error);
    }

    return of(cb(appError));
  });
}
