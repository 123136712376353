import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';

import { TagFilterValue } from '../../models/tag-filter.model';

@Component({
  selector: 'la-new-tag-filter',
  templateUrl: './new-tag-filter.component.html',
  styleUrls: ['./new-tag-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewTagFilterComponent),
      multi: true,
    },
  ],
})
export class NewTagFilterComponent implements ControlValueAccessor {
  @Input() elements: TagFilterValue[] = [];
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() placeholder = 'Filter by teams';
  @Input() icon?: string;

  @Output() clickOnSelect = new EventEmitter<void>();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputControl = new FormControl();

  onChange: any = () => {};
  onTouched: any = () => {};

  handleSelectionChange(event: MatSelectChange) {
    this.onChange(event.value.map((element: TagFilterValue) => element.id));
  }

  remove(element: TagFilterValue): void {
    const teams = this.inputControl.value;
    const index = teams.indexOf(element);

    if (index >= 0) {
      teams.splice(index, 1);
      this.inputControl.setValue(teams);
    }
  }

  writeValue(ids: string[]): void {
    const elements = ids?.map((id) => this.elements.find((el) => el.id === id));
    this.inputControl.patchValue(elements);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
