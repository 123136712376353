import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uniq } from 'lodash';

@Component({
  selector: 'la-checkbox-multiselect',
  templateUrl: './checkbox-multiselect.component.html',
  styleUrl: './checkbox-multiselect.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultiselectComponent),
      multi: true,
    },
  ],
})
export class CheckboxMultiselectComponent {
  @Input() options: { value: string; label: string }[] = [];

  private results: string[] = [];

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string[]) {
    this.results = input;
  }

  onChecked(value: string, checked: boolean) {
    if (checked) {
      this.results = uniq([...this.results, value]);
    } else {
      this.results = this.results.filter((v) => v !== value);
    }

    this.onChange(this.results);
  }

  isChecked(value: string) {
    return this.results.includes(value);
  }
}
