import { createAction, props } from '@ngrx/store';

import { BuyDataWorkflowParameters, DataPurchase, DataWorkflow, DataWorkflowPriceDetails } from '@core/api';
import { apiActionFactory } from '@core/store';

export const fetchWorkflows = createAction('[Map/Workflow] Fetch Workflows');
export const [fetchWorkflowsSuccess, fetchWorkflowsFail] = apiActionFactory<{ workflows: DataWorkflow[] }>(
  '[Map/Workflow/API] Fetch Workflows'
);

export const selectWorkflow = createAction('[Map/Workflow] Select Workflow', props<{ workflowId: string }>());

export const setWorkflowParameters = createAction('[Map/Workflow] Set Form Parameters', props<{ parameters: BuyDataWorkflowParameters }>());
export const changedReferenceArea = createAction('[Map/Workflow] Changed Reference Area');

export const fetchPrice = createAction('[Map/Workflow] Fetch Price');
export const [fetchPriceSuccess, fetchPriceFail] = apiActionFactory<{ details: DataWorkflowPriceDetails }>(
  '[Map/Workflow/API] Fetch Price'
);
export const emptyPrice = createAction('[Map/Workflow] Empty Price');

export const submitWorkflow = createAction('[Map/Workflow] Submit Workflow');
export const openBuyWorkflowDialog = createAction(
  '[Map/Workflow] Open Data Purchase Summary Dialog',
  props<{ workflow: DataWorkflow; priceDetails: DataWorkflowPriceDetails }>()
);
export const buyWorkflow = createAction('[Map/Workflow] Buy Workflow', props<{ jobCode?: string; billable?: string }>());
export const [buyWorkflowSuccess, buyWorkflowFail] = apiActionFactory<{ mapId: string }>('[Map/Workflow/API] Buy Workflow');

export const paymentMethodUpdated = createAction('[Map/Workflow] Payment Method Updated');

export const closeWorkflowSetupPanel = createAction('[Map/Workflow] Close Workflow Setup Panel');

export const openBuyWorkflowSummaryDialog = createAction(
  '[Map/Workflow] Open Data Purchase Downloading Dialog',
  props<{ mapId: string }>()
);

export const fetchPurchases = createAction('[Map/Workflow] Fetch Purchases', props<{ acronym?: string }>());
export const searchPurchases = createAction('[Map/Workflow] Search Purchases', props<{ search?: string }>());
export const [fetchPurchasesSuccess, fetchPurchasesFail] = apiActionFactory<{ owned: DataPurchase[]; shared: DataPurchase[] }>(
  '[Map/Workflow/API] Fetch Purchases'
);
