<la-header></la-header>

<section>
  <div class="content">
    @if (loading) {
      <h1 class="title">Changing your email...</h1>
      <mat-spinner></mat-spinner>
    } @else if (!error) {
      <h1 class="title">Your email was successfully changed!</h1>
      <button type="submit" mat-raised-button color="primary" [routerLink]="'/login'">Back to login</button>
    } @else if (error.status === 410) {
      <la-error-section title="Your verification link has expired, please initiate the email change once again."></la-error-section>
    } @else if (error.status !== 410) {
      <la-error-section title="Failed to change your email" [error]="error"></la-error-section>
    }
  </div>
</section>

<la-footer></la-footer>
