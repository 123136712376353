import { Pipe, PipeTransform } from '@angular/core';
import { flattenDeep, includes } from 'lodash';

@Pipe({ name: 'laFilter' })
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter?: { key: string | string[]; query: string; not?: boolean }): any {
    if (!items || !filter || !filter.key || !filter.query) {
      return items;
    }

    const result = !filter.not;

    return items.filter((featureType) => {
      const keys = flattenDeep([filter.key]);
      const properties = keys.map((key) => featureType[key]).filter((value) => value);
      const haystacks = flattenDeep(properties).map((s) => s.toLowerCase());
      const needle = filter.query.toLowerCase();

      for (const haystack of haystacks) {
        if (includes(haystack, needle)) {
          return result;
        }
      }
      return !result;
    });
  }
}
