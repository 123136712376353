import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ActionComponent } from './components/action/action.component';
import { AvatarIconComponent } from './components/avatar-icon/avatar-icon.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { DelayButtonComponent } from './components/delay-button/delay-button.component';
import { ImageComponent } from './components/image/image.component';
import { TeamChipsComponent } from './components/team-chips/team-chips.component';

import { AutofocusDirective } from './directives/autofocus.directive';
import { DropFileDirective } from './directives/drop-file.directive';
import { ScrollableDirective } from './directives/scrollable.directive';
import { TeamChipColorDirective } from './directives/team-chip-color.directive';

import { FromNowComponent } from './components/from-now/from-now.component';
import { IconHostComponent } from './components/icon-host/icon-host.component';
import { LayerSourceComponent } from './components/layer-source/layer-source.component';
import { LineBarComponent } from './components/line-bar/line-bar.component';
import { AreaPipe } from './pipes/area.pipe';
import { ExtensionPipe, FileNamePipe } from './pipes/file.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GroupPipe } from './pipes/group.pipe';
import { IsExpiredPipe } from './pipes/is-expired.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { LimitTextDisplayPipe } from './pipes/limit-text-display.pipe';
import { NonBreakingHyphenPipe } from './pipes/non-breaking-hyphen.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { PaperSizePipe } from './pipes/paper-size.pipe';
import { PerimeterPipe } from './pipes/perimeter.pipe';
import { PluralSingularPipe } from './pipes/pluralize.pipe';
import { GrossPricePipe, GrossVatPipe, PricePipe, VatPipe } from './pipes/price.pipe';
import { SeparatorPipe } from './pipes/separator.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SubscriptionNamePipe } from './pipes/subscription-name.pipe';
import { TimespanPipe } from './pipes/timespan.pipe';

@NgModule({
  imports: [
    AngularCommonModule,
    RouterModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSliderModule,

    ClipboardModule,
  ],
  declarations: [
    TeamChipsComponent,
    TeamChipColorDirective,
    DropFileDirective,
    ActionComponent,
    AvatarIconComponent,
    ImageComponent,
    CopyToClipboardComponent,
    DelayButtonComponent,
    FromNowComponent,
    IconHostComponent,
    LayerSourceComponent,
    LineBarComponent,

    AreaPipe,
    ExtensionPipe,
    FileNamePipe,
    FilterPipe,
    GrossPricePipe,
    GrossVatPipe,
    GroupPipe,
    IsExpiredPipe,
    KeysPipe,
    LimitTextDisplayPipe,
    NonBreakingHyphenPipe,
    PaperSizePipe,
    PerimeterPipe,
    PricePipe,
    SeparatorPipe,
    SubscriptionNamePipe,
    TimespanPipe,
    VatPipe,
    PluralSingularPipe,
    OrderByPipe,
    SortPipe,

    AutofocusDirective,
    ScrollableDirective,
  ],
  exports: [
    TeamChipsComponent,
    TeamChipColorDirective,
    DropFileDirective,
    ActionComponent,
    AvatarIconComponent,
    ImageComponent,
    CopyToClipboardComponent,
    DelayButtonComponent,
    FromNowComponent,
    IconHostComponent,
    LayerSourceComponent,
    LineBarComponent,

    AreaPipe,
    ExtensionPipe,
    FileNamePipe,
    FilterPipe,
    GrossPricePipe,
    GrossVatPipe,
    GroupPipe,
    IsExpiredPipe,
    KeysPipe,
    LimitTextDisplayPipe,
    NonBreakingHyphenPipe,
    PaperSizePipe,
    PerimeterPipe,
    PricePipe,
    SeparatorPipe,
    SubscriptionNamePipe,
    TimespanPipe,
    VatPipe,
    PluralSingularPipe,
    OrderByPipe,
    SortPipe,

    AutofocusDirective,
    ScrollableDirective,
  ],
})
export class CommonModule {}
