import { createAction, props } from '@ngrx/store';

export const exportPlans = createAction('[Map/Header] Export Plans');

export const openPrintingPanel = createAction('[Map/Header] Open Printing Panel');

export const openReportsPage = createAction('[Map/Header] Open Reports Page');

export const openShareDialog = createAction('[Map/Header] Open Share Dialog');

export const startMeasure = createAction('[Map/Header] Start Measure', props<{ mode: 'distance' | 'area' }>());

export const openNatureReportingPage = createAction('[Map/Header] Open Nature Reporting Page');
