import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { configSelectors } from '@core/store';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { userSetupActions, userSetupSelectors } from '../../store';

@Component({
  selector: 'la-user-setup-page',
  templateUrl: './user-setup-page.component.html',
  styleUrls: ['./user-setup-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSetupPageComponent implements OnDestroy {
  organisationTypes$ = this.store.select(configSelectors.getOrganisationTypes);
  sectors$ = this.store.select(configSelectors.getSectors);
  loading$ = this.store.select(userSetupSelectors.getUserSetupLoading);
  error$ = this.store.select(userSetupSelectors.getUserSetupError);

  selectedType: string;
  selectedSector: string;

  private destroy$: Subject<void> = new Subject();

  constructor(private store: Store) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    this.store.dispatch(userSetupActions.updateUser({ sector: this.selectedSector, organisationType: this.selectedType }));
  }
}
