import { Pipe, PipeTransform } from '@angular/core';
import { AREA_UNITS } from '@core/api';
import { convertAreaTo } from '../services/area-converter.service';

@Pipe({ name: 'laArea' })
export class AreaPipe implements PipeTransform {
  transform(value: string | number, unit: AREA_UNITS = 'ha', decimalPlaces: number = 2): string | number {
    const parsedInput = this.parseInput(value);
    return parsedInput !== null ? convertAreaTo(parsedInput, unit, decimalPlaces) : value;
  }

  private parseInput(value: string | number): number | null {
    if (typeof value === 'number') {
      return value;
    }

    const parsedFloat = parseFloat(value);
    if (!isNaN(parsedFloat)) {
      return parsedFloat;
    }

    return null;
  }
}
