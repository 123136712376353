import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@shared/common';
import { FormFieldsModule } from '@shared/form-fields';

import { ContentWithImageComponent } from './components/content-with-image/content-with-image.component';
import { ContentWithLabelComponent } from './components/content-with-label/content-with-label.component';
import { ContextualHeaderComponent } from './components/contextual-header/contextual-header.component';
import { ContextualToolbarComponent } from './components/contextual-toolbar/contextual-toolbar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PanelHeaderComponent } from './components/panel-header/panel-header.component';
import { SectionComponent } from './components/section/section.component';

@NgModule({
  imports: [
    AngularCommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSliderModule,

    CommonModule,
    ClipboardModule,
    FormFieldsModule,
  ],
  declarations: [
    ContentWithImageComponent,
    ContentWithLabelComponent,
    ContextualHeaderComponent,
    ContextualToolbarComponent,
    LoaderComponent,
    PanelHeaderComponent,
    SectionComponent,
  ],
  exports: [
    ContentWithImageComponent,
    ContentWithLabelComponent,
    ContextualHeaderComponent,
    ContextualToolbarComponent,
    LoaderComponent,
    PanelHeaderComponent,
    SectionComponent,

    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
  ],
})
export class LayoutModule {}
