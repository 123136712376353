import { InjectionToken } from '@angular/core';

export interface AppConfig {
  version: string;
  api: {
    clientToken: string;
    authUrl: string;
    mapUrl: string;
    mediaUrl: string;
    geometryUrl: string;
    layerUrl: string;
    debounce: number;
  };
  socket: {
    enabled: boolean;
    url?: string;
  };
  intercom: {
    id: string;
    delay: number;
  };
  chargebee: {
    site: string;
    delay: number;
  };
  gtag: {
    enabled: boolean;
    key: string;
    category: string;
    debug: boolean;
  };
  natureReporting: {
    canSeeMetrics: boolean;
  };
  amplitude: {
    apiKey: string;
  };
  print: {
    dpi: number;
  };
  layers: {
    bingKey: string;
  };
  limits: {
    teamTags: number;
  };
  linkedIn?: {
    partnerId?: string;
    conversionId?: string;
  };
  tiles: {
    loadAuditTime: number;
  };
  featureFlags?: {
    [key: string]: boolean;
  };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('Application Config');
