import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { initActions } from '@core/store';
import * as layerToolbarActions from '../actions/layer-toolbar.actions';
import * as mapContainerActions from '../actions/map-container.actions';
import { leaveMap } from '../actions/map-page.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';

export const featureKey = 'settings';

export interface SettingsState {
  dataLayersOpenedCategory?: string;
  isUserNotifiedAboutPaymentRequired: boolean;

  rightHandPanel: {
    docked: boolean;
    position: { x: number; y: number };
  };
}

export const initialState: SettingsState = {
  isUserNotifiedAboutPaymentRequired: false,

  rightHandPanel: {
    docked: true,
    position: { x: 0, y: 0 },
  },
};

export const reducer = createReducer<SettingsState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),
  on(leaveMap, (state) => ({ ...cloneDeep(initialState), rightHandPanel: state.rightHandPanel })),

  on(layerToolbarActions.setDataLayersCategory, (state, { category }) => ({ ...state, dataLayersOpenedCategory: category })),

  on(rightHandPanelActions.toggleDockPanel, (state) => ({
    ...state,
    rightHandPanel: {
      ...state.rightHandPanel,
      docked: !state.rightHandPanel.docked,
    },
  })),

  on(rightHandPanelActions.movePanel, (state, { x, y }) => ({
    ...state,
    rightHandPanel: {
      ...state.rightHandPanel,
      position: { x, y },
    },
  })),

  on(mapContainerActions.updatePaymentRequired, (state, { paymentRequired }) => ({
    ...state,
    isUserNotifiedAboutPaymentRequired: paymentRequired,
  }))
);
