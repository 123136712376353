import { HttpParams } from '@angular/common/http';

export function applyParams(params: any): HttpParams {
  let httpParams = new HttpParams();

  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      return;
    }

    if (Array.isArray(params[key])) {
      params[key].forEach((param: string | number | boolean) => (httpParams = httpParams.append(key, param)));
    } else {
      httpParams = httpParams.append(key, params[key]);
    }
  });

  return httpParams;
}
