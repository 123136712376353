import { createReducer, on } from '@ngrx/store';

import { ActionUIState, initActions } from '@core/store';
import { UploadingFile } from '../../models/new-plan.models';
import * as plansApiActions from '../actions/api/plans-api.actions';
import * as dialogsActions from '../actions/dialogs.actions';
import * as estateEffectActions from '../actions/estate-effect.actions';
import * as leftHandPanelActions from '../actions/left-hand-panel.actions';
import { leaveMap } from '../actions/map-page.actions';

export const featureKey = 'createPlan';

export interface CreatePlanState extends ActionUIState {
  uploads?: UploadingFile[];
  mode?: string;
}

export const initialState: CreatePlanState = {
  status: 'INIT',
};

export const reducer = createReducer<CreatePlanState>(
  initialState,
  on(initActions.appCleanup, leaveMap, dialogsActions.cleanCreatePlanSummary, () => ({ ...initialState })),

  on(leftHandPanelActions.openCreatePlanStepperDialog, (state, { mode }) => ({
    ...state,
    mode: mode,
  })),

  on(
    leftHandPanelActions.createPlansFromFiles,
    leftHandPanelActions.createPlanFromPicker,
    leftHandPanelActions.createPlansFromRPA,
    leftHandPanelActions.createPlanFromExistingPlan,
    (state) => ({ ...state, status: 'LOADING' })
  ),

  on(leftHandPanelActions.createPlansFromFiles, (state, { name, subtype, importData }) => ({
    ...state,
    uploads:
      importData.groups.length && !state.uploads?.length
        ? importData.groups.map((group) => ({
            status: 'LOADING',
            id: group.id,
            name: group.name ? group.name : name,
            subtype: group.subtype ? group.subtype : subtype,
            progress: 0,
          }))
        : state.uploads,
  })),

  on(estateEffectActions.uploadPlan, (state, { queue }) => ({
    ...state,
    uploads:
      queue.length && !state.uploads?.length
        ? queue.map((data) => ({ status: 'LOADING', id: data.id, name: data.body.name, subtype: data.body.subtype, progress: 0 }))
        : state.uploads,
  })),
  on(estateEffectActions.uploadPlanProgress, (state, { id, progress }) => ({
    ...state,
    uploads: state.uploads.map((upload) => (upload.id === id ? { ...upload, progress } : upload)),
  })),
  on(plansApiActions.uploadPlanSuccess, (state, { id }) => {
    const uploads: UploadingFile[] = state.uploads.map((upload) => (upload.id === id ? { ...upload, status: 'READY' } : upload));

    if (uploads.every((upload) => upload.status === 'READY' || upload.status === 'ERROR')) {
      return { ...state, uploads, status: 'READY' };
    }

    return { ...state, uploads };
  }),
  on(plansApiActions.uploadPlanFail, (state, { id, error }) => {
    const uploads: UploadingFile[] = state.uploads.map((upload) => (upload.id === id ? { ...upload, status: 'ERROR', error } : upload));

    if (uploads.every((upload) => upload.status === 'READY' || upload.status === 'ERROR')) {
      return { ...state, uploads, status: 'READY' };
    }

    return { ...state, uploads };
  }),

  on(
    estateEffectActions.uploadPlansComplete,
    plansApiActions.createPlanSuccess,
    plansApiActions.createPlanDuplicateSuccess,
    plansApiActions.createPlanFromRPASuccess,
    (state) => ({
      ...state,
      status: 'READY',
    })
  ),
  on(
    plansApiActions.createPlanFail,
    plansApiActions.createPlanDuplicateFail,
    plansApiActions.createPlanFromRPAFail,
    (state, { error }) => ({ ...state, status: 'ERROR', error })
  )
);
