import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'la-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent implements OnInit, OnDestroy {
  @Input() value?: string | string[] | string[][];

  public status$ = new BehaviorSubject<boolean>(false);
  private copied$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();

  private CHANGE_AFTER = 700;

  constructor() {}

  ngOnInit(): void {
    this.copied$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.status$.next(true)),
        delay(this.CHANGE_AFTER),
        tap(() => this.status$.next(false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCopy() {
    this.copied$.next();
  }
}
