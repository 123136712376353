import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { User, UserGroup } from '@core/api';
import { APP_STATES } from '../enums/app.enum';
import { getAppStatus } from '../selectors/init.selectors';
import { getUser } from '../selectors/user.selectors';

@Injectable({ providedIn: 'root' })
export class AdminGuard {
  constructor(
    public router: Router,
    private store: Store
  ) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      switchMap(() => this.store.select(getUser)),
      map((user) => {
        if (user) {
          this.checkAdminPermissions(user);
        }
        return true;
      })
    );
  }

  private checkAdminPermissions(user: User) {
    if (!user.groups?.includes(UserGroup.ADMIN)) {
      this.router.navigate(['/']);
    }
  }
}
