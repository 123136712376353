<la-header></la-header>

<section>
  @if (form) {
  <div class="content">
    <h1 class="title">Setup organisation user</h1>
    <p>Please set your password</p>
    @if (isInvitationExpired) {
    <mat-error>
      <p>Your invitation has expired</p>
      <p>Please ask your account administrator to send a new invitation in order to join this organisation</p>
    </mat-error>
    } @if (!!serverErrorMessage){
    <mat-error>{{ serverErrorMessage }}</mat-error>

    } @if (form){
    <form [formGroup]="form">
      <input type="hidden" formControlName="token" />

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>New Password:</mat-label>
        <input
          type="password"
          placeholder="Min. 8 characters, 1 number and 1 special"
          matInput
          formControlName="password"
          required
          maxLength="64"
          [type]="hide ? 'password' : 'text'"
        />
        @if (form.controls['password'].invalid) {
        <mat-error>Please enter a valid password. Min. 8 characters including 1 special and 1 number</mat-error>
        }
        <mat-icon matSuffix (click)="toggleVisibility()">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Sector</mat-label>
        <mat-select placeholder="Sector" formControlName="sector">
          <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{ sector.name }}</mat-option>
        </mat-select>
        @if (form.controls['sector'].invalid) {<mat-error>Please choose a sector</mat-error>}
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Organisation type</mat-label>
        <mat-select placeholder="Organisation type" formControlName="organisationType">
          <mat-option *ngFor="let organisationType of organisationTypes" [value]="organisationType.id">
            {{ organisationType.name }}
          </mat-option>
        </mat-select>
        @if (form.controls['organisationType'].invalid) {<mat-error>Please choose an organisation type</mat-error>}
      </mat-form-field>

      <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || (loading$ | async) === true" (click)="submit()">
        Submit
      </button>
      @if (loading$ | async){<mat-spinner diameter="20"></mat-spinner>}
    </form>

    }
  </div>
  }
</section>

<la-footer></la-footer>
