import { createAction, props } from '@ngrx/store';

import { BaseLayer, Frame, PrintBorderMargins } from '@core/api';
import { apiActionFactory } from '@core/store';

export const fetchUserFrames = createAction('[Print Setup] Fetch User Frames');
export const [fetchUserFramesSuccess, fetchUserFramesFail] = apiActionFactory<{ frames: Frame[] }>('[Print Setup] Fetch User Frames');

export const fetchOrgFrames = createAction('[Print Setup] Fetch Org Frames');
export const [fetchOrgFramesSuccess, fetchOrgFramesFail] = apiActionFactory<{ frames: Frame[] }>('[Print Setup] Fetch Org Frames');

export const renderPaperOnMap = createAction('[Print Setup] Render Paper On Map', props<any>());

export const selectFrame = createAction('[Print Setup] Select Frame', props<{ frame: Frame; margins: PrintBorderMargins }>());

export const deselectFrame = createAction('[Print Setup] Deselect Frame');

export const searchUserFrames = createAction('[Print Setup] Search User Frames', props<{ payload: string }>());

export const searchOrgFrames = createAction('[Print Setup] Search Org Frames', props<{ payload: string }>());

export const switchBaseLayerQuiet = createAction('[Print Setup] Switch Base Layer', props<{ baseLayer: BaseLayer }>());

export const customizePrint = createAction('[Print Setup] Customize Print');

export const customizePrintFail = createAction(
  '[Print Setup] Customize Print Fail',
  props<{ errors: { planId: string; featuresIds: (string | number)[] }[] }>()
);

export const clearPrintErrors = createAction('[Print Setup] Clear Print Errors');

export const resetPrintSetup = createAction('[Print Setup] Reset');

export const openRenameFrameDialog = createAction('[Print Setup] Open Rename Frame Dialog', props<{ frame: Frame }>());
export const [renameFrameSuccess, renameFrameFail] = apiActionFactory<{ frame: Frame }>('[Print Setup] Rename Frame');

/**
 * Previous map state
 */

export const requestPreviousLocation = createAction('[Map/Print] Request Previous Location');

export const setPreviousLocation = createAction(
  '[Map/Print] Set Previous Location',
  props<{ extent: [number, number, number, number]; basemap: string }>()
);

export const restorePreviousLocation = createAction('[Map/Print] Restore Previous Location');

export const clearPreviousLocation = createAction('[Map/Print] Clear Previous Location');
