import { createEntities, deleteEntities, updateEntities, upsertArray, upsertEntity } from '@core/store';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep, dropRight, last, reduce } from 'lodash';

import { Plan } from '@core/api';
import { ActionUIState, initActions, ItemUIState } from '@core/store';
import { HistoryOperation, Operation } from '../../models/operations.model';
import * as featuresApiActions from '../actions/api/features-api.actions';
import * as photosApiActions from '../actions/api/photos-api.actions';
import * as plansApiActions from '../actions/api/plans-api.actions';
import * as dialogsActions from '../actions/dialogs.actions';
import * as leftHandPanelActions from '../actions/left-hand-panel.actions';
import * as mapPageActions from '../actions/map-page.actions';
import * as plansEffectActions from '../actions/plans-effect.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';
import { applyOperationsOnFeatures, applyOperationsOnOrder, toHistoryOperations, toReversibleOperations } from '../utils/operations.utils';
import { toGeoJSONFeature } from '../utils/plans.utils';

export const featureKey = 'plans';

export interface PlanEntity extends Omit<ItemUIState<Plan>, 'status'> {
  status: 'INIT' | 'LOADING' | 'PARTIAL' | 'READY' | 'ERROR';
  action: ActionUIState;

  history: {
    current: HistoryOperation[];
    pending: HistoryOperation[];
    undo: Operation[][];
    redo: Operation[][];

    preparing?: boolean;
  };
}

export interface PlansState {
  [id: string]: PlanEntity;
}

export const initialState: PlansState = {};

type PlansData = { id: string; plan: Plan; visible: boolean };

export const reducer = createReducer<PlansState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, mapPageActions.leaveMap, () => cloneDeep(initialState)),

  on(plansApiActions.initPlansSuccess, (state, { data }) =>
    createEntities<PlanEntity, PlansData>(data, (item) => ({
      data: item.plan,
      status: item.plan.features?.length ? (item.visible ? 'LOADING' : 'PARTIAL') : 'READY',

      action: {
        status: 'INIT',
      },

      history: {
        current: [],
        pending: [],
        undo: [],
        redo: [],
      },
    }))
  ),
  on(featuresApiActions.initFeaturesSuccess, (state, { planId, features }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      status: 'READY',
      data: {
        ...entity.data,
        geojson: {
          ...entity.data.geojson,
          features: features.map(toGeoJSONFeature),
        },
      },
    }))
  ),

  on(plansApiActions.fetchPlanSuccess, (state, { plan }) =>
    upsertEntity(state, plan.id, (entity) =>
      entity
        ? { ...entity, data: plan, status: plan.features?.length ? 'LOADING' : 'READY' }
        : {
            data: plan,
            status: plan.features?.length ? 'LOADING' : 'READY',

            action: {
              status: 'INIT',
            },

            history: {
              current: [],
              pending: [],
              undo: [],
              redo: [],
            },
          }
    )
  ),

  on(plansApiActions.refetchPlanSuccess, (state, { plan }) =>
    upsertEntity(state, plan.id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        name: plan.name,
        updatedAt: plan.updatedAt,
        updatedBy: plan.updatedBy,
        version: plan.version,
        published: plan.published,
      },
      status: 'READY',
    }))
  ),

  on(
    plansApiActions.createPlanSuccess,
    plansApiActions.createPlanDuplicateSuccess,
    plansApiActions.createPlanFromRPASuccess,
    plansApiActions.createPlanFromLandPickerSuccess,
    plansApiActions.createPlansFromFilesSuccess,
    plansApiActions.restorePlanSuccess,
    plansApiActions.createPlanWithFeaturesSuccess,
    (state, { plans }) =>
      reduce(
        plans,
        (acc, plan) => {
          return {
            ...acc,
            [plan.id]: {
              data: plan,
              status: 'READY',

              action: {
                status: 'INIT',
              },

              history: {
                current: [],
                pending: [],
                undo: [],
                redo: [],
              },
            },
          };
        },
        state
      )
  ),

  on(plansApiActions.fetchPlanFail, plansApiActions.refetchPlanFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({ ...entity, status: 'ERROR', error }))
  ),
  on(plansApiActions.fetchPlanComplete, (state, { planId }) => upsertEntity(state, planId, (entity) => ({ ...entity, status: 'READY' }))),

  on(featuresApiActions.fetchFeaturesSuccess, (state, { planId, features }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      status: 'READY',
      data: {
        ...entity.data,
        geojson: {
          ...entity.data.geojson,
          features: features.map(toGeoJSONFeature),
        },
      },
    }))
  ),
  on(featuresApiActions.fetchFeaturesFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      status: 'ERROR',
      error,
    }))
  ),

  on(featuresApiActions.fetchFeatureSuccess, (state, { planId, feature }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'READY',
      },
      data: {
        ...entity.data,
        geojson: {
          ...entity.data.geojson,
          features: upsertArray(entity.data.geojson.features, toGeoJSONFeature(feature)),
        },
      },
    }))
  ),
  on(featuresApiActions.fetchFeatureFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(plansEffectActions.addOperations, (state, { planId, operations }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        features: applyOperationsOnOrder(operations, entity.data.features),
        geojson: {
          ...entity.data.geojson,
          features:
            entity.status === 'PARTIAL'
              ? entity.data.geojson.features
              : applyOperationsOnFeatures(operations, entity.data.geojson.features),
        },
      },
      history: {
        ...entity.history,
        current: toHistoryOperations(operations, entity.history.current),
        redo: [],
        preparing: true,
      },
    }))
  ),
  on(plansEffectActions.addUndoOperations, (state, { planId, operations }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        features: applyOperationsOnOrder(operations, entity.data.features),
        geojson: {
          ...entity.data.geojson,
          features: applyOperationsOnFeatures(operations, entity.data.geojson.features),
        },
      },
      history: {
        ...entity.history,
        current: toHistoryOperations(operations, entity.history.current, true),
        undo: dropRight(entity.history.undo),
        redo: [...entity.history.redo, last(entity.history.undo)],
      },
    }))
  ),
  on(plansEffectActions.addRedoOperations, (state, { planId, operations }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        features: applyOperationsOnOrder(operations, entity.data.features),
        geojson: {
          ...entity.data.geojson,
          features: applyOperationsOnFeatures(operations, entity.data.geojson.features),
        },
      },
      history: {
        ...entity.history,
        current: toHistoryOperations(operations, entity.history.current, true),
        undo: [...entity.history.undo, last(entity.history.redo)],
        redo: dropRight(entity.history.redo),
      },
    }))
  ),
  on(plansEffectActions.saveOperations, (state) =>
    updateEntities(state, (entity) => ({
      ...entity,
      history: {
        ...entity.history,
        current: [],
        pending: entity.history.current,
        undo: entity.status === 'PARTIAL' ? entity.history.undo : toReversibleOperations(entity.history.current, entity.history.undo),
        preparing: false,
      },
      action: entity.history?.current?.length
        ? {
            status: 'LOADING',
          }
        : entity.action,
    }))
  ),
  on(featuresApiActions.saveFeaturesSuccess, (state, { planId, changes: { plan } }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        version: plan.version,
        features: plan.features,
        updatedAt: new Date().toISOString(),
      },
      history: {
        ...entity.history,
        pending: [],
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(featuresApiActions.saveFeaturesFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(leftHandPanelActions.publishPlan, leftHandPanelActions.unpublishPlan, (state, { planId }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'LOADING',
      },
    }))
  ),
  on(plansApiActions.publishPlanSuccess, (state, { id, version }) =>
    upsertEntity(state, id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        published: true,
        version,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.unpublishPlanSuccess, (state, { id, version }) =>
    upsertEntity(state, id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        published: false,
        version,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.publishPlanFail, plansApiActions.unpublishPlanFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(leftHandPanelActions.lockPlan, leftHandPanelActions.unlockPlan, (state, { planId }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'LOADING',
      },
    }))
  ),
  on(plansApiActions.lockPlanSuccess, (state, { id, version }) =>
    upsertEntity(state, id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        readonly: true,
        version,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.unlockPlanSuccess, (state, { id, version }) =>
    upsertEntity(state, id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        readonly: false,
        version,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.lockPlanFail, plansApiActions.unlockPlanFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(dialogsActions.renamePlan, (state, { planId, name }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        name,
      },
      action: {
        status: 'LOADING',
      },
    }))
  ),
  on(plansApiActions.renamePlanSuccess, (state, { layer }) =>
    upsertEntity(state, layer.id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        name: layer.name,
        version: layer.version,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.renamePlanFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(dialogsActions.updatePlanStatus, dialogsActions.updateVerificationStatus, (state, { planId }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
      },
      action: {
        status: 'LOADING',
      },
    }))
  ),
  on(plansApiActions.updatePlanSuccess, (state, { layer }) =>
    upsertEntity(state, layer.id, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        name: layer.name,
        version: layer.version,
        planStatus: layer.planStatus,
        verificationStatus: layer.verificationStatus,
      },
      action: {
        status: 'READY',
      },
    }))
  ),
  on(plansApiActions.renamePlanFail, (state, { planId, error }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(plansEffectActions.updatePlansInsideFolder, (state, { updates }) =>
    updateEntities(state, (entity) => {
      const found = updates.find((update) => update.planId === entity.data.id);
      if (found) {
        return { ...entity, data: { ...entity.data, ...found.data } };
      }
      return entity;
    })
  ),

  on(featuresApiActions.createFeaturesSuccess, (state, { plan }) =>
    upsertEntity(state, plan.id, () => ({
      data: plan,
      status: 'READY',

      action: {
        status: 'INIT',
      },

      history: {
        current: [],
        pending: [],
        undo: [],
        redo: [],
      },
    }))
  ),

  on(rightHandPanelActions.linkPhotos, (state, { planId }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'LOADING',
      },
    }))
  ),

  on(photosApiActions.linkPhotosSuccess, (state, { planId, changes: { plan } }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        version: plan.version,
        features: plan.features,
        updatedAt: new Date().toISOString(),
      },
      action: {
        status: 'READY',
      },
    }))
  ),

  on(photosApiActions.unlinkPhotosSuccess, (state, { planId, changes: { affected, plan } }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        geojson: {
          ...entity.data.geojson,
          features: entity.data.geojson.features.filter((feature) => !affected.includes(feature.id.toString())),
        },
        version: plan.version,
        features: plan.features,
        updatedAt: new Date().toISOString(),
      },
      action: {
        status: 'READY',
      },
    }))
  ),

  on(photosApiActions.linkPhotosFail, photosApiActions.unlinkPhotosFail, (state, { error, planId }) =>
    upsertEntity(state, planId, (entity) => ({
      ...entity,
      action: {
        status: 'ERROR',
        error,
      },
    }))
  ),

  on(dialogsActions.closeLinkPhotoDialog, (state) =>
    updateEntities(state, (entity) => ({
      ...entity,
      linkPhoto: {
        status: 'INIT',
      },
    }))
  ),

  on(plansApiActions.deletePlanSuccess, (state, { affected }) => deleteEntities(state, affected))
);
