import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'laNetPrice' })
export class PricePipe implements PipeTransform {
  transform(value: number): number {
    return value / 1.2;
  }
}

@Pipe({ name: 'laVat' })
export class VatPipe implements PipeTransform {
  transform(value: number): number {
    return value - value / 1.2;
  }
}

@Pipe({ name: 'laGrossVat' })
export class GrossVatPipe implements PipeTransform {
  transform(value: number): number {
    return value * 0.2;
  }
}

@Pipe({ name: 'laGrossPrice' })
export class GrossPricePipe implements PipeTransform {
  transform(value: number): number {
    return value * 1.2;
  }
}
