export enum AppErrorCodes {
  // App errors
  CODE_ERROR = 'CODE_ERROR',
  CUSTOM_ERROR = 'CUSTOM_ERROR',

  // Client errors
  NO_INTERNET = 'NO_INTERNET',

  // API errors
  DEPRECATED = 'DEPRECATED',
  NOT_ACTIVE = 'NOT_ACTIVE',
  TEMPORARILY_BLOCKED = 'TEMPORARILY_BLOCKED',
  USER_SUSPENDED = 'USER_SUSPENDED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  INVALID_GEOMETRY = 'INVALID_GEOMETRY',
  OVERLAPPING_FEATURES = 'OVERLAPPING_FEATURES',
  OVERLAPPING_PLANS = 'OVERLAPPING_PLANS',
  INVALID_SBI = 'INVALID_SBI',
  RESOURCE_LOCKED = 'RESOURCE_LOCKED',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  NO_LOCATION = 'NO_LOCATION',
  VALUE_TOO_LONG = 'VALUE_TOO_LONG',
  FILE_TOO_BIG = 'FILE_TOO_BIG',
  INVALID_API_KEY = 'INVALID_API_KEY',
  PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',
  PASSWORDS_NOT_EQUAL = 'PASSWORDS_NOT_EQUAL',
  PASSWORD_TOO_WEAK = 'PASSWORD_TOO_WEAK',
  PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED',
  INVALID_REFERRAL_CODE = 'INVALID_REFERRAL_CODE',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_METHOD_EXPIRED = 'PAYMENT_METHOD_EXPIRED',
  NO_PERMISSIONS = 'NO_PERMISSIONS',
  NOT_FOUND = 'NOT_FOUND',
  INVALID_VERSION = 'INVALID_VERSION',
  REQUEST_EXPIRED = 'REQUEST_EXPIRED',
  INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  SERVER_CONFIGURATION_ERROR = 'SERVER_CONFIGURATION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
}
