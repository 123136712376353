<section class="left">
  <a *ngIf="logoNavigates" class="logo" [routerLink]="['/maps']" routerLinkActive="active" (click)="navigate.next($event)">
    <img [src]="logoUrl" />
  </a>

  <div *ngIf="!logoNavigates" class="logo" (click)="navigate.next($event)">
    <img [src]="logoUrl" />
  </div>

  <ng-content select="[la-contextual-header-title]"></ng-content>
</section>

<section class="middle">
  <ng-content select="[la-contextual-header-middle]"></ng-content>
</section>

<section class="right">
  <ng-content select="[la-contextual-header-actions]"></ng-content>

  <button class="person" mat-icon-button *ngIf="user" [matMenuTriggerFor]="profileMenu">
    <mat-icon>person</mat-icon>
  </button>

  <mat-menu #profileMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
    <ng-content select="[la-contextual-menu-content]"></ng-content>
    <!-- <la-paywall-balance *ngIf="!organization?.osApiKey.active"></la-paywall-balance> -->
    <button mat-menu-item [routerLink]="['/referrals']" (click)="navigate.emit()">
      <mat-icon>connect_without_contact</mat-icon>
      <span>Referrals</span>
    </button>
    <button mat-menu-item [routerLink]="['/account']" (click)="navigate.emit()">
      <mat-icon>person</mat-icon>
      <span>Account</span>
    </button>
    <button mat-menu-item [routerLink]="['/maps']" (click)="navigate.emit()">
      <mat-icon>dashboard</mat-icon>
      <span>Maps</span>
    </button>
    <a mat-menu-item [routerLink]="['/prints']" target="_blank">
      <mat-icon>printer</mat-icon>
      <span>Prints</span>
    </a>
    <a mat-menu-item [routerLink]="['/data-purchases']" target="_blank">
      <mat-icon>shop</mat-icon>
      <span>Data purchases</span>
    </a>
    <a mat-menu-item [routerLink]="['/reports']" target="_blank">
      <mat-icon>analytics</mat-icon>
      <span>Portfolio</span>
    </a>
    @if (!!isNatureReportingEnabled) {
      <a mat-menu-item [routerLink]="['/nature-reporting']" target="_blank">
        <mat-icon class="material-symbols-outlined"> eco></mat-icon>
        <span>Nature Reporting</span>
      </a>
    }
    <a mat-menu-item href="https://help.thelandapp.com/" target="_blank">
      <mat-icon>help_outline</mat-icon>
      <span>Help</span>
    </a>
    <a *ngIf="isAdmin" mat-menu-item [routerLink]="['/admin']">
      <mat-icon class="material-icons-outlined">settings</mat-icon>
      <span>Admin</span>
    </a>
    <button mat-menu-item [routerLink]="['/logged-out']" (click)="storeLastLocation()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>

  <ng-content select="[la-contextual-after-person-icon]"></ng-content>
</section>
