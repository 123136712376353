import { createAction, props } from '@ngrx/store';

import { AppError, BaseLayer, FeatureDefinition } from '@core/api';
import { FeatureMode } from '../../models/interactions.model';

/**
 * Undo / Redo Actions
 * ----------------------------------------------
 */

export const undo = createAction('[Map/Toolbar] Undo');

export const redo = createAction('[Map/Toolbar] Redo');

/**
 * Geometry Tools Actions
 * ----------------------------------------------
 */

export const openDrawMenu = createAction('[Map/Toolbar] Open Draw Menu');

export const startDraw = createAction('[Map/Toolbar] Start Draw', props<{ definition: FeatureDefinition }>());

export const stopDraw = createAction('[Map/Toolbar] Stop Draw');

export const startSplit = createAction('[Map/Toolbar] Start Split');

export const startMerge = createAction('[Map/Toolbar] Start Merge');

export const startBuffer = createAction('[Map/Toolbar] Start Buffer');

export const stopBuffer = createAction('[Map/Toolbar] Stop Buffer');

export const mergeImmediately = createAction('[Map/Toolbar] Merge Immediately');

export const bufferPreview = createAction('[Map/Toolbar] Buffer Preview', props<{ features: GeoJSON.Feature[] }>());

export const bufferFail = createAction('[Map/Toolbar] Buffer Fail', props<{ error: AppError }>());

export const bufferFeatures = createAction(
  '[Map/Toolbar] Buffer Features',
  props<{
    add: GeoJSON.Feature[];
    replace: GeoJSON.Feature[];
    remove: (string | number)[];
    sideToggled: {
      buffer_side_one: boolean;
      buffer_side_two: boolean;
      buffer_rounded_corners: boolean;
      buffer_subtract: boolean;
    };
  }>()
);

/**
 * Feature Properties Actions
 * ----------------------------------------------
 */

export const duplicateFeatures = createAction('[Map/Toolbar] Duplicate Features');

export const copyFeatures = createAction('[Map/Toolbar] Copy Features');

export const cancelCopyFeatures = createAction('[Map/Toolbar] Cancel Copy Features');

/**
 * Feature Modification Actions
 * ----------------------------------------------
 */

export const moveFeaturesToFront = createAction(
  '[Map/Toolbar] Move Feature To Front',
  props<{ planId: string; featureIds: (string | number)[] }>()
);

export const moveFeaturesToBack = createAction(
  '[Map/Toolbar] Move Feature To Back',
  props<{ planId: string; featureIds: (string | number)[] }>()
);

export const deleteFeatures = createAction('[Map/Toolbar] Delete Features');

export const openDeleteLinkedPhotoWarningDialog = createAction('[Map/Toolbar] Open Delete Linked Photo Warning');

export const deletePhotos = createAction('[Map/Toolbar] Delete Photos');

/**
 * Interactions
 * ----------------------------------------------
 */

export const enableSnap = createAction('[Map/Toolbar] Enable Snap');

export const disableSnap = createAction('[Map/Toolbar] Disable Snap');

export const setFeatureMode = createAction('[Map/Toolbar] Set Feature Mode', props<{ mode: FeatureMode }>());

export const resetInteractions = createAction('[Map/Toolbar] Reset Interactions');

/**
 * Basemap and Data Layers Actions
 * ----------------------------------------------
 */

export const switchBaseLayer = createAction('[Map/Toolbar] Switch Base Layer', props<{ baseLayer: BaseLayer; zoom?: number }>());

export const showDataLayer = createAction('[Map/Toolbar] Show Data Layer', props<{ id: string; zoom?: number }>());

export const hideDataLayer = createAction('[Map/Toolbar] Hide Data Layer', props<{ id: string; zoom?: number }>());

export const hideAllDataLayers = createAction('[Map/Toolbar] Hide All Data Layers');

export const setDataLayersCategory = createAction('[Map/Toolbar] Set Data Layers Category', props<{ category: string }>());
