import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { AuthenticationService } from '../../services/authentication.service';
import { registerActions } from '../../store';

@Component({
  selector: 'la-activate-page',
  template: '',
})
export class ActivatePageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(registerActions.activatePageOpened());

    const { token } = this.route.snapshot.params;

    if (!token) {
      of(this.router.navigate(['/error']));
    }

    this.authenticationService.activate(token).subscribe(
      async () => await this.router.navigateByUrl('/login', { state: { activation: true } }),
      async () => await this.router.navigate(['/error'])
    );
  }
}
