@if (form) {
  <form [formGroup]="form" class="form">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="search">
      <input matInput formControlName="search" placeholder="Search by print name, map name or user name" (click)="searchPrints.emit()" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <la-new-tag-filter
      *ngIf="isInOrganization && showTeamFilter"
      appearance="outline"
      [elements]="autocompleteElements"
      formControlName="team"
      icon="filter_list"
      (click)="clickOnFilter.emit()"
    ></la-new-tag-filter>

    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-select disableOptionCentering formControlName="sort">
        <mat-option value="date">Most recent</mat-option>
        <mat-option value="name">Map name</mat-option>
      </mat-select>
      <mat-icon matSuffix>filter_list</mat-icon>
    </mat-form-field>
  </form>
}
