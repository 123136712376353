import { User } from '@core/api';
import { createReducer, on } from '@ngrx/store';
import { userApiActions } from '../actions/user.actions';
import { initActions } from '../actions/init.actions';

export const featureKey = 'user';

export type UserState = User | undefined;

export const initialState: UserState = undefined;

export const reducer = createReducer<UserState>(
  initialState,
  on(userApiActions.logoutSuccess, userApiActions.tokenExpired, () => initialState),

  on(initActions.appComplete, userApiActions.loginSuccess, userApiActions.fetchUserSuccess, (state, { user }) =>
    user ? { ...user } : undefined
  ),

  on(userApiActions.updateUserSuccess, (state, { user }) => (state ? { ...state, ...user } : state))
);
