import { Injectable } from '@angular/core';

import { UserApiService } from '@core/api';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private userApiService: UserApiService) {}

  /**
   * Register new user.
   */
  public register(form: any) {
    return this.userApiService.register(form);
  }

  /**
   * Create new organisation user based on token.
   */
  public create(form: { token: string; password: string; sector: string; organisationType: string }) {
    return this.userApiService.create(form);
  }

  /**
   * Activate registered user.
   */
  public activate(activationToken: string) {
    return this.userApiService.activate(activationToken);
  }

  /**
   * Resend activation link.
   */
  public initializeResendActivationEmail(email: string) {
    return this.userApiService.resendActivationEmail(email);
  }

  /**
   * Initialize password change request.
   */
  public initializePasswordChange(email: string) {
    return this.userApiService.initializePasswordChange(email);
  }

  /**
   * Change password.
   */
  public confirmPasswordChange(passwordChangeToken: string, form: any) {
    return this.userApiService.confirmPasswordChange(passwordChangeToken, form);
  }
  /**
   * Change email.
   */
  public confirmEmailChange(token: string) {
    return this.userApiService.confirmEmailChange(token);
  }
}
