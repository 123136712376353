import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'la-activation-required',
  styleUrls: ['./activation-required.component.scss'],
  templateUrl: './activation-required.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationRequiredComponent {
  @Input() email: string;
  public emailSent = false;

  constructor(
    public authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef
  ) {}

  public resend() {
    this.authenticationService.initializeResendActivationEmail(this.email).subscribe(() => {
      this.emailSent = true;
      this.cd.markForCheck();
    });
  }
}
