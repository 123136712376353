import { createAction } from '@ngrx/store';

import { AppError, Feature, FeatureChanges, Plan } from '@core/api';
import { apiActionFactory } from '@core/store';

type PlanError = { planId: string; error: AppError };

export const [initFeaturesSuccess, initFeaturesFail] = apiActionFactory<{ planId: string; features: Feature[] }, PlanError>(
  '[Features/API] Init Features'
);

export const [fetchFeatureSuccess, fetchFeatureFail] = apiActionFactory<{ planId: string; feature: Feature }, PlanError>(
  '[Features/API] Fetch Feature'
);

export const [fetchFeaturesSuccess, fetchFeaturesFail] = apiActionFactory<{ planId: string; features: Feature[] }, PlanError>(
  '[Features/API] Fetch Features'
);

export const [saveFeaturesSuccess, saveFeaturesFail] = apiActionFactory<{ planId: string; changes: FeatureChanges }, PlanError>(
  '[Features/API] Save Features'
);

export const saveFeaturesComplete = createAction('[Features/API] Save Features Complete');

export const [createFeaturesSuccess, createFeaturesFail] = apiActionFactory<{ plan: Plan }>('[Features/API] Create Features');
