import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { userApiActions } from '../actions/user.actions';
import { Organisation } from '@core/api';
import { organisationApiActions } from '../actions/organisation.actions';

export const featureKey = 'organisation';

export type OrganisationState = Organisation | undefined;

export const initialState: OrganisationState = undefined;

export const reducer = createReducer<OrganisationState>(
  initialState,
  on(initActions.appCleanup, () => initialState),

  on(
    initActions.appComplete,
    userApiActions.loginSuccess,
    organisationApiActions.fetchOrganisationSuccess,
    organisationApiActions.updateOrganisationSuccess,
    organisationApiActions.removeOSAPIKeySuccess,
    (state, { organisation }) => (organisation ? organisation : initialState)
  ),

  on(organisationApiActions.updateOSAPIKeySuccess, (state, { osApiKey }) => (state ? { ...state, osApiKey } : state))
);
