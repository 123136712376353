import { createAction, props } from '@ngrx/store';

import { SearchResult } from '@core/api';

export const search = createAction('[Search Bar] Trigger Search', props<{ phrase: string }>());

export const clear = createAction('[Search Bar] Clear Search Phrase');

export const searchStadiaMaps = createAction('[Search API] Stadia Maps', props<{ phrase: string }>());

export const searchStadiaMapsSuccess = createAction(
  '[Search API] Stadia Maps Success',
  props<{ phrase: string; response: SearchResult[] }>()
);

export const searchStadiaMapsFail = createAction('[Search API] Stadia Maps Fail');

export const searchLandRegistry = createAction('[Search API] Land Registry', props<{ phrase: string }>());

export const searchLandRegistrySuccess = createAction(
  '[Search API] Land Registry Success',
  props<{ phrase: string; response: SearchResult[] }>()
);

export const searchLandRegistryFail = createAction('[Search API] Land Registry Fail');

export const searchWhat3Words = createAction('[Search API] What3Words', props<{ phrase: string }>());

export const searchWhat3WordsSuccess = createAction(
  '[Search API] What3Words Success',
  props<{ phrase: string; response: SearchResult[] }>()
);

export const searchWhat3WordsFail = createAction('[Search API] What3Words Fail');

export const searchGridRef = createAction('[Search] Grid Ref', props<{ phrase: string }>());

export const zoomToResult = createAction('[Search] Zoom To Result', props<{ result: SearchResult; searchType: string }>());

export const searchBarClicked = createAction('[Search] Search Bar Clicked');
