import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-avatar-icon',
  templateUrl: './avatar-icon.component.html',
  styleUrls: ['./avatar-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarIconComponent {
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() numberToShow?: number;
  @Input() small = false;

  get initials() {
    return (Array.from(this.firstName)[0] + Array.from(this.lastName)[0]).toUpperCase();
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
