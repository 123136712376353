<la-header></la-header>

<section>
  @if(form){
  <div class="content">
    <h1 class="title">Reset password</h1>
    <p>Create a new password to log back in to your account</p>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Password</mat-label>
        <input
          type="password"
          placeholder="Min. 8 characters, 1 number and 1 special"
          matInput
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
        />
        @if (form.controls['password'].invalid) {
        <mat-error> Please enter a valid password. Min. 8 characters including 1 special and 1 number</mat-error>
        }
        <mat-icon matSuffix (click)="toggleVisibility()">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>

      @if(isUserMigrating){
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Sector</mat-label>
        <mat-select placeholder="Sector" formControlName="sector">
          <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{ sector.name }}</mat-option>
        </mat-select>
        @if (form.controls['sector'].invalid) {<mat-error>Please choose a sector</mat-error>}
      </mat-form-field>

      <div class="phone">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Dialling Code</mat-label>
          <mat-select placeholder="Dialling Code" formControlName="countryCode">
            <mat-option *ngFor="let code of COUNTRY_CODES | keyvalue : valueAscOrder" [value]="code.value"> +{{ code.value }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Phone number</mat-label>
          <input type="tel" placeholder="Phone number" matInput formControlName="phoneNumber" autocomplete="off" maxLength="20" required />
          @if (form.controls['phoneNumber'].invalid) {<mat-error>Please enter a valid phone number</mat-error>}
        </mat-form-field>
      </div>
      }

      <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid" (click)="submit()">Confirm new password</button>
    </form>
  </div>
  }
</section>

<la-footer></la-footer>
