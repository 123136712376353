import { createAction, props } from '@ngrx/store';

import { AppError } from '@core/api';
import { apiActionFactory } from '@core/store';

/**
 * Container Actions
 * ----------------------------------------------
 */

export const resetPhotoUploads = createAction('[Map/Photos Upload] Reset Photo Uploads');

export const selectPhotos = createAction('[Map/Photos Upload] Select Photos', props<{ photos: FileList }>());

export const cancelPhotoUpload = createAction('[Map/Photos Upload] Cancel Photo Upload', props<{ id: string }>());

export const refreshPhotos = createAction('[Map/Photos Upload] Refresh Photos', props<{ mapId: string }>());

/**
 * Internal Actions
 * ----------------------------------------------
 */

export const uploadPhoto = createAction('[Map/Photos Upload/Effect] Upload Photo', props<{ id: string; photo: File }>());

/**
 * API Actions
 * ----------------------------------------------
 */
export const [uploadPhotoSuccess, uploadPhotoFail] = apiActionFactory<
  { id: string; projection: string; feature: GeoJSON.Feature },
  { id: string; error: AppError }
>('[Map/Photos Upload/API] Upload Photo');

export const setUploadPhotoProgress = createAction(
  '[Map/Photos Upload/API] Set Upload Photo Progress',
  props<{ id: string; progress: number }>()
);

export const [refreshPhotosSuccess, refreshPhotosFail] = apiActionFactory<{ features: GeoJSON.Feature[] }>(
  '[Map/Photos Upload/API] Refresh Photos'
);
