import { SubscriptionDetails } from '@core/api';
import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { subscriptionApiActions } from '../actions/subscription.actions';
import { userApiActions } from '../actions/user.actions';

export const featureKey = 'subscription';

export type SubscriptionState = SubscriptionDetails | undefined;

export const initialState: SubscriptionState = undefined;

export const reducer = createReducer<SubscriptionState>(
  initialState,
  on(initActions.appCleanup, () => initialState),

  on(initActions.appComplete, userApiActions.loginSuccess, subscriptionApiActions.fetchSubscriptionSuccess, (state, { subscription }) =>
    subscription ? { ...subscription } : undefined
  )
);
