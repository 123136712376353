import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';

import { catchAppError } from '@core/api';
import { AuthenticationService } from '../../services/authentication.service';
import { createUser, createUserFail, createUserSuccess } from '../actions/join-organisation.actions';

@Injectable()
export class JoinOrganisationEffects {
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUser),
      switchMap((formData) =>
        this.authenticationService.create(formData).pipe(
          map(() => createUserSuccess()),
          tap(() => this.router.navigate(['/login'])),
          catchAppError((error) => createUserFail({ error }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}
}
