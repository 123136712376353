import { createFeatureSelector } from '@ngrx/store';

import * as fromConfig from '../reducers/config.reducer';
import * as fromInit from '../reducers/init.reducer';
import * as fromLimits from '../reducers/limits.reducer';
import * as fromOrganisation from '../reducers/organisation.reducer';
import * as fromPayment from '../reducers/payment.reducer';
import * as fromSubscription from '../reducers/subscription.reducer';
import * as fromTeams from '../reducers/teams.reducer';
import * as fromUser from '../reducers/user.reducer';

export const getConfigState = createFeatureSelector<fromConfig.ConfigState>(fromConfig.featureKey);

export const getInitState = createFeatureSelector<fromInit.InitState>(fromInit.featureKey);

export const getOrganisationState = createFeatureSelector<fromOrganisation.OrganisationState>(fromOrganisation.featureKey);

export const getPaymentState = createFeatureSelector<fromPayment.PaymentState>(fromPayment.featureKey);

export const getSubscriptionState = createFeatureSelector<fromSubscription.SubscriptionState>(fromSubscription.featureKey);

export const getLimitsState = createFeatureSelector<fromLimits.LimitsState>(fromLimits.featureKey);

export const getTeamsState = createFeatureSelector<fromTeams.TeamsState>(fromTeams.featureKey);

export const getUserState = createFeatureSelector<fromUser.UserState>(fromUser.featureKey);
