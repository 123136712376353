import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { paymentApiActions } from '../actions/payment.actions';

export const featureKey = 'payment';

export type PaymentState = { valid: boolean };

export const initialState: PaymentState = { valid: false };

export const reducer = createReducer<PaymentState>(
  initialState,
  on(initActions.appCleanup, () => initialState),

  on(paymentApiActions.initPayment, (state, { valid }) => ({
    ...state,
    valid,
  })),

  on(paymentApiActions.fetchPaymentSuccess, (state) => ({
    ...state,
    valid: true,
  })),
  on(paymentApiActions.fetchPaymentFail, (state) => ({
    ...state,
    valid: false,
  }))
);
