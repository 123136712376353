import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppError } from '@core/api';

export const paymentApiActions = createActionGroup({
  source: 'Payment/API',
  events: {
    'Init Payment': props<{ valid: boolean }>(),
    'Fetch Payment Success': emptyProps(),
    'Fetch Payment Fail': props<{ error: AppError }>(),
  },
});
