import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { AppError } from '@core/api';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'la-change-user-email-success',
  templateUrl: './change-user-email-success.component.html',
  styleUrls: ['./change-user-email-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeUserEmailSuccessComponent implements OnInit {
  public loading = true;
  public error: AppError;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.pipe(switchMap(({ token }) => this.authenticationService.confirmEmailChange(token))).subscribe(
      () => {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
      (error: AppError) => {
        this.loading = false;
        this.error = error;
        this.changeDetectorRef.markForCheck();
      }
    );
  }
}
