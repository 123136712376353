<la-header></la-header>

<section>
  @if (loginStatus$ | async; as status) {
    @if (status === NOT_ACTIVE) {
      <la-activation-required [email]="form.get('email').value"></la-activation-required>
    }
    @if (status === PASSWORD_RESET_REQUIRED) {
      <la-reset-password-required></la-reset-password-required>
    }
  } @else {
    <div class="content">
      <h1 class="title">Welcome back to Land App</h1>
      @if (loginToAccount) {
        <p>Log in to your account</p>
      }
      @if (errorMessage$ | async; as message) {
        <mat-error class="error">
          <p>{{ message }}</p>
        </mat-error>
      }
      @if (userRecentlyActivated) {
        <p>Your account has been activated. You can now log in.</p>
      }
      @if (userRecentlyChangedPassword) {
        <p>Your password has been changed. You can now log in.</p>
      }
      @if (userRecentlyCreated) {
        <p>Your account was successfully created. You can now log in.</p>
      }

      <form [formGroup]="form">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Email address</mat-label>
          <input type="email" placeholder="yours@example.com" matInput formControlName="email" />
          @if (form.controls['email'].invalid) {
            <mat-error>Please enter a email</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Password</mat-label>
          <input type="password" placeholder="password" matInput formControlName="password" required [type]="hide ? 'password' : 'text'" />
          @if (form.controls['password'].invalid) {
            <mat-error>Please enter a password</mat-error>
          }
          <mat-icon matSuffix (click)="toggleVisibility()">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>

        <span class="wrapper">
          <a [routerLink]="['/forgot-password']" tabIndex="-1"><strong>Forgot your password?</strong></a>
        </span>

        <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || (loading$ | async)" (click)="login()">
          Log in
        </button>

        <span class="wrapper">
          <p>
            Not registered? <a [routerLink]="['/register']"><strong> Sign up</strong></a>
          </p>
        </span>
      </form>
    </div>
  }
</section>

<la-footer></la-footer>
