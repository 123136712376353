import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { User } from '@core/api';
import { APP_STATES } from '../enums/app.enum';
import { getAppStatus } from '../selectors/init.selectors';
import { getUser } from '../selectors/user.selectors';

@Injectable({ providedIn: 'root' })
export class ForceUserToSetOrganisationTypeGuard {
  constructor(
    public router: Router,
    private store: Store
  ) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      switchMap(() => this.store.select(getUser)),
      map((user) => {
        if (user) {
          this.forceUserToAddOrganisationType(user);
        }
        return true;
      })
    );
  }

  private forceUserToAddOrganisationType(user: User) {
    if (user && (!user.organisationType || !user.sector)) {
      this.router.navigate(['users/setup']);
    }
  }
}
