import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { omit } from 'lodash';
import { map, switchMap } from 'rxjs/operators';

import { UserApiService, catchAppError } from '@core/api';
import { register, registerFail, registerSuccess } from '../actions/register.actions';

@Injectable()
export class RegisterEffects {
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(register),
      switchMap((formData) =>
        this.userApi
          .register({ ...omit(formData, ['type', 'policiesCheckbox']), phoneNumber: formData.phoneNumber.replace(/(\s|-)+/g, '') })
          .pipe(
            map(({ id, subscription }) => registerSuccess({ ...formData, id, subscription })),
            catchAppError((error) => registerFail({ error }))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userApi: UserApiService
  ) {}
}
