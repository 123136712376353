import { createSelector } from '@ngrx/store';

import { Subscription, UserGroup } from '@core/api';

import { getUserState } from './main.selectors';

export const getUser = createSelector(getUserState, (state) => state);

export const getUserId = createSelector(getUserState, (state) => state?.id);

export const getUserEmail = createSelector(getUserState, (state) => state?.email);

export const getUserSubscription = createSelector(getUserState, (state) => state?.subscription);

export const isFreeUser = createSelector(getUserState, (state) => state?.subscription === Subscription.FREE);

export const getIsBetaTester = createSelector(getUserState, (state) => state?.groups?.includes(UserGroup.BETA_TESTER));

export const getUserSector = createSelector(getUser, (state) => state?.sector);
