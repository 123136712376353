import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  @Input() description?: string;
}
