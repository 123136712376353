import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@core/config';
import { ArchivedMapLike, Map, MapDelete, MapLandDeclaration, MapLike, MapPatch, MapsBalance, PublishedMap } from '../models/map.model';
import { deserialise } from '../operators/deserialise.operator';
import { applyParams } from '../utils/apply-params.utils';

interface ListQueryParams {
  size?: number;
  page?: number;
  search?: string;
  team?: string[];
  sort?: string;
  order?: string;
}

@Injectable({ providedIn: 'root' })
export class MapsApiService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private http: HttpClient
  ) {}

  listOwnedMaps(parameters: ListQueryParams) {
    const params = applyParams(parameters);
    return this.http.get<MapLike[]>(`${this.config.api.mapUrl}/maps`, { params }).pipe(deserialise());
  }

  listSharedMaps(parameters: ListQueryParams) {
    const params = applyParams(parameters);
    return this.http.get<MapLike[]>(`${this.config.api.mapUrl}/maps/shared`, { params }).pipe(deserialise());
  }

  listArchivedMaps(parameters: ListQueryParams) {
    const params = applyParams(parameters);
    return this.http.get<ArchivedMapLike[]>(`${this.config.api.mapUrl}/maps/archived`, { params }).pipe(deserialise());
  }

  listPublishedMaps(parameters: ListQueryParams) {
    const params = applyParams(parameters);
    return this.http.get<PublishedMap[]>(`${this.config.api.mapUrl}/maps/published`, { params }).pipe(deserialise());
  }

  checkLimits() {
    const params = applyParams({ size: this.config.limits.maps });
    return this.http.get<MapLike[]>(`${this.config.api.mapUrl}/maps`, { params }).pipe(
      deserialise(),
      map((maps) => maps.length === this.config.limits.maps)
    );
  }

  readOne(id: string) {
    return this.http.get<Map>(`${this.config.api.mapUrl}/maps/${id}`).pipe(deserialise());
  }

  create(name: string, teams: string[]) {
    const data = { name, teams, type: 'MAP' };
    return this.http.post<MapLike>(`${this.config.api.mapUrl}/maps`, data).pipe(deserialise());
  }

  patch(id: string, data: Partial<Map>) {
    return this.http.patch<MapPatch>(`${this.config.api.mapUrl}/maps/${id}`, data).pipe(deserialise());
  }

  delete(id: string) {
    return this.http.delete<MapDelete>(`${this.config.api.mapUrl}/maps/${id}`).pipe(deserialise());
  }

  addTeam(mapId: string, teamId: string) {
    return this.http.post<void>(`${this.config.api.mapUrl}/organisation/teams/${teamId}/maps`, { map: mapId });
  }

  removeTeam(mapId: string, teamId: string) {
    return this.http.request<void>('delete', `${this.config.api.mapUrl}/organisation/teams/${teamId}/maps`, { body: { map: mapId } });
  }

  restore(id: string) {
    return this.http.post(`${this.config.api.mapUrl}/maps/${id}/restore`, { id }).pipe(deserialise());
  }

  restoreAll() {
    return this.http.post<void>(`${this.config.api.mapUrl}/maps/restore`, {});
  }

  balance() {
    return this.http.get<MapsBalance>(`${this.config.api.mapUrl}/maps/balance`);
  }

  fetchLandDeclaration(mapId: string) {
    return this.http
      .get<MapLandDeclaration>(`${this.config.api.mapUrl}/nature-reporting/maps/${mapId}/land-declarations`)
      .pipe(deserialise())
      .pipe(map((data) => ({ id: data.id, map: data.map, plans: data.plans, publishedTo: data.publishedTo })));
  }
}
