<la-header></la-header>

<section>
  <div class="content">
    <h1 class="title">Please set the sector and organisation type</h1>
    <mat-form-field appearance="outline">
      <mat-label>Sector</mat-label>
      <mat-select placeholder="Sector" [(value)]="selectedSector">
        @for (sector of sectors$ | async; track sector) {
          <mat-option [value]="sector.id">{{ sector.name }} </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Organisation type</mat-label>
      <mat-select placeholder="Organisation type" [(value)]="selectedType">
        @for (organisationType of organisationTypes$ | async; track organisationType) {
          <mat-option [value]="organisationType.id">
            {{ organisationType.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!selectedSector || !selectedType || (loading$ | async) === true"
      (click)="save()"
    >
      Save
    </button>

    @if (error$ | async; as error) {
      <la-error-section [error]="error" title="Faild to set user details"></la-error-section>
    }
  </div>
</section>

<la-footer></la-footer>
