import { createAction, props } from '@ngrx/store';

import { AppError, ArchivedLayer, LayerChanges, MapChanges, MapLandDeclaration, Plan, PlanDetails, UserLayer } from '@core/api';
import { apiActionFactory } from '@core/store';

type PlanError = { planId: string; error: AppError };
type UploadError = { id: string; error: AppError; subtype: string; importMethod: string };
type CreatePlanError = { error: AppError; subtype?: string; importMethod?: string; changes?: MapChanges; plans?: Plan[] };
type CreatePlanSuccess = { changes: MapChanges; plans: Plan[]; subtype?: string; importMethod?: string };

export const [initPlansSuccess, initPlansFail] = apiActionFactory<{ mapId: string; data: { id: string; plan: Plan; visible: boolean }[] }>(
  '[Plans/API] Init Plans'
);
export const [fetchPlanSuccess, fetchPlanFail] = apiActionFactory<{ plan: Plan }, PlanError>('[Plans/API] Fetch Plan');
export const [refetchPlanSuccess, refetchPlanFail] = apiActionFactory<{ plan: Plan }, PlanError>('[Plans/API] Refetch Plan');
export const fetchPlanComplete = createAction('[Plans/API] Fetch Plan Complete', props<{ planId: string }>());

export const [fetchPlanDetailsSuccess, fetchPlanDetailsFail] = apiActionFactory<{ plan: PlanDetails }, PlanError>(
  '[Plans/API] Fetch Plan Details'
);

export const [createPlanSuccess, createPlanFail] = apiActionFactory<CreatePlanSuccess, CreatePlanError>('[Plans/API] Create Plan');
export const [createPlanDuplicateSuccess, createPlanDuplicateFail] = apiActionFactory<CreatePlanSuccess, CreatePlanError>(
  '[Plans/API] Create Plan Duplicate'
);
export const [createPlanFromRPASuccess, createPlanFromRPAFail] = apiActionFactory<CreatePlanSuccess, CreatePlanError>(
  '[Plans/API] Create Plan RPA'
);
export const [createPlanFromLandPickerSuccess, createPlanFromLandPickerFail] = apiActionFactory<CreatePlanSuccess, CreatePlanError>(
  '[Plans/API] Create Plan From Land Picker'
);
export const [createPlansFromFilesSuccess, createPlansFromFilesFail] = apiActionFactory<CreatePlanSuccess>(
  '[Plans/API] Create Plans From Files'
);

export const [createPlanWithFeaturesSuccess, createPlanWithFeaturesFail] = apiActionFactory<{ changes: MapChanges; plans: Plan[] }>(
  '[Plans/API] Create Plan With Features'
);

export const [fetchOrderSuccess, fetchOrderFail] = apiActionFactory<MapChanges>('[Plans/API] Fetch Plan Order');

export const [reorderPlansSuccess, reorderPlansFail] = apiActionFactory<MapChanges>('[Plans/API] Reorder Plans');

export const [renamePlanSuccess, renamePlanFail] = apiActionFactory<{ layer: UserLayer }, PlanError>('[Plans/API] Rename Plan');

export const [updatePlanSuccess, updatePlanFail] = apiActionFactory<{ layer: UserLayer }, PlanError>('[Plans/API] Update Plan');

export const [archivePlanSuccess, archivePlanFail] = apiActionFactory<MapChanges>('[Plans/API] Archive Plan');

export const [fetchLandDeclarationSuccess, fetchLandDeclarationFail] = apiActionFactory<{ data: MapLandDeclaration }>(
  '[Plans/API] Fetch Land Declaration'
);

export const [hidePlansSuccess, hidePlansFail] = apiActionFactory<MapChanges>('[Plans/API] Hide Plans');

export const [showPlansSuccess, showPlansFail] = apiActionFactory<MapChanges>('[Plans/API] Show Plans');

export const [publishPlanSuccess, publishPlanFail] = apiActionFactory<LayerChanges, PlanError>('[Plans/API] Publish Plan');

export const [unpublishPlanSuccess, unpublishPlanFail] = apiActionFactory<LayerChanges, PlanError>('[Plans/API] Unpublish Plan');

export const [lockPlanSuccess, lockPlanFail] = apiActionFactory<LayerChanges, PlanError>('[Plans/API] Lock Plan');

export const [unlockPlanSuccess, unlockPlanFail] = apiActionFactory<LayerChanges, PlanError>('[Plans/API] Unlock Plan');

export const [fetchArchivedPlansSuccess, fetchArchivedPlansFail] = apiActionFactory<{ plans: ArchivedLayer[] }>(
  '[Plans/API] Fetch Archived Plans'
);

export const [restorePlanSuccess, restorePlanFail] = apiActionFactory<{ changes: MapChanges; plans: Plan[] }>('[Plans/API] Restore Plan');

export const exportPlansSuccess = createAction('[Plans/API] Export Plans Success');
export const exportPlansFail = createAction('[Plans/API] Export Plans Fail', props<{ error: AppError }>());

export const [uploadPlanSuccess, uploadPlanFail] = apiActionFactory<{ id: string; subtype: string; importMethod: string }, UploadError>(
  '[Plans/API] Upload Plan'
);

export const [deletePlanSuccess, deletePlanFail] = apiActionFactory<MapChanges>('[Plans/API] Delete Plan');

export const [fetchDeletePrerequisitesSuccess, fetchDeletePrerequisitesFail] = apiActionFactory<{
  isPublishedInLD?: boolean;
  hasPlanReports?: boolean;
  hasDataLayerReports?: boolean;
  isPublishedToOrganisation?: boolean;
}>('[Plans/API] Fetch Delete Prerequisites');

export const [fetchArchivePrerequisitesSuccess, fetchArchivePrerequisitesFail] = apiActionFactory<{
  isPublishedInLD?: boolean;
  isLastBhaPlanInLD?: boolean;
}>('[Plans/API] Fetch Archive Prerequisites');
