import { initActions, ItemUIState } from '@core/store';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { fetchLimitsActions } from '../actions/limits.actions';
import { leaveMap } from '../actions/map-page.actions';
import { Balance } from '@core/api';

export const featureKey = 'limits';

export interface LimitsState {
  plans: ItemUIState<Balance>;
}

export const initialState: LimitsState = {
  plans: { status: 'INIT' },
};

export const reducer = createReducer<LimitsState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, () => cloneDeep(initialState)),

  on(fetchLimitsActions.fetchPlansBalance, (state) => ({
    ...state,
    plans: {
      ...state.plans,
      status: 'LOADING',
    },
  })),
  on(fetchLimitsActions.fetchPlansBalanceSuccess, (state, { data }) => ({
    ...state,
    plans: {
      status: 'READY',
      data,
    },
  })),
  on(fetchLimitsActions.fetchPlansBalanceFails, (state, { error }) => ({
    ...state,
    plans: {
      status: 'ERROR',
      error,
    },
  }))
);
