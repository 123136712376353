import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppError, MapsBalance } from '@core/api';

export const limitsActions = createActionGroup({
  source: 'Limits/API',
  events: {
    'Fetch Maps Balance': emptyProps(),
    'Fetch Maps Balance Success': props<{ data: MapsBalance }>(),
    'Fetch Maps Balance Fail': props<{ error: AppError }>(),
  },
});
