import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';

import { CircleChartComponent } from './components/circle-chart/circle-chart.component';
import { DonutChartLegendComponent } from './components/donut-chart-legend/donut-chart-legend.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';

@NgModule({
  imports: [CommonModule, NgApexchartsModule],
  declarations: [DonutChartComponent, DonutChartLegendComponent, CircleChartComponent],
  exports: [DonutChartComponent, DonutChartLegendComponent, CircleChartComponent],
})
export class ChartsModule {}
