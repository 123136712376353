import { AppError } from './error.model';
import { Team } from './team.model';

export type PrintState = 'pending' | 'processing' | 'finished' | 'error' | 'locked';

export type PAPER_SIZE =
  | 'A0_LANDSCAPE'
  | 'A0_PORTRAIT'
  | 'A1_LANDSCAPE'
  | 'A1_PORTRAIT'
  | 'A2_LANDSCAPE'
  | 'A2_PORTRAIT'
  | 'A3_LANDSCAPE'
  | 'A3_PORTRAIT'
  | 'A4_LANDSCAPE'
  | 'A4_PORTRAIT'
  | 'A5_LANDSCAPE'
  | 'A5_PORTRAIT';

export const PAPER_SIZES_LOOKUP = {
  A0_LANDSCAPE: 'A0 Landscape',
  A0_PORTRAIT: 'A0 Portrait',
  A1_LANDSCAPE: 'A1 Landscape',
  A1_PORTRAIT: 'A1 Portrait',
  A2_LANDSCAPE: 'A2 Landscape',
  A2_PORTRAIT: 'A2 Portrait',
  A3_LANDSCAPE: 'A3 Landscape',
  A3_PORTRAIT: 'A3 Portrait',
  A4_LANDSCAPE: 'A4 Landscape',
  A4_PORTRAIT: 'A4 Portrait',
  A5_LANDSCAPE: 'A5 Landscape',
  A5_PORTRAIT: 'A5 Portrait',
};

export type OVERLAY_ELEMENT_POSITION =
  | 'OVERLAY_TOP_LEFT'
  | 'OVERLAY_TOP_CENTER'
  | 'OVERLAY_TOP_RIGHT'
  | 'OVERLAY_BOTTOM_LEFT'
  | 'OVERLAY_BOTTOM_CENTER'
  | 'OVERLAY_BOTTOM_RIGHT';

export type OVERLAY_LEGEND_POSITION = 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';

export type PRINT_SCALING = 'auto' | 'fixed';

export type PRINT_SETUP_VIEW = 'NEW_FRAME' | 'EXISTING_FRAME' | 'NONE';

export interface PrintLegend {
  style: {
    backgroundColor?: string;
    border?: string;
    fillPattern?: string;
    fillPatternColor?: string;
    fillPatternOpacity?: number;
    strokeDash?: number[];
    strokeColor?: string;
  };
  text: string;
  visible: boolean;
  type: string;
}

export interface PrintBasicOverlayElement {
  active: boolean;
  position?: OVERLAY_ELEMENT_POSITION;
}

export interface PrintTextOverlayElement extends PrintBasicOverlayElement {
  text?: string;
  fontSize?: number;
}

export interface PrintPhotoIconOverlayElement extends PrintBasicOverlayElement {
  active: boolean;
  fontSize?: number;
}

export interface PrintLogoOverlayElement extends PrintBasicOverlayElement {
  name?: string;
  locationStorage?: string;
}

export interface PrintLegendOverlayElement {
  active: boolean;
  position?: OVERLAY_LEGEND_POSITION;
  entries?: PrintLegend[];
  title?: string;
  fontSize?: number;
  mode?: 'entries' | 'image';
  image?: string;
}

export interface PrintOverlayPositioning {
  enabled: boolean;
  element?: string;
  group?: string;
  trigger?: 'add' | 'move';
}

export interface PrintPayment {
  loading: boolean;
  paying: boolean;
  needsToUpdatePaymentMethod: boolean;
  needsToClickBuyAgain: boolean;
  paid: boolean;
  canUpdatePaymentMethod: boolean;
  error?: AppError;
}

export interface PrintSnapshotMetadata {
  id: string;
  features: string[];
  projection: string;
  type: string;
  subtype: string;
  name: string;
}

export interface PrintSnapshotFeatures {
  page: number;
  size: number;
  features: {
    id: string;
    geometry: GeoJSON.Geometry;
    properties: GeoJSON.GeoJsonProperties;
    projection: string;
  }[];
}

export interface PrintSnapshot extends PrintSnapshotMetadata {
  geojson: GeoJSON.FeatureCollection;
}

export interface PrintSnapshotPost {
  plan: string;
  features: (string | number)[];
}

export interface PrintLike {
  id: string;
  owner: string;
  ownerFullName?: string;
  ownerEmail?: string;
  map: {
    id: string;
    name: string;
  };
  basemap: string;
  layers: string[];
  frame?: string;
  paperSize: PAPER_SIZE;
  bbox: [number, number, number, number];
  bboxProjection: string;
  availableExtensions: string[];
  state: PrintState;
  scale: number;
  area: number;
  createdAt: string;
  finishedAt?: string;
  progress?: number;
  thumbnailUrl?: string;
  price: number;
  priceType: string;
  preview: boolean;
  title: string;
  jobCode: string;
  billable: string;
  locked: boolean;
  organisation?: {
    id: string;
    teams: Team[];
  };
}

export interface Print {
  id?: string;
  owner?: string;
  map: {
    id: string;
    name: string;
  };
  basemap: string;
  layers: string[];
  frame?: string;
  paperSize: PAPER_SIZE;
  bbox: [number, number, number, number];
  bboxProjection: string;
  availableExtensions?: string[];
  state?: PrintState;
  scale: number;
  border: string;
  area: number;
  createdAt?: string;
  finishedAt?: string;
  progress?: number;
  thumbnailUrl?: string;
  price: number;
  priceType: string;
  preview: boolean;
  title: string;
  jobCode?: string;
  billable?: string;
  locked?: boolean;
  organisation?: {
    id: string;
    teams: string[];
  };
  snapshot?: PrintSnapshot[]; // deprecated
  snapshots?: string[];
  variant?: string;
  header: PrintTextOverlayElement;
  footer: PrintTextOverlayElement;
  photos: PrintPhotoIconOverlayElement;
  legend: PrintLegendOverlayElement;
  copyright: PrintBasicOverlayElement;
  scaleBar: PrintBasicOverlayElement;
  logo: PrintLogoOverlayElement;
  landappLogo: PrintBasicOverlayElement;
  mapLabels: {
    fontSize: number;
  };
}

export interface PrintPrice extends PriceDetails {
  price: number;
  type: string;
}

export interface PrintRequest {
  map: string;
  basemap: string;
  layers: string[];
  paperSize: PAPER_SIZE;
  bbox: [number, number, number, number];
  bboxProjection: string;
  frame: string;
  scale: number;
  area: number;
  border: string;
  price: number;
  priceType: string;
  title: string;
  jobCode: string;
  billable: string;
  header: PrintTextOverlayElement;
  footer: PrintTextOverlayElement;
  legend: PrintLegendOverlayElement;
  photos: PrintPhotoIconOverlayElement;
  copyright: PrintBasicOverlayElement;
  scaleBar: PrintBasicOverlayElement;
  logo: PrintLogoOverlayElement;
  landappLogo: PrintBasicOverlayElement;
  mapLabels: {
    fontSize: number;
  };
  snapshots?: string[];
  variant?: string;
}

export interface NewFrameData {
  paperSize: PAPER_SIZE;
  scaling: PRINT_SCALING;
  scale: number;
  printExtent: [number, number, number, number];
  border: string;
}

export interface PrintOverlay {
  header: PrintTextOverlayElement;
  footer: PrintTextOverlayElement;
  legend: PrintLegendOverlayElement;
  copyright: PrintBasicOverlayElement;
  scaleBar: PrintBasicOverlayElement;
  logo: PrintLogoOverlayElement;
  landappLogo: PrintBasicOverlayElement;
  mapLabels: {
    fontSize: number;
  };
}

export interface PrintBorder {
  id: string;
  name: string;
  default: boolean;
  settings: PrintBorderSettings[];
}

export interface PrintBorderSettings {
  paperSize: PAPER_SIZE;
  margins: PrintBorderMargins;
}

export interface PrintBorderMargins {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface PriceDetails {
  priceWithoutVat: number;
  priceWithVat: number;
  vatAmount: number;
  vatPercentage: number;
}

export interface PrintQualityOption {
  id: string;
  name: string;
  basemaps: string[];
  variants: PrintQualityVariant[];
  default?: boolean;
}

export interface PrintQualityVariant {
  id: string;
  name: string;
  layers: string[];
  default?: boolean;
}
