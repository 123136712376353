import { AREA_UNITS } from '@core/api';
import { round } from '@core/common';
import { isNil } from 'lodash';

const SQUARE_METER_TO_HECTARE = 0.0001;
const SQUARE_METER_TO_ACRE = 0.000247105381;
const SQUARE_METER_TO_SQUARE_KILOMETER = 0.000001;
const SQUARE_METER_TO_SQUARE_FOOT = 10.763910417;

const squareMeterToHectare = (value: number): number => {
  return value * SQUARE_METER_TO_HECTARE;
};

const squareMeterToAcre = (value: number): number => {
  return value * SQUARE_METER_TO_ACRE;
};

const squareMeterToSquareKilometer = (value: number): number => {
  return value * SQUARE_METER_TO_SQUARE_KILOMETER;
};

const squareMeterToSquareFoot = (value: number): number => {
  return value * SQUARE_METER_TO_SQUARE_FOOT;
};

const isInputValid = (value: number): boolean => {
  return !(value < 0 || typeof value !== 'number');
};

export const convertAreaTo = (
  value: number | null | undefined,
  unit: AREA_UNITS,
  precision: number = 2,
  showUnit: boolean = true
): string => {
  if (isNil(value) || !isInputValid(value)) {
    return '';
  }

  let recalculatedArea = value;
  let formattedUnit: string = unit;

  switch (unit) {
    case 'ha':
      recalculatedArea = squareMeterToHectare(value);
      break;
    case 'ac':
      recalculatedArea = squareMeterToAcre(value);
      break;
    case 'm2':
      formattedUnit = 'm²';
      break;
    case 'ft2':
      recalculatedArea = squareMeterToSquareFoot(value);
      formattedUnit = 'ft²';
      break;
    case 'km2':
      recalculatedArea = squareMeterToSquareKilometer(value);
      formattedUnit = 'km²';
      break;
  }

  return `${round(recalculatedArea, precision).toFixed(precision)} ${showUnit ? formattedUnit : ''}`;
};
