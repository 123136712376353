import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'lodash';

@Pipe({ name: 'laGroup' })
export class GroupPipe implements PipeTransform {
  transform(collection: any[], property: string): { name: string; data: any[] }[] {
    const groupedCollection = groupBy(collection, (element) => {
      if (Array.isArray(element[property])) {
        return element[property][0] || 'Unknown';
      }

      return element[property] || 'Unknown';
    });

    return Object.keys(groupedCollection)
      .sort()
      .map((key) => ({ name: key, data: groupedCollection[key] }));
  }
}
