import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthAuthorizedGuard, AuthUnauthorizedGuard } from '@core/store';

import { ActivatePageComponent } from './pages/activate-page/activate-page.component';
import { ChangeUserEmailSuccessComponent } from './pages/change-user-email-success/change-user-email-success.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { JoinOrganizationPageComponent } from './pages/join-organization-page/join-organization-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { UserSetupPageComponent } from './pages/user-setup-page/user-setup-page.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent, canActivate: [AuthUnauthorizedGuard] },
  { path: 'logged-out', component: LogoutPageComponent },
  { path: 'register', component: RegisterPageComponent, canActivate: [AuthUnauthorizedGuard] },
  { path: 'forgot-password', component: ForgotPasswordPageComponent, canActivate: [AuthUnauthorizedGuard] },
  { path: 'users/activate/:token', component: ActivatePageComponent, canActivate: [AuthUnauthorizedGuard] },
  {
    path: 'users/password/reset/0/:token',
    component: ResetPasswordPageComponent,
    data: { type: 'new' },
    canActivate: [AuthUnauthorizedGuard],
  },
  {
    path: 'users/password/reset/1/:token',
    component: ResetPasswordPageComponent,
    data: { type: 'old' },
    canActivate: [AuthUnauthorizedGuard],
  },
  {
    path: 'users/email/change/:token',
    component: ChangeUserEmailSuccessComponent,
  },
  {
    path: 'users/setup',
    component: UserSetupPageComponent,
    canActivate: [AuthAuthorizedGuard],
  },
  { path: 'users/new/:token', component: JoinOrganizationPageComponent, canActivate: [AuthUnauthorizedGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
