import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'la-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor {
  @Input() min?: number;
  @Input() max?: number;
  @Input() step = 1;
  @Input() label = '';

  sliderValue?: number;

  constructor(private cd: ChangeDetectorRef) {}

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: number) {
    this.sliderValue = +input;
    this.onChange(+this.sliderValue);
  }

  inputChange() {
    if (this.sliderValue) {
      this.passValue(+this.sliderValue);
    }
  }

  passValue(value: number) {
    value = this.sanitizeValue(value);
    this.sliderValue = value;
    this.onChange(this.sliderValue);
    this.cd.markForCheck();
  }

  private sanitizeValue(value: number): number {
    if (this.min && value < this.min) return this.min;
    if (this.max && value > this.max) return this.max;
    return value;
  }
}
