import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';

import { initActions } from '../actions/init.actions';
import { userApiActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userApiActions.logoutSuccess),
      delay(1),
      map(() => initActions.appCleanup())
    )
  );

  tokenExpired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userApiActions.tokenExpired),
      delay(1),
      tap(() => this.router.navigate(['/login'])),
      map(() => userApiActions.logoutSuccess())
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}
