<mat-chip-listbox>
  <mat-chip *ngFor="let team of teamsToRender" [laTeamChipColor]="team.color"
    >{{ showRole && team.role ? team.name + ' (' + team.role + ')' : team.name }}
  </mat-chip>
</mat-chip-listbox>

<button *ngIf="teamsForMore.length" class="more" mat-icon-button [matMenuTriggerFor]="moreTeamsMenu" (click)="$event.stopPropagation()">
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #moreTeamsMenu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <div class="chip-menu-item" *ngFor="let chip of teamsForMore">
      <mat-chip [laTeamChipColor]="chip.color">{{ chip.name }}</mat-chip>
    </div>
  </ng-template>
</mat-menu>
