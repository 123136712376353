import { findLastIndex, omit } from 'lodash';

import { FeatureChanges, Plan } from '@core/api';

import {
  CreateOperation,
  DeleteOperation,
  DuplicateOperation,
  FeatureRelatedOperation,
  HistoryOperation,
  Operation,
  OrderOperation,
  PatchOperation,
  PatchOperationChunk,
} from '../../models/operations.model';

export function toCreateOperation(feature: GeoJSON.Feature): CreateOperation {
  return {
    type: 'create',
    feature,
  };
}

export function toPatchOperation(feature: GeoJSON.Feature, all: GeoJSON.Feature[], chunk?: PatchOperationChunk): PatchOperation {
  const before = all.find((f) => f.id === feature.id);
  return {
    type: 'patch',
    feature,
    before,
    chunk,
  };
}

export function toDuplicateOperation(feature: GeoJSON.Feature, changes: FeatureChanges): DuplicateOperation {
  return {
    type: 'duplicate',
    feature,
    changes,
  };
}

export function toDeleteOperation(feature: GeoJSON.Feature): DeleteOperation {
  return {
    type: 'delete',
    feature,
  };
}

export function toOrderOperation(features: (string | number)[], plan: Plan): OrderOperation {
  return {
    type: 'order',
    features,
    before: plan.features,
  };
}

export function toHistoryOperations(operations: Operation[], state: HistoryOperation[], skip?: boolean): HistoryOperation[] {
  const relevants = state?.filter((hop) => !hop.status);

  if (!relevants?.length) {
    return operations.map((op) => ({ ...op, skip }));
  }

  const result = [...relevants];

  operations.forEach((op) => {
    if (isOrderOperation(op)) {
      result.push(skip ? { ...op, skip } : op);
      return;
    }

    const index = findLastIndex(
      relevants,
      (rel) => isFeatureRelatedOperation(rel) && isFeatureRelatedOperation(op) && op.feature.id === rel.feature.id
    );

    let relevant = index > -1 ? result[index] : undefined;

    if (relevant) {
      if (relevant.type === 'create' && op.type === 'patch') {
        result[index] = skip ? { ...relevant, feature: op.feature, skip } : { ...relevant, feature: op.feature };
      } else if (relevant.type === 'patch' && op.type === 'patch') {
        result[index] = skip ? { ...relevant, feature: op.feature, skip } : { ...relevant, feature: op.feature };
      } else {
        result.push(skip ? { ...op, skip } : op);
      }
    } else {
      result.push(skip ? { ...op, skip } : op);
    }
  });

  return result.filter((op) => !!op);
}

export function toReversibleOperations(hops: HistoryOperation[], state: Operation[][]): Operation[][] {
  const operations = hops.filter((hop) => !hop.skip).map((hop) => omit(hop, 'status', 'skip') as Operation);
  return operations.length ? (state ? [...state, operations] : [operations]) : state;
}

export function convertToUndoOperations(operations: Operation[]): Operation[] {
  return operations.map((operation) => {
    if (operation.type === 'create' || operation.type === 'duplicate') {
      return {
        ...operation,
        type: 'delete',
      };
    }

    if (operation.type === 'delete') {
      return {
        ...operation,
        type: 'restore',
      };
    }

    if (operation.type === 'patch') {
      return {
        ...operation,
        feature: operation.before,
        before: operation.feature,
      };
    }

    if (operation.type === 'order') {
      return {
        ...operation,
        features: operation.before,
        before: operation.features,
      };
    }

    return operation;
  });
}

export function convertToRedoOperations(operations: Operation[]): Operation[] {
  return operations.map((operation) => {
    if (operation.type === 'create' || operation.type === 'duplicate') {
      return {
        ...operation,
        type: 'restore',
      };
    }

    return operation;
  });
}

export function applyOperationsOnFeatures(operations: Operation[], features: GeoJSON.Feature[]) {
  let result = [...features];

  operations.forEach((op) => {
    if (op.type === 'create' || op.type === 'duplicate' || op.type === 'restore') {
      result.push(op.feature);
    }

    if (op.type === 'patch') {
      const index = result.findIndex((f) => f.id === op.feature.id);

      if (index > -1) {
        result[index] = op.feature;
      }
    }

    if (op.type === 'delete') {
      result = result.filter((f) => f.id !== op.feature.id);
    }
  });

  return result;
}

export function applyOperationsOnOrder(operations: Operation[], features: (string | number)[]) {
  let result = [...features];

  operations.forEach((op) => {
    if (op.type === 'order') {
      result = [...op.features];
    }
  });

  return result;
}

function isFeatureRelatedOperation(operation: Operation): operation is FeatureRelatedOperation {
  return ['create', 'duplicate', 'patch', 'delete', 'restore'].includes(operation.type);
}

function isOrderOperation(operation: Operation): operation is OrderOperation {
  return operation.type === 'order';
}
