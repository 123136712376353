<la-header></la-header>

<section>
  @if(!isActivationRequired) {
  <div class="content">
    <h1 class="title">Sign up to start mapping for free</h1>
    <p>
      Already signed up? <a [routerLink]="['/login']"><strong>Log in</strong></a>
    </p>

    <form [formGroup]="form">
      <div class="grid name">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>First name</mat-label>
          <input type="text" placeholder="First name" matInput formControlName="firstName" maxLength="64" />
          @if (form.controls['firstName'].invalid) {
          <mat-error>Please enter a valid name with at least one character</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Last name</mat-label>
          <input type="text" placeholder="Last name" matInput formControlName="lastName" required maxLength="64" />
          @if (form.controls['lastName'].invalid) {
          <mat-error>Please enter a valid name with at least one character</mat-error>
          }
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Email address</mat-label>
        <input type="email" placeholder="Email" matInput formControlName="email" placeholder="yours@example.com" required maxLength="128" />
        @if (form.controls['email'].hasError('exists')) {
        <mat-error>Email address already used</mat-error>
        } @else if(form.controls['email'].invalid) {
        <mat-error>Please enter a valid email address</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Password</mat-label>
        <input
          type="password"
          placeholder="Min. 8 characters, 1 number and 1 special"
          matInput
          formControlName="password"
          required
          maxLength="64"
          [type]="hide ? 'password' : 'text'"
        />
        @if (form.controls['password'].invalid) {
        <mat-error>Please enter a valid password. Min. 8 characters including 1 special and 1 number</mat-error>
        }
        <mat-icon matSuffix (click)="toggleVisibility()">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>

      <div class="grid phone">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Dialling Code</mat-label>
          <mat-select placeholder="Dialling Code" formControlName="countryCode">
            <mat-option *ngFor="let code of COUNTRY_CODES | keyvalue : valueAscOrder" [value]="code.value"> +{{ code.value }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Phone number</mat-label>
          <input type="tel" placeholder="Phone number" matInput formControlName="phoneNumber" autocomplete="off" maxLength="20" required />
          @if (form.controls['phoneNumber'].invalid) {<mat-error>Please enter a valid phone number</mat-error>}
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Sector</mat-label>
        <mat-select placeholder="Sector" formControlName="sector">
          <mat-option *ngFor="let sector of sectors$ | async" [value]="sector.id">{{ sector.name }}</mat-option>
        </mat-select>
        @if (form.controls['sector'].invalid) {<mat-error>Please choose a sector</mat-error>}
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Organisation type</mat-label>
        <mat-select placeholder="Organisation type" formControlName="organisationType">
          <mat-option *ngFor="let organisationType of organisationTypes$ | async" [value]="organisationType.id">{{
            organisationType.name
          }}</mat-option>
        </mat-select>
        @if (form.controls['organisationType'].invalid) {<mat-error>Please choose an organisation type</mat-error>}
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Referral code (Optional)</mat-label>
        <input type="text" placeholder="Referral code" matInput formControlName="referralCode" maxLength="32" />
        @if (form.controls['referralCode'].invalid) {<mat-error>Invalid referral code </mat-error>}
      </mat-form-field>

      <mat-checkbox formControlName="policiesCheckbox" required>
        <span class="label"
          >By signing up, you agree to our <a target="_blank" [href]="licenceUrl"><strong> user terms of service</strong></a
          >,
          <a target="_blank" [href]="privacyPolicyUrl"><strong> privacy policy</strong></a>
          and
          <a target="_blank" [href]="termsOfUseUrl"><strong> terms of use</strong></a
          >.
        </span>
      </mat-checkbox>

      <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid || (loading$ | async)" (click)="register()">
        Sign up
      </button>
      @if (loading$ | async){<mat-spinner diameter="20"></mat-spinner>}
    </form>
  </div>
  } @else {<la-activation-required [email]="form.get('email').value"></la-activation-required>}
</section>

<la-footer></la-footer>
