import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() border = true;
  @Input() mode: 'spinner' | 'pulsing-logo' = 'spinner';
}
