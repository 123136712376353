import { Injectable } from '@angular/core';
import { MapsApiService, catchAppError } from '@core/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { limitsActions } from '../actions/limits.actions';

@Injectable()
export class LimitsEffects {
  checkMapsLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(limitsActions.fetchMapsBalance),
      switchMap(() =>
        this.mapsApi.balance().pipe(
          map((data) => limitsActions.fetchMapsBalanceSuccess({ data })),
          catchAppError((error) => limitsActions.fetchMapsBalanceFail({ error }))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private mapsApi: MapsApiService
  ) {}
}
