import { Directive, ElementRef, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[laScrollable]',
})
export class ScrollableDirective {
  @Input() threshold = 1;
  @Output() scrollPosition = new EventEmitter();

  constructor(public el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  public onScroll(event: Event) {
    const top = (event.target as HTMLElement).scrollTop;
    const height = this.el.nativeElement.scrollHeight;
    const offset = this.el.nativeElement.offsetHeight;

    if (top > height - offset - this.threshold && height >= offset - 14) {
      this.scrollPosition.emit('bottom');
    }
  }
}
