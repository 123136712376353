import { createActionGroup, props } from '@ngrx/store';

export const hydrationActions = createActionGroup({
  source: 'Hydration',
  events: {
    Hydrate: props<{ persistedKeys: { key: string[]; sections: string[] }[] }>(),
    'Hydrate Success': props<{ element: { key: string[]; sections: string[] }; newState: any; userId?: string }>(),
    'Hydrate Fail': props<{ element: { key: string[]; sections: string[] }; userId?: string }>(),
  },
});
