import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';

import { Team } from '@core/api';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'la-team-chips',
  templateUrl: './team-chips.component.html',
  styleUrls: ['./team-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamChipsComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() teams: Team[] = [];
  @Input() showRole?: boolean;

  public teamsToRender: Team[] = [];
  public teamsForMore: Team[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    setTimeout(() => this.detect());

    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(() => this.detect());
  }

  ngOnChanges() {
    this.detect();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  detect() {
    const containerWidth = this.el.nativeElement.clientWidth - 70;
    let calculatedWidth = 0;

    this.teamsToRender = [];
    this.teamsForMore = [];

    this.teams.forEach((team) => {
      const teamWidth = this.computeWidth(this.showRole ? team.name + ` (${team.role})` : team.name) + 38;
      calculatedWidth = calculatedWidth + teamWidth;

      if (containerWidth > calculatedWidth) {
        this.teamsToRender.push(team);
      } else {
        this.teamsForMore.push(team);
      }
    });

    this.cd.markForCheck();
  }

  computeWidth(text: string) {
    const div = document.createElement('div');
    div.style.display = 'inline-block';
    div.style.font = '14px Roboto';
    div.innerHTML = text;

    document.body.appendChild(div);
    const width = div.clientWidth;
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }

    return width;
  }
}
