import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  install() {
    this.iconRegistry.addSvgIcon('la-point', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/point.svg'));
    this.iconRegistry.addSvgIcon('la-line', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/line.svg'));
    this.iconRegistry.addSvgIcon('la-polygon', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/polygon.svg'));
    this.iconRegistry.addSvgIcon('la-rectangle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rectangle.svg'));
    this.iconRegistry.addSvgIcon('la-circle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/circle.svg'));
    this.iconRegistry.addSvgIcon('la-text_point', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/text.svg'));
    this.iconRegistry.addSvgIcon('la-arrow_line', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow.svg'));
    this.iconRegistry.addSvgIcon('la-north_arrow', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/north-arrow.svg'));
    this.iconRegistry.addSvgIcon('la-logo_green', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo-green.svg'));
    this.iconRegistry.addSvgIcon('la-image', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/image.svg'));
  }
}
