import { createAction, props } from '@ngrx/store';

import { ToolboxWorkflow } from '@core/api';
import { apiActionFactory, apiActionFactoryNoProps } from '@core/store';

export const fetchToolboxWorkflows = createAction('[Map/Toolbox] Fetch Toolbox Workflows');
export const [fetchToolboxWorkflowsSuccess, fetchToolboxWorkflowsFail] = apiActionFactory<{ workflows: ToolboxWorkflow[] }>(
  '[Map/Toolbox/API] Fetch Toolbox Workflows'
);

export const runToolboxWorkflow = createAction(
  '[Map/Toolbox] Run Toolbox Workflow',
  props<{ workflowId: string; planId: string; parameters?: { [key: string]: boolean | undefined }[] }>()
);
export const [runToolboxWorkflowSuccess, runToolboxWorkflowFail] = apiActionFactoryNoProps('[Map/Toolbox/API] Run Toolbox Workflow');
export const closeRunToolboxDialog = createAction('[Map/Toolbox] Close Run Toolbox Dialog', props<{ workflow: string; planId: string }>());
