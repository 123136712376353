import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfigApiService,
  MapsApiService,
  Organisation,
  OrganizationApiService,
  Region,
  Subscription,
  Team,
  Token,
  User,
  UserApiService,
  catchAppError,
} from '@core/api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { GtagService, IntercomService, SocketService } from '@core/providers';
import { initActions } from '../actions/init.actions';
import { paymentApiActions } from '../actions/payment.actions';
import { userActions, userApiActions } from '../actions/user.actions';
import { APP_STATES } from '../enums/app.enum';
import { AppComplete } from '../models/app.models';
import { TokenService } from '../services/token.service';
import { limitsActions } from '../actions/limits.actions';

@Injectable()
export class InitEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initActions.appInit),
      switchMap(() => this.verify().pipe(map((data) => initActions.appComplete(data)))),
      catchAppError((error) => initActions.appError({ error }))
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login),
      switchMap(({ email, password }) =>
        this.userApi.login(email, password).pipe(
          tap((token) => this.token.set(token)),
          switchMap(() =>
            this.verify(true).pipe(
              map((data) => userApiActions.loginSuccess(data)),
              tap(({ mapId }) => {
                const storedLocation = localStorage.getItem('last.location');

                if (storedLocation) {
                  localStorage.removeItem('last.location');
                  this.router.navigateByUrl(storedLocation);
                } else if (mapId) {
                  this.router.navigate(['/map', mapId]);
                } else {
                  this.router.navigate(['/maps']);
                }
              })
            )
          ),
          catchAppError((error) => userApiActions.loginFail({ error }))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.logout),
      map(() => this.token.token),
      filter((token): token is Token => !!token?.access_token),
      switchMap((token) =>
        this.userApi.logout(token.refresh_token).pipe(
          map(() => userApiActions.logoutSuccess()),
          catchError(() => of(userApiActions.logoutSuccess()))
        )
      )
    )
  );

  initPaymentCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initActions.appComplete, userApiActions.loginSuccess),
      filter(({ status }) => status === APP_STATES.USER),
      switchMap(() => this.fetchPaymentMethodValidity().pipe(map((data) => paymentApiActions.initPayment(data))))
    )
  );

  initMapsLimitsCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initActions.appComplete, userApiActions.loginSuccess),
      filter(({ status }) => status === APP_STATES.USER),
      map(() => limitsActions.fetchMapsBalance())
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userApiActions.logoutSuccess),
        tap(() => {
          this.token.remove();
          this.socket.disconnect();
          this.intercom.shutdown();
          this.gtag.setUser(undefined);
        })
      ),
    { dispatch: false }
  );

  bootProviders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initActions.appComplete, userApiActions.loginSuccess),
        filter(({ user }) => !!user),
        tap(({ user, organisation, config }) => {
          if (user && config?.sectors) {
            this.socket.connect(user);
            this.intercom.boot(user, organisation, config.sectors);
          }
        }),
        tap((data) => this.setGtagUserParams(data))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private token: TokenService,
    private userApi: UserApiService,
    private organisationApi: OrganizationApiService,
    private configApi: ConfigApiService,
    private mapsApi: MapsApiService,
    private socket: SocketService,
    private intercom: IntercomService,
    private gtag: GtagService
  ) {}

  private verify(login = false): Observable<AppComplete> {
    if (this.token.token) {
      return combineLatest([
        this.fetchUserConfig(),
        this.userApi.fetch().pipe(
          switchMap((user) =>
            combineLatest([this.fetchSubscription(), this.fetchOrganisation(), this.fetchLayerConfig()]).pipe(
              exhaustMap(([subscription, { organisation, teams }, config]) =>
                this.createFirstMapIfNeeded(user, login, config.regions).pipe(
                  map((mapId) => ({ user, subscription, organisation, teams, config, mapId }))
                )
              )
            )
          ),
          map((data) => ({ status: APP_STATES.USER, ...data }))
        ),
      ]).pipe(map(([userConfig, data]) => ({ ...data, config: { ...data.config, ...userConfig } })));
    }

    return this.fetchUserConfig().pipe(map((config) => ({ status: APP_STATES.CLIENT, config })));
  }

  private fetchOrganisation(): Observable<{ organisation: Organisation; teams?: Team[] }> {
    return this.organisationApi.fetchOrganisation().pipe(
      switchMap((organisation) => {
        if (organisation) {
          return this.organisationApi.fetchTeams().pipe(map((teams) => ({ organisation, teams })));
        }
        return of({ organisation });
      })
    );
  }

  private fetchSubscription() {
    return this.userApi.fetchSubscription();
  }

  private fetchLayerConfig() {
    return combineLatest([
      this.configApi.getLayers(),
      this.configApi.getProjectTemplates(),
      this.configApi.getSubscriptionsPrices(),
      this.configApi.getPrintBorders(),
      this.configApi.fetchRegions(),
    ]).pipe(
      map(([{ basemaps, layers }, templates, subscriptionsPrices, printBorders, regions]) => ({
        baseLayers: basemaps,
        dataLayers: layers,
        templates,
        subscriptionsPrices,
        printBorders,
        regions,
      }))
    );
  }

  private fetchUserConfig() {
    return combineLatest([this.configApi.getSectors(true), this.configApi.getOrganisationTypes()]).pipe(
      map(([sectors, organisationTypes]) => ({
        sectors,
        organisationTypes,
      }))
    );
  }

  private fetchPaymentMethodValidity() {
    return this.userApi.getPaymentMethodValidity().pipe(
      map(() => ({ valid: true })),
      catchError(() => of({ valid: false }))
    );
  }

  private createFirstMapIfNeeded(user: User, login: boolean, regions: Region[]) {
    if (login && user.subscription === Subscription.FREE) {
      return this.mapsApi.listOwnedMaps({}).pipe(
        switchMap((maps) => {
          if (maps.length > 0) {
            return of(null);
          }
          const defaultRegion = regions?.find((region) => region?.default === true)?.id;
          return this.mapsApi.create('New Map', [], defaultRegion).pipe(map((response) => response.id));
        })
      );
    }

    return of(null);
  }

  private setGtagUserParams(data?: AppComplete) {
    if (data && data.user && data.config) {
      let sector = data.config.sectors?.find((sector) => sector?.id === data.user?.sector)?.name || '';
      let orgType = data.config.organisationTypes?.find((orgType) => orgType?.id === data.user?.organisationType)?.name || '';
      let organisationName = data.organisation?.name;

      return this.gtag.setUser({
        user_id: data.user.id,
        org_type: orgType,
        subscription_tier: data.user.subscription.toLowerCase(),
        sector,
        ...(organisationName && { organisation_name: organisationName }),
      });
    }

    return this.gtag.setUser(undefined);
  }
}
