import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LINE_TYPES } from '../../constants/line-types.const';
import { LineType } from '../../models/line-type.model';

@Component({
  selector: 'la-line-type-dropdown',
  templateUrl: './line-type-dropdown.component.html',
  styleUrls: ['./line-type-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LineTypeDropdownComponent),
      multi: true,
    },
  ],
})
export class LineTypeDropdownComponent implements ControlValueAccessor {
  @Input() label = '';
  lineTypes = LINE_TYPES;
  selectedType?: LineType;

  constructor(private cd: ChangeDetectorRef) {}

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: number[]) {
    const presetType = this.lineTypes.find((type) => type.value[0] === input[0] && type.value[1] === input[1]);
    if (presetType) {
      this.selectedType = presetType;
    } else {
      this.selectedType = this.lineTypes[0];
    }

    this.onChange(this.selectedType);
  }

  onSelect(value: LineType) {
    this.selectedType = value;
    this.onChange(this.selectedType.value);

    this.cd.markForCheck();
  }

  typePreview(id: string) {
    return `assets/layout/${id}.png`;
  }
}
