const transformSelectedColorFromHexToRgb = (color: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const checkIfSelectedColorIsBright = (color: string) => {
  let isBright;
  let selectedColorRgb;
  selectedColorRgb = transformSelectedColorFromHexToRgb(color);
  const luminance = 0.2126 * selectedColorRgb?.r + 0.7152 * selectedColorRgb.g + 0.0722 * selectedColorRgb.b;

  isBright = luminance > 127.5;
  return isBright;
};

export const setTextColor = (color?: string) => {
  if (!color || (color !== '' && checkIfSelectedColorIsBright(color))) {
    return '#000000';
  } else {
    return '#ffffff';
  }
};
