<div *ngIf="label" class="label">{{ label }}</div>

<div class="preview">
  <div class="angle" [style.rotate]="sliderValue + 'deg'"></div>
</div>

<section class="la-filter-field">
  <mat-slider [step]="step" [min]="min" [max]="max">
    <input matSliderThumb [(ngModel)]="sliderValue" (input)="inputChange()" />
  </mat-slider>

  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="la-narrow-input">
    <input
      matInput
      class="input-field"
      type="number"
      min="{{ min }}"
      max="{{ max }}"
      step="{{ step }}"
      [(ngModel)]="sliderValue"
      (input)="inputChange()"
    />
  </mat-form-field>
</section>
