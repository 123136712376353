import { first, get, uniq } from 'lodash';

import { FlattenedPlanNode, PlanNode } from '@core/api';
import { TreeNode } from '../../models/plans-tree.model';

export function getTreeNodes(chunks: string[], tree: TreeNode[]): TreeNode[] {
  const needle = chunks.shift();
  const node = tree.find((el) => el.id.toString() === needle.toString());

  if (chunks.length > 0 && node?.content) {
    return [node, ...getTreeNodes(chunks, node.content)];
  }

  return [node];
}

export function getNodeById(plans: PlanNode[], id: string) {
  let found: PlanNode;
  plans?.forEach((plan) => {
    if (plan.id === id) {
      found = plan;
    }
    if (!found && plan.type === 'folder' && plan.content?.length) {
      found = getNodeById(plan.content, id);
    }
  });
  return found;
}

export function definePaths(features: { properties: GeoJSON.GeoJsonProperties }[]): string[] {
  return uniq(
    features.map((f) => get(f, ['properties', 'sheetId'], '') + '|' + get(f, ['properties', 'parcelId'], '')).filter((p) => p !== '|')
  );
}

export function getParentIds(id: string, plans: PlanNode[], parent?: PlanNode) {
  let ids: string[] = [];

  if (plans.some((plan) => plan.id === id)) {
    return parent ? [parent.id] : [];
  }

  plans
    .filter((plan) => plan.type === 'folder' && plan.content?.length)
    .forEach((plan) => {
      const result = getParentIds(id, plan.content, plan);

      if (result?.length) {
        ids = parent ? [parent.id, ...result] : result;
      }
    });

  return ids;
}
export function findPlanNode(path: string, plans: PlanNode[]): PlanNode {
  const chunks = path.split('/');

  if (chunks.length > 1) {
    const parentId = chunks.shift();
    const parentPlan = plans.find((plan) => plan.id === parentId);

    return findPlanNode(chunks.join('/'), parentPlan.content);
  }

  return plans.find((plan) => plan.id === chunks[0]);
}

export function findAndRemovePlanNode(path: string, plans: PlanNode[]): PlanNode {
  const chunks = path.split('/');

  if (chunks.length > 1) {
    const parentId = chunks.shift();
    const parentPlan = plans.find((plan) => plan.id === parentId);

    return findAndRemovePlanNode(chunks.join('/'), parentPlan.content);
  }

  return first(
    plans.splice(
      plans.findIndex((plan) => plan.id === chunks[0]),
      1
    )
  );
}

export function insertIntoPlanNode(path: string, nodes: PlanNode[], plans: PlanNode[]): void {
  const chunks = path.split('/');

  if (chunks.length > 1) {
    const parentId = chunks.shift();
    const parentPlan = plans.find((plan) => plan.id === parentId);

    return insertIntoPlanNode(chunks.join('/'), nodes, parentPlan.content);
  }

  const plan = plans.find((plan) => plan.id === chunks[0]);

  if (!plan) {
    throw new Error('Invalid Reorder Operation: Target node not found ' + chunks[0]);
  }

  plan.content = plan.content ? [...plan.content, ...nodes] : nodes;

  return undefined;
}

export function insertBeforePlanNode(path: string, nodes: PlanNode[], plans: PlanNode[]) {
  const chunks = path.split('/');

  if (chunks.length > 1) {
    const parentId = chunks.shift();
    const parentPlan = plans.find((plan) => plan.id === parentId);

    return insertBeforePlanNode(chunks.join('/'), nodes, parentPlan.content);
  }

  plans.splice(
    plans.findIndex((plan) => plan.id === chunks[0]),
    0,
    ...nodes
  );

  return undefined;
}

export function insertAfterPlanNode(path: string, nodes: PlanNode[], plans: PlanNode[]) {
  const chunks = path.split('/');

  if (chunks.length > 1) {
    const parentId = chunks.shift();
    const parentPlan = plans.find((plan) => plan.id === parentId);

    return insertAfterPlanNode(chunks.join('/'), nodes, parentPlan.content);
  }

  plans.splice(plans.findIndex((plan) => plan.id === chunks[0]) + 1, 0, ...nodes);

  return undefined;
}

export function findPlanAndGetVisibility(plans: PlanNode[], id: string) {
  return plans?.some((plan) => {
    if (plan.id === id) {
      return plan.visible;
    }
    if (plan.type === 'folder' && plan.content?.length) {
      return findPlanAndGetVisibility(plan.content, id);
    }
    return false;
  });
}

export function flattenPlans(tree: PlanNode[]) {
  const result: FlattenedPlanNode[] = [];

  tree?.forEach((node) => {
    if (node.type === 'folder') {
      if (node.content?.length) {
        result.push(...flattenPlans(node.content));
      }
    } else if (node.type === 'project') {
      result.push(node as FlattenedPlanNode);
    }
  });

  return result;
}

export function removePlan(plans: PlanNode[], id: string) {
  const tree = [];

  plans?.forEach((plan) => {
    if (plan.id !== id) {
      if (plan.type === 'folder' && plan.content?.length) {
        const content = removePlan(plan.content, id);
        tree.push({ ...plan, content });
      } else {
        tree.push(plan);
      }
    }
  });

  return tree;
}

export function updatePlan(plans: PlanNode[], id: string, data: Partial<PlanNode>) {
  return plans.map((plan) => {
    if (plan.id !== id) {
      if (plan.type === 'folder' && plan.content?.length) {
        return { ...plan, content: updatePlan(plan.content, id, data) };
      } else {
        return plan;
      }
    } else {
      return { ...plan, ...data };
    }
  });
}

export function compareParentPaths(p1: string, p2: string) {
  const c1 = p1.split('/');
  const c2 = p2.split('/');

  if (c1.length !== c2.length) {
    return false;
  }

  return (c1.length === 1 && c2.length === 1) || c1[c1.length - 2] === c2[c2.length - 2];
}
