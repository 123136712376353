<mat-card *ngIf="border; else borderless">
  <header>
    <ng-content select="[la-loader-header]"></ng-content>
  </header>

  <mat-card-content>
    <mat-progress-spinner *ngIf="mode === 'spinner'" mode="indeterminate"></mat-progress-spinner>

    <div *ngIf="mode === 'pulsing-logo'" class="pulse">
      <img src="../../../../../assets/layout/short-logo-green.svg" />
    </div>
  </mat-card-content>

  <footer>
    <ng-content select="[la-loader-footer]"></ng-content>
  </footer>
</mat-card>

<ng-template #borderless>
  <header>
    <ng-content select="[la-loader-header]"></ng-content>
  </header>

  <mat-progress-spinner *ngIf="mode === 'spinner'" mode="indeterminate"></mat-progress-spinner>

  <div *ngIf="mode === 'pulsing-logo'" class="pulse">
    <img src="../../../../../assets/layout/short-logo-green.svg" />
  </div>

  <footer>
    <ng-content select="[la-loader-footer]"></ng-content>
  </footer>
</ng-template>
