import { createReducer, on } from '@ngrx/store';

import { LandRegistryOwnership } from '@core/api';
import { ActionUIState } from '@core/store';
import * as dataLayersApiActions from '../actions/api/data-layers-api.actions';
import * as landRegistryOwnershipEffectActions from '../actions/land-registry-ownership-effect.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';

export const featureKey = 'landRegistryOwnership';

export interface LandRegistryOwnershipState extends ActionUIState {
  ownership: {
    [key: string]: LandRegistryOwnership;
  };
}

export const initialState: LandRegistryOwnershipState = {
  status: 'INIT',
  ownership: {},
};

export const reducer = createReducer<LandRegistryOwnershipState>(
  initialState,
  on(rightHandPanelActions.fetchLandRegistryOwnership, (state) => ({
    ...state,
    status: 'LOADING',
  })),
  on(landRegistryOwnershipEffectActions.fetchLandRegistryOwnershipDone, (state) => ({
    ...state,
    status: 'READY',
  })),
  on(dataLayersApiActions.fetchLandRegistryOwnershipSuccess, (state, { ownership, titleNumber }) => ({
    ...state,
    status: 'READY',
    ownership: {
      ...state.ownership,
      [titleNumber]: ownership,
    },
  })),
  on(dataLayersApiActions.fetchLandRegistryOwnershipFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  }))
);
