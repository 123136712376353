<div class="title">
  <label>{{ label }}</label>
  <mat-icon
    *ngIf="info || icon"
    mat-list-icon
    matTooltipPosition="right"
    [matTooltip]="info"
    [color]="iconColor"
    (click)="clicked.emit()"
    >{{ icon ? icon : 'info' }}</mat-icon
  >
</div>
<ng-content></ng-content>
