import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { userActions, userApiActions } from '../actions/user.actions';
import { APP_STATES } from '../enums/app.enum';

export const featureKey = 'init';

export type InitState = APP_STATES;

export const initialState: InitState = APP_STATES.IDLE;

export const reducer = createReducer<InitState>(
  initialState,
  on(
    userApiActions.logoutSuccess,
    userApiActions.logoutFail,
    userApiActions.loginFail,
    userApiActions.tokenExpired,
    () => APP_STATES.CLIENT
  ),

  on(initActions.appInit, userActions.login, () => APP_STATES.IDLE),
  on(initActions.appComplete, userApiActions.loginSuccess, (state, { status }) => status),
  on(initActions.appError, () => APP_STATES.ERROR)
);
