import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAppStatus } from '../selectors/init.selectors';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { APP_STATES } from '../enums/app.enum';
import { getUserHasNatureReportingAccess } from '../selectors/organisation.selectors';

@Injectable({ providedIn: 'root' })
export class NatureReportingGuard {
  constructor(
    public router: Router,
    private store: Store
  ) {}

  canActivate() {
    return this.store.select(getAppStatus).pipe(
      skipWhile((status) => status === APP_STATES.IDLE),
      take(1),
      switchMap(() => this.store.select(getUserHasNatureReportingAccess)),
      map((enabled) => {
        if (!enabled) {
          this.router.navigate(['/']);
        }
        return true;
      })
    );
  }
}
