import { createActionGroup, props } from '@ngrx/store';

import { AppError, SubscriptionDetails } from '@core/api';

export const subscriptionApiActions = createActionGroup({
  source: 'Subscription/API',
  events: {
    'Fetch Subscription Success': props<{ subscription: SubscriptionDetails }>(),
    'Fetch Subscription Fail': props<{ error: AppError }>(),
  },
});
