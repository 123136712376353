import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'laSeparator' })
export class SeparatorPipe implements PipeTransform {
  transform(value: string | string[] | string[][] | undefined, separator: string) {
    if (typeof value === 'string') {
      return value;
    } else if (value === undefined) {
      return '';
    } else {
      return Array.prototype.join.call(value, separator);
    }
  }
}
