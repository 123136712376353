import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { ArchivedLayer, ArchivedLayersParams } from '@core/api';
import { initActions, ListUIState } from '@core/store';
import * as plansApiActions from '../actions/api/plans-api.actions';
import * as leftHandPanelActions from '../actions/left-hand-panel.actions';
import { leaveMap } from '../actions/map-page.actions';

export const featureKey = 'archivedPlans';

export interface ArchivedPlansState extends ListUIState<ArchivedLayer, ArchivedLayersParams> {}

export const initialState: ArchivedPlansState = {
  status: 'INIT',
  params: { page: 0, size: 15 },
};

export const reducer = createReducer<ArchivedPlansState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, leftHandPanelActions.closeArchivedPlansDialog, () => cloneDeep(initialState)),

  on(leftHandPanelActions.openArchivedPlansDialog, leftHandPanelActions.fetchArchivedPlans, (state) => ({
    ...state,
    status: 'LOADING',
  })),
  on(plansApiActions.fetchArchivedPlansSuccess, (state, { plans }) => ({
    ...state,
    status: 'READY',
    params: {
      ...state.params,
      page: plans.length === state.params.size ? state.params.page + 1 : state.params.page,
    },
    more: plans.length === state.params.size,
    data: state.params.page === 0 ? plans : [...state.data, ...plans],
  })),
  on(plansApiActions.fetchArchivedPlansFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  })),

  on(leftHandPanelActions.setArchivedPlansParams, (state, { params }) => ({
    ...state,
    params: {
      ...initialState.params,
      ...params,
    },
  })),
  on(plansApiActions.restorePlanSuccess, (state, { plans }) => ({
    ...state,
    data: state.data.filter((archivedPlan) => !plans.some((plan) => plan.id === archivedPlan.id)),
  }))
);
