import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNumber } from 'lodash';

@Component({
  selector: 'la-line-bar',
  templateUrl: './line-bar.component.html',
  styleUrls: ['./line-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineBarComponent {
  @Input() min: number = 0;
  @Input() max: number = 1;
  @Input() value?: number;
  @Input() marker?: number;
  @Input() status?: 'INCREASE' | 'DECREASE' | 'NEUTRAL';

  get width() {
    return this.calculatePercentage(this.value);
  }

  get markerPosition() {
    return this.calculatePercentage(this.marker);
  }

  private calculatePercentage(value: number | undefined) {
    if (isNumber(value)) {
      const currentValue = Math.round(value * 100) / 100;

      if (currentValue <= this.max && currentValue >= this.min) {
        return `${((currentValue - this.min) * 100) / (this.max - this.min)}%`;
      }
    }
    return undefined;
  }
}
