import { MapChanges } from '@core/api';
import { apiActionFactory } from '@core/store';

export const [createFolderSuccess, createFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Create Folder');

export const [renameFolderSuccess, renameFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Rename Folder');

export const [deleteFolderSuccess, deleteFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Delete Folder');

export const [showFolderSuccess, showFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Show Folder');

export const [hideFolderSuccess, hideFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Hide Folder');

export const [publishFolderSuccess, publishFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Publish Folder');

export const [unpublishFolderSuccess, unpublishFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Unpublish Folder');

export const [lockFolderSuccess, lockFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Lock Folder');

export const [unlockFolderSuccess, unlockFolderFail] = apiActionFactory<MapChanges>('[Folders/API] Unlock Folder');
