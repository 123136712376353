<div>
  <h2>{{ title }}</h2>
  <div class="image" [ngStyle]="{ width: width }">
    <la-image [url]="url" [alt]="title ? title : header ? header : ''" />
  </div>

  <header>{{ header }}</header>

  <ng-content></ng-content>
</div>
