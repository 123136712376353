import { ActionCreatorProps, createAction, NotAllowedCheck, props } from '@ngrx/store';

import { AppError } from '@core/api';

type AppErrorProps = { error: AppError };

export function apiActionFactory<T extends object, P extends AppErrorProps | object = AppErrorProps>(title: string) {
  const success = createAction(`${title} Success`, props<T, T>() as ActionCreatorProps<T> & NotAllowedCheck<T>);
  const fail = createAction(`${title} Fail`, props<P, P>() as ActionCreatorProps<P> & NotAllowedCheck<P>);
  return [success, fail] as [typeof success, typeof fail];
}

export function apiActionFactoryNoProps<P extends AppErrorProps | object = AppErrorProps>(title: string) {
  const success = createAction(`${title} Success`);
  const fail = createAction(`${title} Fail`, props<P, P>() as ActionCreatorProps<P> & NotAllowedCheck<P>);
  return [success, fail] as [typeof success, typeof fail];
}
