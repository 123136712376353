import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { BuyDataWorkflowParameters, DataPurchase, DataWorkflow, DataWorkflowPriceDetails } from '@core/api';
import { ActionUIState, initActions, StaticListUIState } from '@core/store';
import { setWorkflowExtent } from '../actions/map-container.actions';
import { leaveMap } from '../actions/map-page.actions';
import {
  buyWorkflow,
  buyWorkflowFail,
  buyWorkflowSuccess,
  closeWorkflowSetupPanel,
  emptyPrice,
  fetchPrice,
  fetchPriceFail,
  fetchPriceSuccess,
  fetchPurchases,
  fetchPurchasesFail,
  fetchPurchasesSuccess,
  fetchWorkflows,
  fetchWorkflowsFail,
  fetchWorkflowsSuccess,
  paymentMethodUpdated,
  searchPurchases,
  selectWorkflow,
  setWorkflowParameters,
} from '../actions/workflow.actions';

export const featureKey = 'workflowSetup';

interface PriceAction extends ActionUIState, DataWorkflowPriceDetails {}

interface PurchasesFilterAction extends ActionUIState {
  owned?: DataPurchase[];
  shared?: DataPurchase[];
  workflowAcronym?: string;
  excludeExpired?: boolean;
  search?: string;
  size: number;
}

export interface BuyDataState extends StaticListUIState<DataWorkflow> {
  selectedWorkflowId?: string;

  bbox?: [number, number, number, number];
  parameters?: BuyDataWorkflowParameters;

  priceAction: PriceAction;
  purchaseAction: ActionUIState;

  purchasesFilterAction: PurchasesFilterAction;
}

export const initialState: BuyDataState = {
  status: 'INIT',

  priceAction: {
    status: 'INIT',
  },

  purchaseAction: {
    status: 'INIT',
  },

  purchasesFilterAction: {
    status: 'INIT',
    size: 100,
    excludeExpired: true,
  },
};

export const reducer = createReducer<BuyDataState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, leaveMap, () => cloneDeep(initialState)),

  on(fetchWorkflows, (state) => ({
    ...state,
    status: 'LOADING',
  })),
  on(fetchWorkflowsSuccess, (state, { workflows }) => ({
    ...state,
    status: 'READY',
    data: workflows,
  })),
  on(fetchWorkflowsFail, (state, { error }) => ({
    ...state,
    status: 'ERROR',
    error,
  })),

  on(selectWorkflow, (state, { workflowId }) => ({
    ...state,
    selectedWorkflowId: workflowId,
  })),

  on(setWorkflowParameters, (state, { parameters }) => ({
    ...state,
    parameters,
  })),
  on(setWorkflowExtent, (state, { bbox }) => ({
    ...state,
    bbox: [...bbox],
  })),

  on(fetchPrice, (state) => ({
    ...state,
    priceAction: {
      ...state.priceAction,
      status: 'LOADING',
    },
  })),
  on(fetchPriceSuccess, (state, { details }) => ({
    ...state,
    priceAction: {
      ...state.priceAction,
      status: 'READY',
      ...details,
    },
  })),
  on(fetchPriceFail, (state, { error }) => ({
    ...state,
    priceAction: {
      ...state.priceAction,
      status: 'ERROR',
      error,
    },
  })),
  on(emptyPrice, (state) => ({
    ...state,
    priceAction: {
      ...state.priceAction,
      status: 'READY',
      area: 0,
      geography: {},
      priceWithoutVat: 0,
      priceWithVat: 0,
      vatAmount: 0,
      vatPercentage: 20,
    },
  })),

  on(buyWorkflow, (state) => ({
    ...state,
    purchaseAction: {
      ...state.purchaseAction,
      status: 'LOADING',
    },
  })),
  on(buyWorkflowSuccess, (state) => ({
    ...state,
    purchaseAction: {
      ...state.purchaseAction,
      status: 'READY',
    },
  })),
  on(buyWorkflowFail, (state, { error }) => ({
    ...state,
    purchaseAction: {
      ...state.purchaseAction,
      status: 'ERROR',
      error,
    },
  })),
  on(paymentMethodUpdated, (state) => ({
    ...state,
    purchaseAction: {
      ...initialState.purchaseAction,
    },
  })),

  on(fetchPurchases, (state, { acronym }) => ({
    ...state,
    purchasesFilterAction: {
      ...state.purchasesFilterAction,
      status: 'LOADING',
      workflowAcronym: acronym,
    },
  })),
  on(fetchPurchasesSuccess, (state, { owned, shared }) => ({
    ...state,
    purchasesFilterAction: {
      ...state.purchasesFilterAction,
      status: 'READY',
      owned,
      shared,
    },
  })),
  on(fetchPurchasesFail, (state, { error }) => ({
    ...state,
    purchasesFilterAction: {
      ...state.purchasesFilterAction,
      status: 'ERROR',
      error,
    },
  })),
  on(searchPurchases, (state, { search }) => ({
    ...state,
    purchasesFilterAction: {
      ...state.purchasesFilterAction,
      search,
    },
  })),
  on(closeWorkflowSetupPanel, (state) => ({
    ...state,
    selectedWorkflowId: cloneDeep(initialState.selectedWorkflowId),
    bbox: cloneDeep(initialState.bbox),
    parameters: cloneDeep(initialState.parameters),
  }))
);
