import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppError } from '@core/api';

@Component({
  selector: 'la-error-section',
  templateUrl: './error-section.component.html',
  styleUrls: ['./error-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorSectionComponent {
  @Input() error?: AppError;
  @Input() title?: string;
}
