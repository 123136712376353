import { MonoTypeOperatorFunction, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface SerializedResource<T> {
  id: string;
  attributes: T;
}

export interface SerializedResponse<T> {
  data: SerializedResource<T> | SerializedResource<T>[];
}

export function deserialise<T extends object>(): MonoTypeOperatorFunction<T> {
  return (input$) =>
    input$.pipe(
      switchMap((response) => {
        if (isSerialized(response)) {
          if (Array.isArray(response.data)) {
            return of(response.data.map((element) => resource(element)) as T);
          } else {
            return of(resource(response.data));
          }
        }

        return of(response as T);
      })
    );
}

function isSerialized<T extends object>(response: T): response is T & SerializedResponse<T> {
  return response?.hasOwnProperty('data');
}
function resource<T>(data: SerializedResource<T>): T {
  return {
    id: data.id,
    ...data.attributes,
  };
}
