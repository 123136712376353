import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class LinkedInService {
  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig) {
    this.loadLinkedInInsightTag();
  }

  private loadLinkedInInsightTag() {
    if (this.config.linkedIn) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;

      (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
      (window as any)._linkedin_data_partner_ids.push(this.config.linkedIn.partnerId);

      function lintrk(l: any) {
        if (!l) {
          (window as any).lintrk = function (a: any, b: any) {
            (window as any).lintrk.q.push([a, b]);
          };
        }
        const s = document.getElementsByTagName('script')[0];
        const b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        s.parentNode?.insertBefore(b, s);
      }

      lintrk((window as any).lintrk);
    }
  }

  public trackEvent() {
    if (typeof (window as any).lintrk !== 'undefined') {
      (window as any).lintrk('track', {
        conversion_id: this.config.linkedIn?.conversionId,
      });
    }
  }
}
