import { createActionGroup, props } from '@ngrx/store';

import { AppError, Team } from '@core/api';

export const teamsActions = createActionGroup({
  source: 'Teams',
  events: {
    'Create Team': props<{ team: Team }>(),
    'Edit Team': props<{ id: string; name?: string; color?: string; category?: string }>(),
    'Remove Team': props<{ id: string }>(),
  },
});

export const teamsApiActions = createActionGroup({
  source: 'Teams/API',
  events: {
    'Fetch Teams Success': props<{ data: Team[] }>(),
    'Fetch Teams Fail': props<{ error: AppError }>(),
  },
});
