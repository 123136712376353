<mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
  <mat-select
    [formControl]="inputControl"
    (selectionChange)="handleSelectionChange($event)"
    multiple
    [placeholder]="placeholder"
    (click)="clickOnSelect.emit()"
  >
    <mat-select-trigger>
      {{ inputControl.value?.[0]?.name || '' }}
      <span *ngIf="(inputControl.value?.length || 0) > 1" class="additional-selection">
        (+{{ (inputControl.value?.length || 0) - 1 }} {{ inputControl.value?.length === 2 ? 'other' : 'others' }})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let element of elements" [value]="element" class="la-gold-checkbox">
      {{ element.name }}
    </mat-option>
  </mat-select>
  <mat-icon matSuffix *ngIf="icon">{{ icon }}</mat-icon>
</mat-form-field>
