import { Injectable } from '@angular/core';

import { Token } from '@core/api';
import { TOKEN_KEY } from '../constants/token.const';

@Injectable({ providedIn: 'root' })
export class TokenService {
  get token(): Token | null {
    try {
      return localStorage.getItem(TOKEN_KEY) ? JSON.parse(`${localStorage.getItem(TOKEN_KEY)}`) : null;
    } catch (error) {
      if (error instanceof Error) {
        console.warn('Invalid token', error.message);
      }

      return null;
    }
  }

  set(token: Token) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  remove() {
    localStorage.removeItem(TOKEN_KEY);
  }
}
