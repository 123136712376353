import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppError, AppErrorCodes } from '@core/api';

@Component({
  selector: 'la-action-error',
  templateUrl: './action-error.component.html',
  styleUrls: ['./action-error.component.scss'],
})
export class ActionErrorComponent implements OnInit {
  public error?: { title: string; message: string; details?: string };

  constructor(@Inject(MAT_DIALOG_DATA) public data: AppError) {}

  ngOnInit(): void {
    if (this.data.code === AppErrorCodes.NO_INTERNET) {
      this.error = {
        title: 'No internet',
        message: `You're not connected to the internet at the moment, so any changes you make won't be saved. Please check your connection and try again.`,
      };
    } else if (this.data.code === AppErrorCodes.DEPRECATED) {
      this.error = {
        title: 'New version available',
        message: 'Please refresh your browser or clear your cache to ensure you are using the latest version of the Land App',
      };
    } else if (this.data.code === AppErrorCodes.INVALID_VERSION) {
      this.error = {
        title: 'Changes not saved',
        message: `We couldn't save your changes because your map was updated on another device before you reconnected to the internet. Please refresh your page to see the latest version of your map.`,
      };
    } else if (this.data.code === AppErrorCodes.SERVER_ERROR) {
      this.error = {
        title: 'Server error',
        message:
          'We are having trouble connecting to our servers at the moment. If you need urgent assistance, please get in touch with our support team on: support@thelandapp.com',
      };
    } else {
      this.error = { title: 'Error', message: this.data.message, details: this.data.details };
    }
  }
}
