import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { IconService } from '@core/common';
import { initActions } from '@core/store';

@Component({
  selector: 'la-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store,
    private icons: IconService
  ) {}

  ngOnInit() {
    this.store.dispatch(initActions.appInit());
    this.icons.install();
  }
}
