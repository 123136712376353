import { ApexChart, ApexDataLabels, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions, ApexStroke, ApexTooltip } from 'ng-apexcharts';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent {
  @Input() series: ApexNonAxisChartSeries = [];
  @Input() labels: string[] = [];
  @Input() colors: string[] = [];

  options: {
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    legend: ApexLegend;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
  } = {
    chart: {
      type: 'donut',
      height: '250px',
    },
    dataLabels: { enabled: false },
    legend: {
      show: false,
    },
    stroke: { width: 0 },
    tooltip: {
      enabled: true,
      y: {
        formatter: (v) => this.getPercentage(v),
      },
    },
  };

  get maxValueIndex() {
    return this.findMaxIndex(this.series);
  }

  get innerLabel() {
    const maxValue = this.series?.length && this.series[this.maxValueIndex];
    return this.getPercentage(maxValue);
  }

  get innerValue() {
    return this.labels[this.maxValueIndex]?.toString();
  }

  get plotOptions(): ApexPlotOptions {
    return {
      pie: {
        expandOnClick: false,
        donut: {
          size: '85%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: this.innerLabel,
              formatter: () => this.innerValue,
            },
          },
        },
      },
    };
  }

  private getPercentage(value: number) {
    const sum = this.series?.reduce((sum, current) => sum + current, 0);
    const result = (sum > 0 ? value / sum : 0) * 100;
    return (Number.isInteger(result) ? result : result.toFixed(2)) + '%';
  }

  private findMaxIndex(arr: number[]) {
    let maxIndex = 0;

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > arr[maxIndex]) {
        maxIndex = i;
      }
    }

    return maxIndex;
  }
}
