import { Team } from '@core/api';
import { createReducer, on } from '@ngrx/store';
import { initActions } from '../actions/init.actions';
import { teamsActions, teamsApiActions } from '../actions/teams.actions';
import { userApiActions } from '../actions/user.actions';

export const featureKey = 'teams';

export type TeamsState = Team[] | undefined;

export const initialState: TeamsState = undefined;

export const reducer = createReducer<TeamsState>(
  initialState,
  on(initActions.appCleanup, () => initialState),

  on(initActions.appComplete, userApiActions.loginSuccess, (state, { teams }) => (teams ? teams : undefined)),

  on(teamsApiActions.fetchTeamsSuccess, (state, { data }) => data),

  on(teamsActions.createTeam, (state, { team }) => (state ? [...state, team] : [team])),
  on(teamsActions.editTeam, (state, { id, name, color, category }) =>
    state?.map((team) =>
      team.id === id
        ? {
            ...team,
            ...(name && { name }),
            ...(color && { color }),
            ...(category && { category }),
          }
        : team
    )
  ),
  on(teamsActions.removeTeam, (state, { id }) => state?.filter((team) => team.id !== id))
);
