import { createSelector } from '@ngrx/store';
import { getConfigState } from './main.selectors';
import { getIsBetaTester } from './user.selectors';

const OS_BASEMAP_VARIANTS = ['os_road', 'os_light', 'os_road_27700', 'os_light_27700', 'os_road_3857', 'os_light_3857'];
const EXCLUDE_LAYER_GROUPS_FOR_REPORT = ['Organisation', 'Ordnance Survey Master Map'];

export const getBaseLayers = createSelector(getConfigState, ({ baseLayers }) => {
  return Array.from(baseLayers).sort((a, b) => sortBaseLayers(a.id) - sortBaseLayers(b.id));
});

export const getDataLayers = createSelector(getConfigState, ({ dataLayers }) => dataLayers);

export const getPrintLayers = createSelector(getConfigState, ({ printLayers }) => printLayers);

export const getInteractiveLayers = createSelector(getConfigState, ({ interactiveLayers }) => interactiveLayers);

export const getReportDialogDataLayers = createSelector(getConfigState, ({ dataLayers }) =>
  dataLayers.filter((dataLayer) => !EXCLUDE_LAYER_GROUPS_FOR_REPORT.includes(dataLayer.layerGroup || ''))
);

export const getSectors = createSelector(getConfigState, ({ sectors }) => sectors);

export const getProjectTemplates = createSelector(getConfigState, ({ projectTemplates }) => projectTemplates);

export const getSortedProjectTemplates = createSelector(getProjectTemplates, getSectors, (templates, sectors) => {
  const order = sectors?.find((sector) => sector.name === 'Other')?.templates || [];

  templates?.forEach((template) => {
    if (!order.includes(template.id)) {
      order.push(template.id);
    }
  });

  return templates?.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)) || [];
});

export const getEditableProjectTemplates = createSelector(getSortedProjectTemplates, getIsBetaTester, (templates, isBetaTester) =>
  templates.filter((template) => !template.readonly && (template.active || (isBetaTester && template.beta)))
);

export const getSector = (id: string) => createSelector(getSectors, (state) => state?.find((sector) => sector.id === id));

export const getOrganisationTypes = createSelector(getConfigState, ({ organisationTypes }) => organisationTypes);

export const getOrganisationType = (id: string) =>
  createSelector(getOrganisationTypes, (state) => state?.find((orgType) => orgType.id === id));

export const getOSVariants = createSelector(getConfigState, ({ baseLayers }) =>
  baseLayers.filter((layer) => OS_BASEMAP_VARIANTS.includes(layer.id))
);

export const getUserProjectTemplates = createSelector(getConfigState, ({ projectTemplates }) =>
  projectTemplates?.filter((template) => !template.boundary)
);

export const getNumberOfDataLayers = createSelector(getDataLayers, (state) => state?.length || 0);
export const getNumberOfInteractiveLayers = createSelector(getInteractiveLayers, (state) => state?.length || 0);

export const getSubscriptionsPrices = createSelector(getConfigState, ({ subscriptionsPrices }) => subscriptionsPrices);

export const getWorkflowDiscounts = createSelector(getConfigState, ({ subscriptionsPrices }) => subscriptionsPrices?.discounts.workflows);

export const getPrintDiscounts = createSelector(getConfigState, ({ subscriptionsPrices }) => subscriptionsPrices?.discounts.prints);

export const getPrintBorders = createSelector(getConfigState, ({ printBorders }) => printBorders);

export const getRegions = createSelector(getConfigState, ({ regions }) => regions);

export const getDefaultRegion = createSelector(getRegions, (regions) => regions?.find((region) => region.default));

const sortBaseLayers = (id: string): number => {
  switch (id) {
    case 'osm':
      return 1;
    case 'os_road_27700':
      return 2;
    case 'os_light_27700':
      return 3;
    case 'os_leisure_27700':
      return 4;
    case 'mapbox_aerial':
      return 5;
    case 'bing_aerial':
      return 6;
    case 'bing_aerial_labels':
      return 7;
    default:
      return 8;
  }
};
