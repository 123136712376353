import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';

import { handleError, Token, UserApiService } from '@core/api';
import { APP_CONFIG, AppConfig } from '@core/config';
import { userApiActions } from '../actions/user.actions';
import { TokenService } from '../services/token.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject = new BehaviorSubject<Token | null>(null);

  private HANDLE_TOKEN_FOR: string[] = [];
  private HANDLE_ERRORS_FOR: string[] = [];

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private store: Store,
    private tokenService: TokenService,
    private userApiService: UserApiService
  ) {
    this.HANDLE_TOKEN_FOR = [this.config.api.mapUrl, this.config.api.mediaUrl, this.config.api.layerUrl, this.config.api.geometryUrl];
    this.HANDLE_ERRORS_FOR = [this.config.api.mapUrl, this.config.api.mediaUrl];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.tokenService.token;

    if (this.apisToHandle(request.url, this.HANDLE_TOKEN_FOR)) {
      return next.handle(this.addBearerTokenToRequest(request, token)).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          }

          if (this.apisToHandle(request.url, this.HANDLE_ERRORS_FOR)) {
            return throwError(handleError(error));
          }
          return throwError(error);
        })
      );
    }

    if (request.url && request.url.startsWith(this.config.api.authUrl)) {
      return next.handle(this.addBasicTokenToRequest(request));
    }

    return next.handle(
      request.clone({
        headers: request.headers.delete('Authorization'),
      })
    );
  }

  private addBasicTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Basic ${this.config.api.clientToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        Version: this.config.version,
      },
    });
  }

  private addBearerTokenToRequest(request: HttpRequest<any>, token: Token | null): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${token.access_token}`, Version: this.config.version },
      });
    } else {
      return request.clone({
        setHeaders: {
          Authorization: `Basic ${this.config.api.clientToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          Version: this.config.version,
        },
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.tokenService.token;

    if (!token) {
      return EMPTY;
    }

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      this.tokenService.remove();

      return this.userApiService.refresh(token).pipe(
        tap((refreshedToken: Token) => this.tokenService.set(refreshedToken)),
        switchMap((refreshedToken: Token) => {
          this.tokenSubject.next(refreshedToken);
          this.isRefreshingToken = false;
          return next.handle(this.addBearerTokenToRequest(request, refreshedToken));
        }),
        finalize(() => (this.isRefreshingToken = false)),
        catchError(() => {
          this.tokenService.remove();
          this.store.dispatch(userApiActions.tokenExpired());
          return EMPTY;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter((refreshedToken) => !!refreshedToken),
        take(1),
        switchMap((refreshedToken) => next.handle(this.addBearerTokenToRequest(request, refreshedToken)))
      );
    }
  }

  private apisToHandle(url: string, apis: string[]) {
    return url && apis.some((api) => url.startsWith(api));
  }
}
