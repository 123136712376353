import { createReducer, MetaReducer, on } from '@ngrx/store';
import { get, merge, set } from 'lodash';

import { hydrationActions } from '../actions/hydration.actions';

const hydrationReducer = createReducer(
  {},
  on(hydrationActions.hydrateSuccess, (state, { element, newState }) => set(state, element.key, merge(get(state, element.key), newState)))
);

export const metaReducer: MetaReducer = (reducer) => {
  return (state, action) =>
    action.type === hydrationActions.hydrateSuccess.type ? hydrationReducer(state, action) : reducer(state, action);
};
