import { createActionGroup, props } from '@ngrx/store';

import { AppError, Organisation, OsApiKey } from '@core/api';

export const organisationApiActions = createActionGroup({
  source: 'Organisation/API',
  events: {
    'Fetch Organisation Success': props<{ organisation: Organisation }>(),
    'Fetch Organisation Fail': props<{ error: AppError }>(),
    'Update Organisation Success': props<{ organisation: Organisation }>(),
    'Update Organisation Fail': props<{ error: AppError }>(),
    'Update OS API Key Success': props<{ osApiKey: OsApiKey }>(),
    'Update OS API Key Fail': props<{ error: AppError }>(),
    'Remove OS API Key Success': props<{ organisation: Organisation }>(),
    'Remove OS API Key Fail': props<{ error: AppError }>(),
  },
});
