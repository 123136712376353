import { createAction, props } from '@ngrx/store';

import { Photo } from '@core/api';
import { AttributesChangeEvent, AttributesDeleteEvent } from '@shared/attributes';

type AttributesChange = { planId: string; changes: AttributesChangeEvent };
type AttributesDelete = { planId: string; changes: AttributesDeleteEvent };

/**
 * Generic Plan Attributes Actions
 * ----------------------------------------------
 */

export const createAttributes = createAction('[Map/RHP] Create Attributes', props<AttributesChange>());

export const importAttributes = createAction('[Map/RHP] Import Attributes', props<AttributesChange>());

export const changeFeatureType = createAction('[Map/RHP] Change Feature Type', props<AttributesChange>());

export const updateAttributes = createAction('[Map/RHP] Update Attributes', props<AttributesChange>());

export const removeAttributes = createAction('[Map/RHP] Remove Attributes', props<AttributesDelete>());

export const annotateAttributes = createAction('[Map/RHP] Annotate Attributes', props<AttributesChange>());

export const annotateCentroid = createAction('[Map/RHP] Annotate Centroid', props<AttributesChange>());

export const updateMeasureUnits = createAction('[Map/RHP] Update Units', props<AttributesChange>());

/**
 * Document Actions
 * ----------------------------------------------
 */

export const createDocument = createAction('[Map/RHP] Create Document', props<AttributesChange>());

export const updateDocument = createAction('[Map/RHP] Update Document', props<AttributesChange>());

export const removeDocument = createAction('[Map/RHP] Remove Document', props<AttributesChange>());

/**
 * Resize Actions
 * ----------------------------------------------
 */

export const resizeCircle = createAction(
  '[Map/RHP] Resize Circle',
  props<{ planId: string; featureIds: (string | number)[]; radius: number }>()
);

export const resizeRectangle = createAction(
  '[Map/RHP] Resize Rectangle',
  props<{ planId: string; featureIds: (string | number)[]; width: number; height: number }>()
);

export const resizeLine = createAction(
  '[Map/RHP] Resize Line',
  props<{ planId: string; featureIds: (string | number)[]; length: number }>()
);

/**
 * Feature Actions
 * ----------------------------------------------
 */

export const updateFeaturesStyle = createAction('[Map/RHP] Update Features Style', props<AttributesChange>());

export const resetFeaturesStyle = createAction('[Map/RHP] Reset Features Style', props<AttributesChange>());

export const changeAssignUseClicked = createAction(
  '[Map/RHP] Change Assign Use Clicked',
  props<{ templateName: string; geometryType: string }>()
);

export const startSearchingForAssignUse = createAction(
  '[Map/RHP] Start Searching For Assign Use',
  props<{ templateName: string; geometryType: string }>()
);

export const searchingForAssignUseNoResults = createAction(
  '[Map/RHP] Searching For Assign Use No Results',
  props<{ templateName: string; geometryType: string }>()
);

export const startFeatureStyling = createAction(
  '[Map/RHP]  Start Feature Styling',
  props<{ templateName: string; geometryType: string }>()
);

export const selectFeatureStyling = createAction(
  '[Map/RHP]  Select Feature Styling',
  props<{ templateName: string; geometryType: string; styleCategory: string; stylingType: string }>()
);

/**
 * Attributes Picker Actions
 * ----------------------------------------------
 */

export const enableAttributesPick = createAction('[Map/RHP] Enable Attributes Pick', props<{ from: string }>());

export const disableAttributesPick = createAction('[Map/RHP] Disable Attributes Pick');

/**
 * Dock Actions
 * ----------------------------------------------
 */

export const toggleDockPanel = createAction('[Map/RHP] Toggle Dock Panel');

export const movePanel = createAction('[Map/RHP] Move Panel', props<{ x: number; y: number }>());

/**
 * Photo Actions
 * ----------------------------------------------
 */
export const editPhoto = createAction('[Map/RHP] Edit Photo', props<{ mapId: string; photoId: string; change: Partial<Photo> }>());

export const editPhotos = createAction('[Map/RHP] Edit Photos', props<{ mapId: string; photoIds: string[]; change: Partial<Photo> }>());

export const getPhotoData = createAction('[Map/RHP] Get Photo Data', props<{ photoId: string }>());

export const openLinkToPlanDialog = createAction('[Map/RHP] Open Link To Plan Dialog');

export const linkPhotos = createAction('[Map/RHP] Link Photos', props<{ photoIds: string[]; planId: string }>());

export const unlinkPhotos = createAction('[Map/RHP] Unlink Photos', props<{ photoIds: string[]; planId: string }>());

/**
 * Other
 * ----------------------------------------------
 */

export const fetchLandRegistryOwnership = createAction('[Map/RHP] Fetch LandRegistry Ownership', props<{ titleNumber: string }>());
