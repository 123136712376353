import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ApexPlotOptions, ApexStates, ApexStroke } from 'ng-apexcharts';

@Component({
  selector: 'la-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrl: './circle-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleChartComponent {
  @Input() value!: number;
  @Input() max!: number;

  options: {
    chart: ApexChart;
    stroke: ApexStroke;
    states: ApexStates;
  } = {
    chart: {
      type: 'radialBar',
      height: '48px',
      width: '48px',
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      lineCap: 'round',
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  get series(): ApexNonAxisChartSeries {
    return [(this.value / this.max) * 100];
  }

  get color() {
    return this.value / this.max < 1 ? ['#5D997E'] : ['#B00020'];
  }

  get plotOptions(): ApexPlotOptions {
    return {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {
          size: '30%',
        },
        track: {
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            show: false,
          },
        },
      },
    };
  }
}
