import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadImageService } from '../../services/upload-image.service';

enum UploadState {
  READY,
  COMPLETE,
}

@Component({
  selector: 'la-upload-image',
  templateUrl: 'upload-image.component.html',
  styleUrls: ['upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit, OnDestroy {
  @Input() endpoint?: string;
  @Output() uploaded: EventEmitter<string> = new EventEmitter();

  errorMessage?: string;
  private state: UploadState = UploadState.READY;
  private destroy$: Subject<void> = new Subject();

  constructor(public uploadImageService: UploadImageService) {}

  ngOnInit() {
    this.uploadImageService.upload$.pipe(takeUntil(this.destroy$)).subscribe((storage) => {
      this.state = storage ? UploadState.COMPLETE : UploadState.READY;
      this.uploaded.emit(storage);
    });

    this.uploadImageService.fail$.pipe(takeUntil(this.destroy$)).subscribe((error) => {
      this.errorMessage = get(error, ['errors', '0', 'title'], '');
    });
  }

  ngOnDestroy() {
    this.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isCompleteState(): boolean {
    return this.state === UploadState.COMPLETE;
  }

  public isFileSelected(): boolean {
    return !!this.uploadImageService.file;
  }

  public cancel() {
    this.reset();
  }

  private reset() {
    this.state = UploadState.READY;
    this.uploadImageService.cancel();
    this.uploadImageService.upload$.next('');
    this.uploadImageService.fail$.next();
  }
}
