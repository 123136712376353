import { createActionGroup, props } from '@ngrx/store';

import { AppError, Balance } from '@core/api';

export const fetchLimitsActions = createActionGroup({
  source: 'Plans/Balance',
  events: {
    'Fetch Plans Balance': props<{ mapId: string }>(),
    'Fetch Plans Balance Success': props<{ data: Balance }>(),
    'Fetch Plans Balance Fails': props<{ error: AppError }>(),
  },
});
