import { LandRegistryOwnership } from '@core/api';
import { apiActionFactory } from '@core/store';

export const [showDataLayersSuccess, showDataLayersFail] = apiActionFactory<{ ids: string[] }>('[Data Layers/API] Show');

export const [hideDataLayersSuccess, hideDataLayersFail] = apiActionFactory<{ ids: string[] }>('[Data Layers/API] Hide');

export const [fetchLandRegistryOwnershipSuccess, fetchLandRegistryOwnershipFail] = apiActionFactory<{
  ownership: LandRegistryOwnership;
  titleNumber: string;
}>('[Data Layers/API] Fetch LandRegistry Ownership');
