<ng-template [ngIf]="!isFileSelected()">
  <section class="uploader">
    <mat-icon>cloud_upload</mat-icon>
    <strong>Drag & drop image</strong>
    <footer>JPG, PNG - Max size 50MB</footer>
    <input type="file" name="uploader" #fileInput (change)="uploadImageService.upload(fileInput.files, endpoint)" />
  </section>
</ng-template>

<ng-template [ngIf]="isFileSelected()">
  <section class="uploading">
    <div class="cancel" (click)="cancel()" matTooltip="Cancel" matTooltipPosition="below">
      <mat-icon inline="true">close</mat-icon>
    </div>
    <div class="file">{{ uploadImageService.file?.name }}</div>

    <mat-progress-bar *ngIf="!isCompleteState()" mode="determinate" [value]="uploadImageService.progress$ | async"></mat-progress-bar>
    <div *ngIf="isCompleteState()" class="complete">File uploaded</div>
    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
  </section>
</ng-template>
